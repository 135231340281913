import { definePlugin } from '/@src/utils/app-helper'
import { VueFlow } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'

// SCSS is imported in main.scss
export default definePlugin(({ app }) => {
  app.component('VueFlow', VueFlow)
  app.component('VueFlowBackground', Background)
  app.component('VueFlowControls', Controls)
})
