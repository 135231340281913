<script setup lang="ts">
import { useDashboardStore } from '/@src/stores/dashboard'
import {
  type DashboardWidgetInfo,
  dashboardWidgetSelectorMap,
} from '/@src/mapping/dashboard'
import type { DashboardSlot, DashboardWidgetKey } from '/@src/types/dashboard'

type DashboardWidgetModalEmits = {
  select: [slot: DashboardSlot | undefined, key: DashboardWidgetKey]
}

interface DashboardWidgetModalProps {
  slot: DashboardSlot | undefined
  isOverlay?: boolean
}

const emits = defineEmits<DashboardWidgetModalEmits>()
const props = defineProps<DashboardWidgetModalProps>()

const isOpen = defineModel<boolean>('open')

const dashboardStore = useDashboardStore()

const availableWidgets = computed<DashboardWidgetInfo[]>(() => {
  if (!dashboardStore.currentLoadout?.loadout) {
    return []
  }

  const currentWidgets = Object.values(dashboardStore.currentLoadout.loadout.position)

  return Object.entries(dashboardWidgetSelectorMap)
    .filter(([key, value]) => {
      const settings = dashboardStore.getOrCreateWidgetSettings(
        key as DashboardWidgetKey,
        true,
      )
      return (
        (!currentWidgets.includes(key as DashboardWidgetKey) || settings?.hidden) &&
        !toValue(value.disabled)
      )
    })
    .map(([_, v]) => v)
})
</script>

<template>
  <VModal
    v-model:open="isOpen"
    :modal-id="`widget-modal${isOverlay ? '-overlay' : ''}`"
    size="xlarge"
    title="Widget kiezen"
    cancel-label="Sluiten"
  >
    <template #content>
      <div class="columns is-multiline">
        <div
          v-for="widget in availableWidgets"
          :id="`widget-modal-${widget.key}${isOverlay ? '-overlay' : ''}`"
          :key="`available-widget-${widget.key}`"
          class="column is-half"
        >
          <div class="columns is-flex is-align-items-center">
            <div class="column is-four-fifths">
              <h4 class="title is-size-4">{{ widget.title }}</h4>
              <p>{{ widget.description }}</p>
            </div>
            <div class="column is-one-fifth">
              <VIconBox
                icon="fa-plus"
                color="info"
                @click="emits('select', slot, widget.key)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VModal>
</template>

<style scoped lang="scss"></style>
