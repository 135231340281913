import { definePlugin } from '/@src/utils/app-helper'
import dayjs, { type OpUnitType } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

// Named this 'dayjs-plugin' to stop it from clashing with the regular Dayjs import

// Load default plugins for dayjs
dayjs.extend(utc)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

declare module 'dayjs' {
  interface Dayjs {
    endOfUTC(unit: OpUnitType): Dayjs
  }
}

export default definePlugin(() => {
  dayjs.extend((option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.endOfUTC = function (unit: OpUnitType) {
      return this.endOf(unit).startOf('day')
    }
  })
})
