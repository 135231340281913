import type { Reactions } from '/@src/models/types'
import type { UserMatchOverviewResource } from '/@src/types/matches'
import { Dayjs } from 'dayjs'
import type { Gender, UserId } from '/@src/types/users'
import type {
  AbstractId,
  AddressType,
  CompleteAddress,
  GeoLocation,
  IsoDay,
  RecursivePartial,
} from '/@src/types/utils'
import type { SubActivities, TimelineStoredEvent } from '/@src/types/events'
import {
  type FoodieId,
  type MatchRequestFoodie,
  type ReasonOption,
  type SuggestFoodie,
} from '/@src/types/foodies'
import { z } from 'zod'
import type { GeneralSearchFilters } from '/@src/types/filters'
import type { StopReason, StopReasonId } from '/@src/types/stop-reason'
import type { Caretaker } from '/@src/types/caretaker'
import type { AdminTask } from '/@src/types/admin-tasks'
import type { CookId, MatchableCookPointsMetrics, SuggestCook } from '/@src/types/cooks'
import type { MealWishConnection } from '/@src/types/meal-wishes'
import type { TransportType } from '/@src/types/shared'
import type { AddressQuery } from '/@src/types/dashboard'
import type { SignupReferenceId } from '/@src/types/signup-references'
import type { FocusMunicipalityTargetStatus } from '/@src/types/focus-municipalities'

export type MatchRequestId = AbstractId<'match-request'>
export type MatchRequestDetailsId = AbstractId<'match-request-details'>

export enum MatchRequestStatus {
  New = 'new',
  Intake = 'intake',
  WaitingList = 'waiting-list',
  Forwarded = 'forwarded',
  FollowUp = 'follow-up',
  OnHold = 'on-hold',
  NoCookFound = 'no-cook-found',
  DontFollowUp = 'dont-follow-up',
  Cancelled = 'cancelled',
  Suggested = 'suggested',
  NAEmailed = 'na-emailed',
  NACalled = 'na-called',
  SampleMeal = 'sample-meal',
  Match = 'match',
  EstimatedMatch = 'estimated-match',
  FinishedAfterSuggested = 'finished-after-suggested',
  FinishedAfterSampleMeal = 'finished-after-sample-meal',
  MatchStopped = 'match-stopped',
  NotVulnerable = 'not-vulnerable',
}

export enum MatchRequestStatusCamelCase {
  New = 'new',
  Intake = 'intake',
  Forwarded = 'forwarded',
  FollowUp = 'followUp',
  OnHold = 'onHold',
  NoCookFound = 'noCookFound',
  DontFollowUp = 'dontFollowUp',
  Cancelled = 'cancelled',
  Suggested = 'suggested',
  NAEmailed = 'naEmailed',
  NACalled = 'naCalled',
  SampleMeal = 'sampleMeal',
  Match = 'match',
  EstimatedMatch = 'estimatedMatch',
  FinishedAfterSuggested = 'finishedAfterSuggested',
  FinishedAfterSampleMeal = 'finishedAfterSampleMeal',
  MatchStopped = 'matchStopped',
  NotVulnerable = 'notVulnerable',
  WaitingList = 'waitingList',
}

export type UpdateMatchRequestStatus = MatchRequestStatus | 'waiting-list-no-email'

export enum FrequencyEnum {
  Once = 1,
  Occasionally = 2,
  Frequently = 3,
}

export const FrequencyOptions = z.nativeEnum(FrequencyEnum)
export type Frequency = z.infer<typeof FrequencyOptions>

export enum SignupChannelEnum {
  Unknown = 0,
  Phone = 1,
  Website = 2,
  Misc = 3,
}

export const SignupChannelOptions = z.nativeEnum(SignupChannelEnum)
export type SignupChannel = z.infer<typeof SignupChannelOptions>

export enum MatchRequestWarmUpEnum {
  Can = 'can',
  Cannot = 'cannot',
  NoPreference = 'no-preference',
}
export const MatchRequestWarmUpOptions = z.nativeEnum(MatchRequestWarmUpEnum)
export type MatchRequestWarmUp = z.infer<typeof MatchRequestWarmUpOptions>

export enum MatchRequestPaymentPreferenceEnum {
  Collection = 'collection',
  Mutual = 'mutual',
  NoPreference = 'no-preference',
}
export const MatchRequestPaymentPreferenceOptions = z.nativeEnum(
  MatchRequestPaymentPreferenceEnum,
)
export type MatchRequestPaymentPreference = z.infer<
  typeof MatchRequestPaymentPreferenceOptions
>

export enum MatchRequestCloneReason {
  NewCook = 'new-cook',
  ExtraCook = 'extra-cook',
  SystemError = 'system-error',
  WaitingList = 'waiting-list',
  OpenedAfterNAEmailed = 'opened-after-na-emailed',
}

// These are lower case because they also double as object keys from the backend
export enum MatchRequestContact {
  email = 'email',
  phone = 'phone',
  texting = 'texting',
}

export type MatchRequestSortOption =
  | 'priority'
  | 'new_to_old'
  | 'old_to_new'
  | 'contact_moment'

export interface MatchRequest {
  id: MatchRequestId
  userId: UserId

  createdAt: Dayjs
  updatedAt: Dayjs

  requestedAt: Dayjs
  matchmakerId: UserId

  status: MatchRequestStatus

  isCloned: boolean
  clonedFrom: MatchRequestId | null
  cloneReason: MatchRequestCloneReason | null

  frequency: Frequency
  transport: TransportType
  timesAWeek: number
  portions: number
  amountOfPeople: number
  canWarmup: MatchRequestWarmUp
  paymentPreference: MatchRequestPaymentPreference

  daysPreferred: IsoDay[]
  daysBlocked: IsoDay[]

  helpCookDescription: string | null
  mealWishesDescription: string | null

  comments: string
  emergencyContact: string | null

  signupReferenceId: SignupReferenceId
  signupReferenceText: string | null
  signupChannel: SignupChannel

  noCooksFlag: boolean

  latestContactMoment: Dayjs | null
  suggestedOn: Dayjs | null
  sampleMealOn: Dayjs | null
  onHoldUntil: Dayjs | null

  allowShare: boolean

  stopReason: StopReason | null
  stopReasonText: string | null
}

export interface MatchRequestDetails {
  id: MatchRequestDetailsId
  matchRequestId: MatchRequestId

  contactPersonName: string | null
  contactPersonPhone: string | null
  contactPersonType: string | null

  organisations: string | null
}

export interface DetailMatchRequest extends MatchRequest {
  details: MatchRequestDetails | null

  foodie: MatchRequestFoodie
  caretaker: Caretaker | null
  mealWishes: MealWishConnection[]

  storedEvents: TimelineStoredEvent[]
  nextTask: AdminTask | null

  listOfSiblings?: SiblingMatchRequest[]
  numberOfSiblings: number

  timeIndicator: TimeIndication
  hasActiveMatch: boolean | number
}

export interface SiblingMatchRequest {
  id: MatchRequestId
  requestedAt: Dayjs
  foodieName: string
  status: MatchRequestStatus
  comments: string
}

export interface TimeIndication {
  newToForwarded: number
  forwardedToSuggested: number
  forwardedToClosed: number
}

export interface UpdateMatchRequest {
  frequency: Frequency
  transport: TransportType
  portions: number
  timesAWeek: number
  amountOfPeople: number
  canWarmup: MatchRequestWarmUpEnum
  paymentPreference: MatchRequestPaymentPreferenceEnum

  daysPreferred: IsoDay[]
  daysBlocked: IsoDay[] | null

  helpCookDescription: string | null
  mealWishesDescription: string | null
  comments: string | null

  allowShare: boolean

  emergencyContact: string | null

  signupReferenceId: SignupReferenceId
  signupReferenceText: string | null
  signupChannel: SignupChannel

  latestContactMoment: Dayjs | null
  sampleMealOn: Dayjs | null
  onHoldUntil: Dayjs | null
}

export interface UpdateMatchRequestWithInformation extends UpdateMatchRequest {
  id: MatchRequestId
  userId: UserId

  requestedAt: Dayjs
  fullName: string

  mealWishes: MealWishConnection[]
}

export interface UpdateMatchRequestDetails {
  contactPersonName: string | null
  contactPersonPhone: string | null
  contactPersonType: string | null

  organisations: string | null
}

type MatchRequestFieldUpdateStatus = {
  field: 'status'
  value: UpdateMatchRequestStatus
}
type MatchRequestFieldUpdateComments = {
  field: 'comments'
  value: string
  addTask: boolean
}
export type MatchRequestFieldUpdateStopReason = {
  field: 'stop-reason'
  value: number
  text: string | null
  status: MatchRequestStatus
}
export type MatchRequestFieldUpdateOptions =
  | MatchRequestFieldUpdateStatus
  | MatchRequestFieldUpdateComments
  | MatchRequestFieldUpdateStopReason
export type MatchRequestFieldParams = {
  id: MatchRequestId
} & MatchRequestFieldUpdateOptions

export type MatchRequestDateFieldType =
  | 'latest_contact_moment'
  | 'suggested_on'
  | 'sample_meal_on'

export interface MatchRequestDateFieldParams {
  id: MatchRequestId
  field: MatchRequestDateFieldType
  value: Dayjs | null
}

export type MatchRequestAutomaticUpdateField =
  | 'transport'
  | 'can_warmup'
  | 'allow_share'
  | 'no_cooks_flag'
  | 'payment_preference'
export type MatchRequestAutomaticUpdateParams = {
  id: MatchRequestId
  field: MatchRequestAutomaticUpdateField
}

type MatchRequestAutomaticUpdateFieldWithOperator = {
  field: 'times_a_week' | 'amount_of_people' | 'portions'
  value: 'add' | 'subtract'
}
type MatchRequestAutomaticUpdateFieldWithNumber = {
  field: 'days_preferred'
  value: IsoDay
}
export type MatchRequestAutomaticUpdateFieldWithValueOptions =
  | MatchRequestAutomaticUpdateFieldWithOperator
  | MatchRequestAutomaticUpdateFieldWithNumber
export type MatchRequestAutomaticUpdateWithValueParams = {
  id: MatchRequestId
} & MatchRequestAutomaticUpdateFieldWithValueOptions

export interface SearchMatchRequestParams extends GeneralSearchFilters {
  addressQuery: AddressQuery
  vulnerableOnly: boolean
  matchmakerId: UserId
  impactAcceleratorId: UserId
  status: MatchRequestStatus[] | null
  oldestFirst: boolean
}

export interface SearchNearbyMatchRequestParams extends GeneralSearchFilters {
  matchmakerId: UserId
  status: MatchRequestStatus[] | null
  oldestFirst: boolean
  limitDistance: boolean
}

interface BaseSearchMatchRequest {
  id: MatchRequestId
  userId: UserId
  foodieName: string

  requestedAt: Dayjs
  municipality: string

  status: MatchRequestStatus
  matchmakerId: UserId
  comments: string
  numberOfSiblings: number
  timeIndicator: TimeIndication

  onHoldUntil: Dayjs | null

  hasActiveMatch: boolean
  reactions: Reactions
}

interface AvailableCooks {
  positive: number
  unknown: number
}

export interface SearchMatchRequest extends BaseSearchMatchRequest {
  latestContactMoment: Dayjs | null
  availableCooks: AvailableCooks | null
  suggestedOn: Dayjs | null
  sampleMealOn: Dayjs | null
  blockedAt: Dayjs | null
}

export interface SearchNearbyMatchRequest extends BaseSearchMatchRequest {
  distance: number
  postalCode: string
}

export interface ActivityLogUpdate {
  cookId: CookId
  matchRequestId: MatchRequestId
  events: SubActivities
}

export interface UserMatchRequestOverviewResource {
  id: MatchRequestId
  status: MatchRequestStatus
  foodieName: string
  matchmakerId: UserId | null
  municipality: string
}

export interface MatchRequestAndMatchResult {
  matchRequests: UserMatchRequestOverviewResource[]
  matches: UserMatchOverviewResource[]
}

export interface MatchRequestCloneParams {
  id: MatchRequestId
  cloneReason: MatchRequestCloneReason
  cloneEvents: boolean
  cloneComments: boolean
}

export interface SuggestUsersResource {
  foodie: SuggestFoodie
  cook: SuggestCook
}

export type SuggestMatchRequestFormIndex = 'check' | 'foodie-info' | 'cook-info'

export interface SuggestFoodieInfoStep {
  gender: Gender
  birthdate: Dayjs
  address: CompleteAddress
  reasonOption: ReasonOption
  reasonText: string | null

  signupReferenceId: SignupReferenceId
  signupReferenceText: string | null

  amountOfPeople: number
}

export interface SuggestCookInfoStep {
  gender: Gender
  birthdate: Dayjs
  address: CompleteAddress
  vogConsentAt: Dayjs | null
  vogSkipComment: string | null
  signupReferenceId: SignupReferenceId
}

export interface SuggestMatchRequest {
  matchRequestId: MatchRequestId
  foodieId: FoodieId
  cookId: CookId
  step: SuggestMatchRequestFormIndex

  isAddedManually: boolean
  points: MatchableCookPointsMetrics

  foodieInfoStep: SuggestFoodieInfoStep
  cookInfoStep: SuggestCookInfoStep
}

export type BaseSuggestMatchRequest = RecursivePartial<SuggestMatchRequest>

export interface PauseMatchRequestParams {
  onHoldUntil: Dayjs

  stopReasonId: StopReasonId
  stopReasonText?: string

  comments: string
  cookComments?: string
}

export interface StopMatchRequestForm {
  stopReasonId: StopReasonId
  stopReasonText?: string

  comments: string
  cookComments?: string
}

export interface StopMatchRequestParams extends StopMatchRequestForm {
  status: MatchRequestStatus
}

export interface ExMatchmakerMatchRequest {
  id: MatchRequestId
  matchmakerId: UserId
  requestedAt: Dayjs

  foodieName: string
  municipality: string

  status: MatchRequestStatus
  comments: string | null

  latestContactMoment: Dayjs | null

  isCloned: boolean
  clonedFrom: MatchRequestId | null

  stopReason: StopReason | null
  stopReasonText: string | null
}

export interface NewMatchRequest {
  id: MatchRequestId
  matchmakerId: UserId
  requestedAt: Dayjs

  foodieName: string
  blockedAt: Dayjs | null

  municipality: string

  status: MatchRequestStatus
}

export interface WaitingListMatchRequest {
  id: MatchRequestId
  matchmakerId: UserId
  requestedAt: Dayjs

  foodieName: string
  blockedAt: Dayjs | null

  municipality: string

  status: MatchRequestStatus

  latestContactMoment: Dayjs | null
  comments: string | null
}

export interface MatchRequestInfoSocialPost {
  id: MatchRequestId
  firstName: string
  lastName: string
  birthdate: Dayjs | null
  helpCookDescription: string | null
  mealWishesDescription: string | null
  mealWishes: MealWishConnection[]
  address: GeoLocation
  status: MatchRequestStatus
  portions: number | null
}

export interface MatchRequestProblemAreaAddress {
  municipality: string
  city?: string
  borough?: string
  neighborhood?: string
  district?: string
  postalCode?: string
}

export interface MatchRequestProblemArea {
  type: AddressType
  matchRequestIds: MatchRequestId[]
  targetStatus: FocusMunicipalityTargetStatus
  addressData: MatchRequestProblemAreaAddress
}
