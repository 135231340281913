<script setup lang="ts">
import type { BulmaColor, FAIconName } from '/@src/types/elements-ui'

type ResetButtonEmits = {
  reset: []
}

interface ResetButtonProps {
  icon?: FAIconName
  time?: number
  color?: BulmaColor
  secondaryColor?: BulmaColor
  width?: number | `${number}` | 'full'
  minWidth?: `${number}%`
  notAutomatic?: boolean
  loading?: boolean
}

const emits = defineEmits<ResetButtonEmits>()
const props = withDefaults(defineProps<ResetButtonProps>(), {
  icon: 'fa-arrows-rotate',
  time: 0.4,
  color: 'warning',
  secondaryColor: 'success',
  width: 'full',
  minWidth: '90%',
})
</script>

<template>
  <VAnimatedButton
    :icon="icon"
    :color="color"
    :secondary-color="secondaryColor"
    :time="time"
    :min-width="minWidth"
    :width="width"
    @click="emits('reset')"
  >
    <slot> Reset </slot>
  </VAnimatedButton>
</template>

<style scoped lang="scss"></style>
