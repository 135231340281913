<script setup lang="ts">
import {
  MatchesNavbarIcon,
  MatchRequestNavbarIcon,
  UserIcon,
} from '/@src/models/standardIcons'
import type { GlobalSearch } from '/@src/types/globals'
import type {
  ComponentClass,
  FAIconName,
  VTableColumn,
  VTableColumns,
} from '/@src/types/elements-ui'
import type { RouteLocationRaw } from 'vue-router'
import { useGlobalsStore } from '/@src/stores/global'
import { useTemplateRef } from 'vue'
import { debounce } from 'lodash'
import { match } from 'ts-pattern'
import type { RouteLocationNormalized } from 'vue-router/auto'
import { emojiPopper } from '/@src/utils/emoji-popper'

type GlobalSearchEmits = {
  linkClicked: []
}

interface GlobalSearchProps {
  tableClasses?: ComponentClass
}

const emits = defineEmits<GlobalSearchEmits>()
const props = defineProps<GlobalSearchProps>()

const searchQuery = defineModel<string>({ required: true })

const globalsStore = useGlobalsStore()

const searchInput = useTemplateRef('searchInput')

const isLoading = ref(false)
const data = ref<GlobalSearch[]>([])

const showData = computed<GlobalSearch[]>(() => {
  if (data.value.length > 0) {
    return data.value
  }

  return globalsStore.searchHistory.map((searchResponse) => {
    if (searchResponse.action || searchResponse.to) {
      return searchResponse
    } else if (searchResponse.query) {
      return {
        ...searchResponse,
        action: () => {
          searchQuery.value = searchResponse.query!
          searchForQuery()
          return undefined
        },
      } satisfies GlobalSearch
    }

    return searchResponse
  })
})

const searchForQuery = async () => {
  isLoading.value = true
  const result = await globalsStore.globalSearchQuery(searchQuery.value)
  isLoading.value = false

  if (result.query === searchQuery.value) {
    data.value = result.data
  }
}

watch(searchQuery, debounce(searchForQuery, 200))

const inputIsId = computed(
  () => !isNaN(+searchQuery.value) && searchQuery.value.length < 9,
)

const resultsHaveUser = computed(() =>
  data.value.some(
    (result) => result.type === 'user' && result.id === Number(searchQuery.value),
  ),
)

const resultsHaveMatch = computed(() =>
  data.value.some(
    (result) => result.type === 'match' && result.id === Number(searchQuery.value),
  ),
)

const resultsHaveMatchRequest = computed(() =>
  data.value.some(
    (result) =>
      result.type === 'match_request' && result.id === Number(searchQuery.value),
  ),
)

const preBodyRowCount = computed<number>(() =>
  isLoading.value
    ? 3
    : Number(!resultsHaveUser.value) +
      Number(!resultsHaveMatch.value) +
      Number(!resultsHaveMatchRequest.value),
)

const matchBodyRowIndex = computed(() =>
  isLoading.value ? 2 : resultsHaveUser.value ? 1 : 2,
)
const matchRequestBodyRowIndex = computed(() => {
  if (isLoading.value) {
    return 3
  }

  if (resultsHaveMatch.value) {
    if (resultsHaveUser.value) {
      return 1
    } else {
      return 2
    }
  } else {
    if (resultsHaveUser.value) {
      return 2
    } else {
      return 3
    }
  }
})

const getTypeIcon = (searchResult: GlobalSearch) => {
  if (searchResult.icon) {
    return searchResult.icon
  }

  return match(searchResult.type)
    .returnType<FAIconName>()
    .with('user', () => UserIcon)
    .with('match', () => MatchesNavbarIcon)
    .with('match_request', () => MatchRequestNavbarIcon)
    .with('misc', () => 'fa-wand-magic-sparkles')
    .with('history', () => 'fa-clock-rotate-left')
    .exhaustive()
}

const getLink = (searchResult: GlobalSearch) => {
  return match(searchResult.type)
    .returnType<Partial<RouteLocationNormalized> | undefined>()
    .with('user', () => {
      return {
        name: '/users/[user]/edit/',
        params: { user: searchResult.id.toString() },
      }
    })
    .with('match', () => {
      return {
        name: '/matches/[match]/',
        params: { match: searchResult.id.toString() },
      }
    })
    .with('match_request', () => {
      return {
        name: '/matching/[matching]/',
        params: { matching: searchResult.id.toString() },
      }
    })
    .with('misc', () => searchResult.to)
    .with('history', () => searchResult.to)
    .exhaustive()
}

const onRouterLinkClose = (row: GlobalSearch | undefined = undefined) => {
  if (row?.action) {
    row.action()
  }

  emits('linkClicked')
}

const executeAction = async (row: GlobalSearch, event: Event) => {
  if (row.action) {
    const result = await row.action()

    if (result === 'emoji') {
      const target = event.target as Element
      const boundClientRec = target?.getBoundingClientRect()

      emojiPopper.pop({
        location: boundClientRec,
        chance: 1,
      })
    } else if (result === 'close') {
      emits('linkClicked')
    }
  }
}

const focusInput = () => {
  searchInput.value?.focusElement()
}
defineExpose({ focusInput })

type ColumnKeys = 'row'

const columns: VTableColumns<ColumnKeys, GlobalSearch> = [
  {
    key: 'row',
    label: '',
    preRowClass: 'px-0',
    cellClass: 'px-0',
  },
]
</script>

<template>
  <VInput
    ref="searchInput"
    v-model="searchQuery"
    class="mb-2"
    placeholder="Thuiskoks in gemeente Utrecht"
    type="search"
  />
  <VTable
    v-if="showData.length > 0"
    :data="showData"
    :columns="columns"
    :number-of-pre-body-cell-rows="preBodyRowCount"
    :class="tableClasses"
  >
    <template v-if="inputIsId && data.length > 0" #pre-body-cell="{ index, column }">
      <template v-if="index === 1 && !resultsHaveUser">
        <template v-if="column.key === 'row'">
          <RouterLink
            :to="{
              name: '/users/[user]/edit',
              params: { user: searchQuery.length > 1 ? searchQuery : '-1' },
            }"
            class="is-fullwidth is-wildcard"
            @click="emits('linkClicked')"
          >
            <div class="columns is-vcentered">
              <div
                class="column is-half is-flex is-align-items-center is-flex-direction-column"
              >
                <VIconBox class="mx-6 mt-4" :icon="UserIcon" color="warning" />
                <span class="light-text mx-6 mb-3">
                  {{ searchQuery }}
                </span>
              </div>
              <div class="column is-half">
                <span class="light-text"> Ga naar gebruiker #{{ searchQuery }} </span>
              </div>
            </div>
          </RouterLink>
        </template>
      </template>
      <template v-if="index === matchBodyRowIndex && !resultsHaveMatch">
        <template v-if="column.key === 'row'">
          <RouterLink
            :to="{
              name: '/matches/[match]/',
              params: { match: searchQuery.length > 1 ? searchQuery : '-1' },
            }"
            class="is-fullwidth is-wildcard"
            @click="emits('linkClicked')"
          >
            <div class="columns is-vcentered">
              <div
                class="column is-half is-flex is-align-items-center is-flex-direction-column"
              >
                <VIconBox class="mx-6 mt-4" :icon="MatchesNavbarIcon" color="warning" />
                <span class="light-text mx-6 mb-3">
                  {{ searchQuery }}
                </span>
              </div>
              <div class="column is-half">
                <span class="light-text"> Ga naar match #{{ searchQuery }} </span>
              </div>
            </div>
          </RouterLink>
        </template>
      </template>
      <template v-if="index === matchRequestBodyRowIndex && !resultsHaveMatchRequest">
        <template v-if="column.key === 'row'">
          <RouterLink
            :to="{
              name: '/matching/[matching]/',
              params: { matching: searchQuery.length > 1 ? searchQuery : '-1' },
            }"
            class="is-fullwidth is-wildcard"
            @click="emits('linkClicked')"
          >
            <div class="columns is-vcentered">
              <div
                class="column is-half is-flex is-align-items-center is-flex-direction-column"
              >
                <VIconBox
                  class="mx-6 mt-4"
                  :icon="MatchRequestNavbarIcon"
                  color="warning"
                />
                <span class="light-text mx-6 mb-3">
                  {{ searchQuery }}
                </span>
              </div>
              <div class="column is-half">
                <span class="light-text"> Ga naar aanvraag #{{ searchQuery }} </span>
              </div>
            </div>
          </RouterLink>
        </template>
      </template>
    </template>

    <template
      #body-cell="{
        row,
        column,
      }: {
        row: GlobalSearch
        column: VTableColumn<ColumnKeys, GlobalSearch>
      }"
    >
      <template v-if="column.key === 'row'">
        <RouterLink
          v-if="getLink(row)"
          :to="getLink(row)! as RouteLocationRaw"
          class="is-fullwidth"
          :class="row.type === 'history' && 'is-wildcard'"
          @click="onRouterLinkClose(row)"
        >
          <div class="columns is-vcentered">
            <div
              class="column is-half is-flex is-align-items-center is-flex-direction-column"
            >
              <VIconBox
                class="mx-6 mt-4"
                :class="row.id === -1 && 'mb-4'"
                :icon="getTypeIcon(row)"
                :color="row.type === 'history' ? 'warning' : 'info'"
              />
              <span v-if="row.id !== -1" class="light-text mx-6 mb-3">
                {{ row.id }}
              </span>
            </div>

            <div class="column is-half">
              <span class="light-text">
                {{ row.fullName }}
              </span>
            </div>
          </div>
        </RouterLink>
        <div
          v-else
          class="is-fullwidth is-clickable"
          :class="row.type === 'history' && 'is-wildcard'"
          @click.prevent="(event) => row.action && executeAction(row, event)"
          @keyup.prevent="(event) => row.action && executeAction(row, event)"
        >
          <div class="columns is-vcentered">
            <div
              class="column is-half is-flex is-align-items-center is-flex-direction-column"
            >
              <VIconBox
                class="mx-6 my-4"
                :icon="getTypeIcon(row)"
                :color="row.type === 'history' ? 'warning' : 'info'"
              />
              <span v-if="row.id !== -1" class="light-text mx-6">
                {{ row.id }}
              </span>
            </div>

            <div class="column is-half">
              <span class="light-text">
                {{ row.fullName }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </template>
  </VTable>
</template>

<style scoped lang="scss">
a,
.is-clickable {
  border: 2px solid var(--info);
  border-radius: 8px;

  &.is-wildcard {
    border-color: var(--warning);
  }

  &:hover {
    background: var(--fade-grey-light-3);
  }
}

.is-dark {
  a:hover {
    background: var(--dark-sidebar-light-8) !important;
  }

  .is-clickable:hover {
    background: var(--dark-sidebar-light-8) !important;
  }
}
</style>
