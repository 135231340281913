import { type UserInformation, UserRoleEnum } from '/@src/types/users'
import { useAuthStore } from '/@src/stores/auth'
import { hasRole } from '/@src/utils/helpers'
import { type AdminUser } from '/@src/types/admin-users'
import dayjs, { Dayjs } from 'dayjs'
import type { MatchRequestId } from '/@src/types/matchRequests'
import { MatchRequestStatus } from '/@src/types/matchRequests'
import { stoppedMatchRequestStatuses } from '/@src/mapping/match-requests'
import { dayjsUTC } from '/@src/utils/date-formatter'
import type { ComputedRef } from 'vue'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import type { SignupReferenceId } from '/@src/types/signup-references'
import { SignupReferenceOopoehId } from '/@src/mapping/signup-references'

export const devIsInProdEnvironment = computed<boolean>(() => {
  const isInProdEnvironment = import.meta.env.PROD

  return isInProdEnvironment && isDeveloper.value
})

export const isDeveloper = computed<boolean>(() => {
  return useAuthStore().hasRole(UserRoleEnum.Developer)
})

export const isAdministrator = computed<boolean>(() => {
  return isDeveloper.value || useAuthStore().hasRole(UserRoleEnum.Administrator)
})

export const isManager = computed<boolean>(() => {
  return isAdministrator.value || useAuthStore().hasRole(UserRoleEnum.Manager)
})

export const isFinance = computed<boolean>(() => {
  return isAdministrator.value || useAuthStore().hasRole(UserRoleEnum.Finance)
})

export const isLeadMatchmaker = computed<boolean>(() => {
  return isManager.value || useAuthStore().hasRole(UserRoleEnum.LeadMatchmaker)
})

export const isVolunteerCoordinator = computed<boolean>(() => {
  return useAuthStore().hasRole(UserRoleEnum.VolunteerCoordinator)
})

export const isOfficeManager = computed<boolean>(() => {
  return isManager.value || useAuthStore().hasRole(UserRoleEnum.OfficeManager)
})

export const isHelpdesker = computed<boolean>(() => {
  return isLeadMatchmaker.value || useAuthStore().hasRole(UserRoleEnum.Helpdesker)
})

export const isExternalUser = computed<boolean>(() => {
  return isManager.value || useAuthStore().hasRole(UserRoleEnum.External)
})

export const userRolePermissionsMap: Partial<Record<UserRoleEnum, ComputedRef<boolean>>> =
  {
    [UserRoleEnum.Developer]: isDeveloper,
    [UserRoleEnum.Administrator]: isAdministrator,
    [UserRoleEnum.Manager]: isManager,
    [UserRoleEnum.Finance]: isFinance,
    [UserRoleEnum.LeadMatchmaker]: isLeadMatchmaker,
    [UserRoleEnum.VolunteerCoordinator]: isVolunteerCoordinator,
    [UserRoleEnum.OfficeManager]: isOfficeManager,
    [UserRoleEnum.Helpdesker]: isHelpdesker,
    [UserRoleEnum.External]: isExternalUser,
  }

export const operatingSystem = computed<'windows' | 'macOS' | 'linux' | undefined>(() => {
  if (window.navigator.platform.indexOf('Win') != -1) {
    return 'windows'
  } else if (window.navigator.platform.indexOf('Mac') != -1) {
    return 'macOS'
  } else if (window.navigator.platform.indexOf('Linux') != -1) {
    return 'linux'
  } else {
    return undefined
  }
})

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @source https://stackoverflow.com/a/21742107
 * @returns {String}
 */
export const mobileOperatingSystem = computed<
  'Windows Phone' | 'Android' | 'iOS' | 'unknown'
>(() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
})

export function canBeDeleted(user: UserInformation) {
  return (
    user.balance === 0 && !user.hasActiveMatches && !hasRole(user, UserRoleEnum.Admin)
  )
}

export function hasFocusMunicipalityAssigned(
  user: AdminUser,
  municipality?: string,
): boolean {
  if (municipality) {
    if (municipality === "'s-Gravenhage") {
      municipality = 'Den Haag'
    } else if (municipality === "'s-Hertogenbosch") {
      municipality = 'Den Bosch'
    }

    return user.municipalities.includes(municipality.toLowerCase())
  }
  return false
}

export function matchRequestIsFrozen(matchRequest?: {
  requestedAt: Dayjs
  id: MatchRequestId
}): boolean {
  if (!matchRequest) {
    return true
  }

  return (
    matchRequest.requestedAt.isBefore(dayjs('2024-01-01')) &&
    ![16640, 16400, 16390, 16417, 16245, 15777].includes(matchRequest.id)
  )
}

export function matchRequestIsCancelled(status?: MatchRequestStatus): boolean {
  if (!status) {
    return false
  }
  return stoppedMatchRequestStatuses.includes(status)
}

export function matchRequestIsPaused(
  status?: MatchRequestStatus,
  onHoldUntil?: Dayjs | null,
): boolean {
  if (!status) {
    return false
  }
  return (
    status === MatchRequestStatus.OnHold && (onHoldUntil?.isAfter(dayjsUTC()) ?? false)
  )
}

export function matchRequestIsVoorproefjeEligible(
  promotions: string[] | undefined | null,
  municipality: string | undefined,
) {
  const focusMunicipalityStore = useFocusMunicipalitiesStore()
  return (
    !promotions?.includes('voorproefje') &&
    focusMunicipalityStore.isFocusMunicipality(municipality)
  )
}

export function isVoorproefjeMatchRequest(promotions: string[] | undefined | null) {
  return promotions?.includes('voorproefje')
}

export function isOopoehMatchRequest(signupReferenceId: SignupReferenceId | undefined) {
  return signupReferenceId === SignupReferenceOopoehId
}

/**
 *
 * const isVoorproefje = computed(
 *   () =>
 *     matchRequest.value.foodie.promotions?.includes('voorproefje')
 * )
 *
 * const isOopoeh = computed(
 *   () => matchRequest.value.signupReferenceId === SignupReferenceOopoehId,
 * )
 */
