import axios, { type AxiosInstance } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { parseDateRequest, parseDateResponse } from '/@src/utils/date-formatter'
import { useAuthStore } from '/@src/stores/auth'
import { toast } from 'vue-sonner'
import { useBuggieStore } from '/@src/stores/buggie'

// register global for Echo interceptor
declare global {
  interface Window {
    axios: AxiosInstance
    api?: AxiosInstance
  }
}

export function createApi() {
  // Here we set the base URL for all requests made to the api
  window.api = axios.create({
    baseURL: import.meta.env.VITE_BASE_API_URL as string,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    transformResponse: [
      (data, headers) => {
        if (
          data && headers ? headers['content-type'].includes('application/json') : false
        ) {
          return camelcaseKeys(JSON.parse(data), { deep: true, stopPaths: ['errors'] })
        } else {
          return data
        }
      },
      function (data) {
        if (data && data.meta && !data.meta.dateChecked) {
          return data
        }

        parseDateResponse(data)

        return data
      },
    ],
    transformRequest: [
      function (data) {
        parseDateRequest(data)
        return data
      },
      (data, headers) => {
        if (
          data && headers
            ? (headers['Content-Type'] as string).includes('application/json')
            : false
        ) {
          return JSON.stringify(snakecaseKeys(data, { deep: true }))
        }
      },
    ],
  })

  window.axios = window.api

  window.api.interceptors.request.use((config) => {
    const authStore = useAuthStore()

    if (config.headers && authStore.user?.token.accessToken) {
      config.headers['Authorization'] = authStore.authorizationHeader
    }

    if (
      authStore.hasReadonlyRole &&
      ((config.method !== 'get' &&
        config.method !== 'GET' &&
        !config.headers.allowReadOnly) ||
        config.headers.blockReadOnly)
    ) {
      toast.message('Deze actie is niet toegestaan met de read-only rol')
      return Promise.reject('Action not allowed while having the read-only role')
    }

    return config
  })

  // This gives an "inject() can only be used inside setup() or functional components." warning,
  // but so far this hasn't caused any real errors
  window.api.interceptors.response.use(
    (response) => {
      const authStore = useAuthStore()

      if (response.headers.authorization) {
        const token = response.headers.authorization.split(' ')[1]
        if (authStore.user) {
          authStore.user.token.accessToken = token
        }
      }

      return response
    },
    async (error) => {
      const route = window.location.pathname

      const authStore = useAuthStore()

      const buggieStore = useBuggieStore()
      buggieStore.pushNetworkError(error.response)

      if (error.response?.headers.authorization) {
        const token = error.response.headers.authorization.split(' ')[1]
        if (authStore.user) {
          authStore.user.token.accessToken = token
        }
      }

      if (error.response?.status === 401 && !route.includes('login')) {
        if (authStore.isAuthenticated) {
          toast.error('Automatisch uitgelogd, log opnieuw in!')
          authStore._loggedOut()
        }
        const router = useRouter()
        await router?.isReady()

        // Router can be undefined here, randomly, not sure why.
        // In case it is, we use the old school method of going to another page.
        if (router) {
          await router.push({
            name: '/login',
            query: {
              redirect: route,
            },
          })
        } else {
          window.location.href = `https://${window.location.host}/login?redirect=${route}`
        }
      }

      return Promise.reject(error)
    },
  )

  return window.api
}

export function useApi(): AxiosInstance {
  if (!window.api) {
    createApi()
  }
  return window.api!
}
