import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type { AddressType, ApiResponse } from '/@src/types/utils'
import { toast } from 'vue-sonner'
import type {
  BaseSuggestMatchRequest,
  DetailMatchRequest,
  MatchRequest,
  MatchRequestAutomaticUpdateParams,
  MatchRequestAutomaticUpdateWithValueParams,
  MatchRequestCloneParams,
  MatchRequestDateFieldParams,
  MatchRequestFieldParams,
  MatchRequestId,
  MatchRequestInfoSocialPost,
  MatchRequestProblemArea,
  PauseMatchRequestParams,
  SearchMatchRequest,
  SearchMatchRequestParams,
  SearchNearbyMatchRequest,
  SearchNearbyMatchRequestParams,
  StopMatchRequestParams,
  SuggestCookInfoStep,
  SuggestFoodieInfoStep,
  SuggestMatchRequest,
  SuggestUsersResource,
  UpdateMatchRequest,
  UpdateMatchRequestDetails,
  UpdateMatchRequestWithInformation,
} from '/@src/types/matchRequests'
import {
  MatchRequestContact,
  type MatchRequestSortOption,
  MatchRequestStatus,
} from '/@src/types/matchRequests'
import { cloneDeep } from 'lodash'
import { rejectWebSocketUpdate, resolveWebSocketUpdate } from '/@src/utils/webSockets'
import type {
  CaretakerId,
  UpdateCaretaker,
  UpdateCaretakerWithId,
} from '/@src/types/caretaker'
import type { TableMatch } from '/@src/types/matches'
import type { SubjectHistoryResponseWithDate } from '/@src/types/events'
import type {
  CookId,
  MatchableCook,
  MatchableCookResource,
  SuggestCook,
} from '/@src/types/cooks'
import { allowReadOnly } from '/@src/utils/axios-utils'
import {
  matchRequestIsCancelled,
  matchRequestIsFrozen,
  matchRequestIsPaused,
} from '/@src/utils/entity-checks'
import { type AdminTask } from '/@src/types/admin-tasks'
import type { UserId } from '/@src/types/users'
import type { SuggestFoodie } from '/@src/types/foodies'
import type { SocialPost } from '/@src/types/social-posts'
import { undefinedUserId } from '/@src/models/users'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'

const api = useApi()

interface State {
  searchMatchRequestParams: SearchMatchRequestParams
  searchMatchRequestResults: SearchMatchRequest[]
  sortingParam: MatchRequestSortOption

  specificMatchRequest?: DetailMatchRequest

  matchableCooks: MatchableCook[]
  totalMatchableCooks: number

  suggestMatchRequest: SuggestMatchRequest
  specificSuggestFoodie?: SuggestFoodie
  specificSuggestCook?: SuggestCook

  searchNearbyMatchRequestResults: SearchNearbyMatchRequest[]
  searchNearbyMatchRequestParams: SearchNearbyMatchRequestParams

  reportIds: MatchRequestId[]
}

export const defaultMatchRequestParams: SearchMatchRequestParams = {
  query: '',
  addressQuery: {
    municipality: [],
    city: [],
    borough: [],
    neighborhood: [],
    district: [],
  },
  limit: 30,
  maxDaysOld: null,
  vulnerableOnly: false,
  matchmakerId: undefinedUserId,
  impactAcceleratorId: undefinedUserId,
  oldestFirst: false,
  status: [
    MatchRequestStatus.New,
    MatchRequestStatus.Intake,
    MatchRequestStatus.Forwarded,
    MatchRequestStatus.FollowUp,
    MatchRequestStatus.OnHold,
    MatchRequestStatus.Suggested,
    MatchRequestStatus.NAEmailed,
    MatchRequestStatus.NACalled,
    MatchRequestStatus.SampleMeal,
    MatchRequestStatus.Match,
  ], // By default, disable: statuses no cook found, no follow up, cancelled, finished after suggested, finished after sample meal, match stopped, and not vulnerable
}

export const defaultNearbyMatchRequestParams: SearchNearbyMatchRequestParams = {
  query: '',
  limit: 30,
  maxDaysOld: null,
  matchmakerId: undefinedUserId,
  oldestFirst: false,
  limitDistance: true,
  status: [
    MatchRequestStatus.New,
    MatchRequestStatus.WaitingList,
    MatchRequestStatus.Intake,
    MatchRequestStatus.Forwarded,
    MatchRequestStatus.FollowUp,
    MatchRequestStatus.NAEmailed,
    MatchRequestStatus.NACalled,
    MatchRequestStatus.OnHold,
    MatchRequestStatus.NoCookFound,
  ], // By default, disable: statuses suggested, sample meal, match, no cook found, no follow up, cancelled, finished after suggested, finished after sample meal, match stopped, and not vulnerable
}

const baseSuggestMatchRequest: BaseSuggestMatchRequest = {
  matchRequestId: undefined,
  foodieId: undefined,
  cookId: undefined,

  step: 'check',

  isAddedManually: false,
  points: undefined,

  foodieInfoStep: undefined,
  cookInfoStep: undefined,
}

export const useMatchRequestStore = defineStore('matchRequests', {
  state: (): State => ({
    searchMatchRequestParams: cloneDeep(defaultMatchRequestParams),
    searchNearbyMatchRequestResults: [],
    sortingParam: 'priority',

    specificMatchRequest: undefined,

    matchableCooks: [],
    totalMatchableCooks: 0,

    suggestMatchRequest: cloneDeep(baseSuggestMatchRequest as SuggestMatchRequest),
    specificSuggestFoodie: undefined,
    specificSuggestCook: undefined,

    searchMatchRequestResults: [],
    searchNearbyMatchRequestParams: cloneDeep(defaultNearbyMatchRequestParams),
    reportIds: [],
  }),

  persist: usePersist<State>({
    pick: ['sortingParam', 'suggestMatchRequest', 'reportIds'],
  }),

  logout: {
    pick: ['sortingParam', 'searchMatchRequestParams', 'searchNearbyMatchRequestParams'],
  },

  actions: {
    resetMatchRequestFilter() {
      this.searchMatchRequestParams = cloneDeep(defaultMatchRequestParams)
    },
    resetNearbyMatchRequestFilter() {
      this.searchNearbyMatchRequestParams = cloneDeep(defaultNearbyMatchRequestParams)
    },
    resetSuggestMatchRequest() {
      const points = cloneDeep(this.suggestMatchRequest.points)
      const isAddedManually = cloneDeep(this.suggestMatchRequest.isAddedManually)
      this.suggestMatchRequest = cloneDeep(baseSuggestMatchRequest as SuggestMatchRequest)

      this.suggestMatchRequest.points = points
      this.suggestMatchRequest.isAddedManually = isAddedManually
    },
    setSuggestMatchRequest(matchRequest: MatchRequest) {
      if (this.suggestMatchRequest.matchRequestId !== matchRequest.id) {
        this.resetSuggestMatchRequest()
      }
    },
    setSuggestedUsers(users: Partial<SuggestUsersResource>) {
      const { cook, foodie } = users

      if (
        this.suggestMatchRequest.foodieId !== foodie?.id ||
        this.suggestMatchRequest.cookId !== cook?.id
      ) {
        const matchRequestId = this.suggestMatchRequest.matchRequestId
        this.resetSuggestMatchRequest()
        this.suggestMatchRequest.matchRequestId = matchRequestId
      }

      if (cook) {
        this.suggestMatchRequest.cookId = cook.id
        this.suggestMatchRequest.cookInfoStep = {
          gender: cook.gender,
          birthdate: cook.birthdate,
          address: cook.address,
          vogConsentAt: cook.vogConsentAt,
          vogSkipComment: null,
          signupReferenceId: cook.signupReferenceId,
        } as SuggestCookInfoStep
      }
      if (foodie) {
        this.suggestMatchRequest.foodieId = foodie.id
        this.suggestMatchRequest.foodieInfoStep = {
          gender: foodie.gender,
          birthdate: foodie.birthdate,
          address: foodie.address,
          reasonOption: foodie.reasonOption,
          reasonText: foodie.reasonText,
          signupReferenceId: foodie.signupReferenceId,
          signupReferenceText: foodie.signupReferenceText,
          amountOfPeople: foodie.amountOfPeople,
        } as SuggestFoodieInfoStep
      }
    },

    async fetchMatchRequest(matchRequestId: MatchRequestId) {
      return new Promise<boolean>((resolve) => {
        api
          .get<ApiResponse<DetailMatchRequest>>(`admin/match-requests/${matchRequestId}`)
          .then((response) => {
            const json = response.data
            this.specificMatchRequest = json.data
            resolve(true)
          })
          .catch(() => {
            toast.error('Kon aanvraag niet ophalen.')
            resolve(false)
          })
      })
    },

    async fetchMatches(matchRequestId: MatchRequestId) {
      return new Promise<TableMatch[] | false>((resolve) => {
        api
          .get<ApiResponse<TableMatch[]>>(
            `admin/match-requests/${matchRequestId}/matches`,
          )
          .then((response) => {
            const json = response.data
            resolve(json.data)
          })
          .catch(() => {
            toast.error('Fout bij ophalen matches')
            resolve(false)
          })
      })
    },

    async fetchHistory(matchRequestId: MatchRequestId) {
      return new Promise<SubjectHistoryResponseWithDate<MatchRequest> | false>(
        (resolve) => {
          api
            .get<SubjectHistoryResponseWithDate<MatchRequest>>(
              `admin/match-requests/${matchRequestId}/history`,
            )
            .then((response) => {
              resolve(response.data)
            })
            .catch(() => {
              toast.error('Kon geschiedenis niet ophalen.')
              resolve(false)
            })
        },
      )
    },

    async fetchNextTask(matchRequestId: MatchRequestId) {
      return new Promise<AdminTask | null | false>((resolve) => {
        api
          .get<ApiResponse<AdminTask | null>>(
            `admin/match-requests/${matchRequestId}/next-task`,
          )
          .then((response) => {
            resolve(response.data.data)
          })
          .catch(() => {
            toast.error('Fout bij ophalen volgende herinnering')
            resolve(false)
          })
      })
    },

    async get(matchRequestIds: MatchRequestId[]) {
      return new Promise<SearchMatchRequest[] | false>((resolve) => {
        api
          .post<ApiResponse<SearchMatchRequest[]>>(
            `admin/match-requests/get`,
            {
              ids: matchRequestIds,
            },
            allowReadOnly(),
          )
          .then((response) => {
            const json = response.data
            resolve(json.data)
          })
          .catch(() => {
            toast.error('Aanvragen ophalen mislukt.')
            resolve(false)
          })
      })
    },

    async searchMatchRequests() {
      return new Promise<boolean>((resolve) => {
        api
          .post<ApiResponse<SearchMatchRequest[]>>(
            'admin/match-requests/search',
            this.searchMatchRequestParams,
            allowReadOnly(),
          )
          .then((response) => {
            const json = response.data
            this.searchMatchRequestResults = json.data
            resolve(true)
          })
          .catch(() => {
            toast.error('Zoeken naar aanvragen mislukt.')
            resolve(false)
          })
      })
    },

    async fetchProblemMatchRequestsAreas(type: AddressType) {
      return new Promise<MatchRequestProblemArea[] | false>((resolve) => {
        api
          .post<ApiResponse<MatchRequestProblemArea[]>>(
            'admin/match-requests/problem-areas',
            { type },
          )
          .then((response) => {
            resolve(response.data.data)
          })
          .catch(() => {
            toast.error('Probleemgebieden ophalen mislukt')
            resolve(false)
          })
      })
    },

    async searchNearbyMatchRequests(cookId: CookId) {
      return new Promise<boolean>((resolve) => {
        api
          .post<ApiResponse<SearchNearbyMatchRequest[]>>(
            `admin/cooks/${cookId}/search-nearby-match-requests`,
            this.searchNearbyMatchRequestParams,
            allowReadOnly(),
          )
          .then((response) => {
            const json = response.data
            this.searchNearbyMatchRequestResults = json.data
            resolve(true)
          })
          .catch(() => {
            toast.error('Zoeken naar aanvragen mislukt.')
            resolve(false)
          })
      })
    },

    async searchMatchableCooks(argument: boolean | string, maxCooks: number) {
      let params: object
      if (typeof argument === 'boolean') {
        params = {
          limitDistance: argument,
          limitCooks: maxCooks,
        }
      } else {
        params = {
          query: argument,
          limitCooks: maxCooks,
        }
      }

      return new Promise<boolean>((resolve) => {
        api
          .post<MatchableCookResource>(
            `admin/match-requests/${this.matchRequest.id}/search-cooks`,
            params,
            allowReadOnly(),
          )
          .then((response) => {
            const json = response.data
            // Sort the list based on distance by default
            this.matchableCooks = json.data.sort(
              (cookA, cookB) => cookA.distance - cookB.distance,
            )
            this.totalMatchableCooks = json.total
            resolve(true)
          })
          .catch(() => {
            toast.error('Fout bij ophalen koks.')
            resolve(false)
          })
      })
    },

    async getMatchRequestDataForSocialPost(matchRequestId: MatchRequestId) {
      return new Promise<MatchRequestInfoSocialPost | false>((resolve) => {
        api
          .get<ApiResponse<MatchRequestInfoSocialPost>>(
            `admin/match-requests/${matchRequestId}/social-post`,
          )
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Fout bij ophalen post.')
            resolve(false)
          })
      })
    },

    async getSocialPostsOfMatchRequest(matchRequestId: MatchRequestId) {
      return new Promise<SocialPost[] | false>((resolve) => {
        api
          .get<ApiResponse<SocialPost[]>>(
            `admin/match-requests/${matchRequestId}/social-posts`,
          )
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Fout bij ophalen posts')
            resolve(false)
          })
      })
    },

    async update(payload: UpdateMatchRequestWithInformation) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${payload.id}`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag aangepast!'))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvraag aanpassen mislukt.'))
      })
    },

    async updateFieldAutomatically(payload: MatchRequestAutomaticUpdateParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${payload.id}/update/automatic`, payload)
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvraag aanpassen mislukt.'))
      })
    },

    async updateFieldAutomaticallyWithValue(
      payload: MatchRequestAutomaticUpdateWithValueParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${payload.id}/update/automatic-value`, payload)
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvraag aanpassen mislukt.'))
      })
    },

    async updateField(payload: MatchRequestFieldParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${payload.id}/update/${payload.field}`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag succesvol aangepast!'))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvraag aanpassen mislukt.'))
      })
    },

    async updateDateField(payload: MatchRequestDateFieldParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${payload.id}/update/date-field`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag succesvol aangepast!'))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvraag aanpassen mislukt.'))
      })
    },

    async assignMatchmaker(matchRequestId: MatchRequestId, matchmakerId: UserId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/assign-matchmaker`, {
            matchmakerId,
          })
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag gekoppeld aan matchmaker'))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvraag koppelen mislukt.'))
      })
    },

    async massAssignMatchmaker(matchRequestIds: MatchRequestId[], matchmakerId: UserId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/mass-assign`, {
            ids: matchRequestIds,
            matchmakerId: matchmakerId,
          })
          .then(resolveWebSocketUpdate(resolve, 'Aanvragen gekoppeld aan matchmaker'))
          .catch(rejectWebSocketUpdate(resolve, 'Aanvragen koppelen mislukt.'))
      })
    },

    async addContactActivity(
      cookIds: CookId[],
      action: MatchRequestContact,
      isAddedManually: boolean,
    ) {
      if (
        action === MatchRequestContact.email &&
        !confirm(
          `Stuur mail naar ${
            cookIds.length === 1 ? 'deze thuiskok' : `${cookIds.length} thuiskoks`
          }?`,
        )
      ) {
        return
      }

      return new Promise<boolean>((resolve) => {
        if (this.isFrozen) {
          toast.info('Deze aanvraag is bevroren 🥶')
          resolve(false)
          return
        }

        api
          .post<{ success: boolean }>(
            `admin/match-requests/${this.matchRequest.id}/add-contact-activity`,
            {
              cooks: cookIds,
              action: action,
              isAddedManually: isAddedManually,
            },
          )
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten koks'))
      })
    },

    async addMatchableCookReaction(
      cookId: CookId,
      isPositive: boolean,
      isAddedManually: boolean,
    ) {
      return new Promise<boolean>((resolve) => {
        if (this.isFrozen) {
          toast.info('Deze aanvraag is bevroren 🥶')
          resolve(false)
          return
        }

        api
          .post<{ success: boolean }>(
            `admin/match-requests/${this.matchRequest.id}/cook/${cookId}/add-reaction`,
            {
              isPositive: isPositive,
              isAddedManually: isAddedManually,
            },
          )
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten koks'))
      })
    },

    async fetchSuggestUserInformation(cookId: CookId) {
      return new Promise<boolean>((resolve) => {
        api
          .get<ApiResponse<SuggestUsersResource>>(
            `admin/match-requests/${this.matchRequest.id}/cook/${cookId}/suggest-info`,
          )
          .then((result) => {
            const { cook, foodie } = result.data.data
            this.specificSuggestCook = cook
            this.specificSuggestFoodie = foodie
            resolve(true)
          })
          .catch(() => {
            toast.error('Gebruikers informatie ophalen voor voorstellen mislukt')
            resolve(false)
          })
      })
    },

    async addSuggestedCook() {
      return new Promise<boolean>((resolve) => {
        if (this.isFrozen) {
          toast.info('Deze aanvraag is bevroren 🥶')
          resolve(false)
          return
        }

        const params = this.suggestMatchRequest

        api
          .post(
            `admin/match-requests/${this.matchRequest.id}/cook/${params.cookId}/add-suggested`,
            params,
          )
          .then(resolveWebSocketUpdate(resolve, 'Thuiskok is voorgesteld'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten koks'))
      })
    },

    async fetchUpdateCaretaker(caretakerId: CaretakerId) {
      return new Promise<false | UpdateCaretakerWithId>((resolve) => {
        api
          .get<ApiResponse<UpdateCaretakerWithId>>(
            `admin/foodies/caretaker/${caretakerId}`,
          )
          .then((res) => {
            resolve(res.data.data)
          })

          .catch(() => {
            toast.error('Fout bij ophalen gegevens')
            resolve(false)
          })
      })
    },

    async updateCaretaker(caretakerId: CaretakerId, caretaker: UpdateCaretaker) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/foodies/caretaker/${caretakerId}/update`, caretaker)
          .then(resolveWebSocketUpdate(resolve, 'Updaten gegevens is gelukt!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten gegevens'))
      })
    },

    async fetchUpdateMatchRequest(matchRequestId: MatchRequestId) {
      return new Promise<false | UpdateMatchRequestWithInformation>((resolve) => {
        api
          .get<ApiResponse<UpdateMatchRequestWithInformation>>(
            `admin/match-requests/${matchRequestId}/update`,
          )
          .then((res) => {
            resolve(res.data.data)
          })

          .catch(() => {
            toast.error('Fout bij ophalen gegevens')
            resolve(false)
          })
      })
    },

    async updateMatchRequest(
      matchRequestId: MatchRequestId,
      matchRequest: UpdateMatchRequest,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/update`, matchRequest)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag is aangepast!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij aanpassen aanvraag'))
      })
    },

    async updateMatchRequestDetails(
      matchRequestId: MatchRequestId,
      matchRequestDetails: UpdateMatchRequestDetails,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(
            `admin/match-requests/${matchRequestId}/update/details`,
            matchRequestDetails,
          )
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag is aangepast!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij aanpassen aanvraag'))
      })
    },

    async pauseMatchRequest(
      matchRequestId: MatchRequestId,
      pauseMatchRequestParams: PauseMatchRequestParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/pause`, pauseMatchRequestParams)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag is gepauzeerd!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij pauzeren aanvraag'))
      })
    },

    async unpauseMatchRequest(matchRequestId: MatchRequestId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/unpause`)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag is geactiveerd!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij heractiveren aanvraag'))
      })
    },

    async stopMatchRequest(
      matchRequestId: MatchRequestId,
      stopMatchRequestParams: StopMatchRequestParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/stop`, stopMatchRequestParams)
          .then(resolveWebSocketUpdate(resolve, 'Aanvraag is gestopt!'))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij stoppen aanvraag'))
      })
    },

    async clone(params: MatchRequestCloneParams) {
      return new Promise<MatchRequestId | false>((resolve) => {
        api
          .post<
            ApiResponse<DetailMatchRequest>
          >(`admin/match-requests/${params.id}/clone`, params)
          .then(
            (response) => {
              toast.success('Aanvraag succesvol gekopieerd!')
              resolve(response.data.data.id)
            },
            () => {
              toast.error('Kopiëren van aanvraag mislukt')
              resolve(false)
            },
          )
      })
    },

    async markPraatjePotMatchRequest(matchRequestId: MatchRequestId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/mark-praatje-pot`)
          .then(
            resolveWebSocketUpdate(resolve, 'Aanvraag staat nu goed voor Praatje Pot!'),
          )
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten aanvraag'))
      })
    },

    async markVoorproefjeMatchRequest(matchRequestId: MatchRequestId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/match-requests/${matchRequestId}/mark-voorproefje`)
          .then(
            resolveWebSocketUpdate(resolve, 'Aanvraag staat nu goed als Voorproefje!'),
          )
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten aanvraag'))
      })
    },
  },

  getters: {
    matchRequest: (state): DetailMatchRequest => state.specificMatchRequest!,

    isPaused: (state): boolean => {
      return matchRequestIsPaused(
        state.specificMatchRequest?.status,
        state.specificMatchRequest?.onHoldUntil,
      )
    },

    isCancelled: (state): boolean => {
      return matchRequestIsCancelled(state.specificMatchRequest?.status)
    },

    isFrozen: (state): boolean => {
      return matchRequestIsFrozen(state.specificMatchRequest)
    },
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMatchRequestStore as any, import.meta.hot))
}
