<script setup lang="ts">
import { Toaster } from 'vue-sonner'
import { useDarkMode } from '/@src/stores/darkmode'

export type MinimalTheme = 'darker' | 'light'

interface MinimalLayoutProps {
  theme?: MinimalTheme
}

const props = withDefaults(defineProps<MinimalLayoutProps>(), {
  theme: 'darker',
})

const darkModeStore = useDarkMode()
</script>

<template>
  <div class="minimal-wrapper" :class="[props.theme]">
    <slot></slot>

    <Toaster rich-colors close-button :theme="darkModeStore.isDark ? 'dark' : 'light'" />
  </div>
</template>
