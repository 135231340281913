<script setup lang="ts">
import { match } from 'ts-pattern'
import type { BulmaColor, FAIconName } from '/@src/types/elements-ui'

interface SideCardProps {
  direction: 'left' | 'right'
  startOpen?: boolean
  wide?: boolean
  offset?: string
  sideCardColor?: BulmaColor
}

const props = defineProps<SideCardProps>()

const isOpen = ref(props.startOpen)
const showContent = ref(props.startOpen)

const chevronIsOpen = computed<FAIconName>(() =>
  match(props.direction)
    .returnType<FAIconName>()
    .with('left', () => 'fa-chevron-left')
    .with('right', () => 'fa-chevron-right')
    .exhaustive(),
)

const chevronIsClosed = computed<FAIconName>(() =>
  match(props.direction)
    .returnType<FAIconName>()
    .with('left', () => 'fa-chevron-right')
    .with('right', () => 'fa-chevron-left')
    .exhaustive(),
)

const { start: toggleTabsContent } = useTimeoutFn(
  () => (showContent.value = !showContent.value),
  400,
  { immediate: false },
)

const openCard = () => {
  if (!isOpen.value) {
    isOpen.value = true
    toggleTabsContent()
  }
}

const closeCard = () => {
  if (isOpen.value) {
    isOpen.value = false
    showContent.value = false
  }
}

const toggleCard = () => {
  if (isOpen.value) {
    closeCard()
  } else {
    openCard()
  }
}

const sideCardColor = computed(() => {
  if (!props.sideCardColor) {
    return undefined
  } else {
    return `is-${props.sideCardColor}`
  }
})

defineExpose({ openCard, closeCard, toggleCard })
</script>

<template>
  <VCard
    class="is-side-card"
    :class="[`is-${direction}`, isOpen && 'is-active', wide && 'wide-side-card']"
    :style="offset && { left: offset }"
    top-right-icon
    icon-action
    content-classes="is-scrollable"
    :header-classes="sideCardColor"
    @icon-click="toggleCard"
  >
    <template #toolbar-left>
      <slot v-if="showContent" name="title"></slot>
    </template>
    <template #icon>
      <VIcon
        id="sidecard-toggle-button"
        :icon="showContent ? chevronIsOpen : chevronIsClosed"
        force-clickable
      />
    </template>

    <template #content>
      <div v-show="showContent">
        <slot name="content" />
      </div>
    </template>

    <template v-if="showContent" #footer>
      <slot name="footer" />
    </template>
  </VCard>
</template>

<style scoped lang="scss">
:deep(.card-footer) {
  &:empty {
    display: none;
  }
}
</style>
