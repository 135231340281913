<script setup lang="ts">
import { type RouteLocationNormalized } from 'vue-router/auto'
import type { BulmaColor, FAIconName, VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'
import { useFinancesStore } from '/@src/stores/finances'
import { formatCurrency } from '/@src/utils/helpers'
import { userWalletTypeIconMap, userWalletTypeMap } from '/@src/mapping/user-wallets'
import { LoadingSpinner } from '/@src/models/standardIcons'
import { computed } from 'vue'
import { UserWalletType } from '/@src/types/user-wallets'
import { useModal } from '/@src/composable/useModal'
import { FinanceGroupedCategoryKeys, type FinanceReportKey } from '/@src/types/finances'
import { financeReportGroupIconMap, financeReportGroupMap } from '/@src/mapping/finances'
import { useFavicon } from '@vueuse/core'

useHead({
  title: 'Financiële Rapportage | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
    roles: 'finance',
  },
})

const financeStore = useFinancesStore()

const route = useRoute()
const router = useRouter()

const favicon = computed(() =>
  financeStore.isLoadingAny ? '/loading-favicon.svg' : '/favicon.svg',
)
useFavicon(favicon)

const { modalIsOpen, openModal } = useModal()

const categoryTo = computed<Partial<RouteLocationNormalized> | undefined>(() => {
  if (!financeStore.categoryKey) {
    return undefined
  }
  return {
    name: '/finances/category/[key]',
    params: { key: financeStore.categoryKey },
  }
})

const getIcon = (key: FinanceReportKey): FAIconName | undefined => {
  if (financeStore.loading[key]) {
    return LoadingSpinner
  } else if (financeStore.errors[key]) {
    return 'fa-x'
  } else if (financeStore.complete[key]) {
    return 'fa-check'
  } else {
    return undefined
  }
}

const getTabColor = (key: FinanceReportKey): BulmaColor | undefined => {
  if (financeStore.errors[key]) {
    return 'danger'
  } else if (financeStore.complete[key]) {
    return 'success'
  } else {
    return undefined
  }
}

const tabs: VTabsItem<FinanceReportKey>[] = [
  {
    label: 'Totalen',
    value: 'totals',
    icon: () => getIcon('totals'),
    color: () => getTabColor('totals'),
    to: {
      name: '/finances/totals',
    },
  },
  {
    label: () => {
      if (financeStore.categoryKey) {
        let key
        if (
          (Object.values(UserWalletType) as string[]).includes(financeStore.categoryKey)
        ) {
          key = userWalletTypeMap[financeStore.categoryKey as UserWalletType]
        } else if (
          (Object.values(FinanceGroupedCategoryKeys) as string[]).includes(
            financeStore.categoryKey,
          )
        ) {
          key =
            financeReportGroupMap[financeStore.categoryKey as FinanceGroupedCategoryKeys]
        }
        return `Categorie: ${key}`
      }
      return 'Categorie'
    },
    value: 'category',
    icon: () => {
      if (financeStore.loading.category) {
        return LoadingSpinner
      } else if (financeStore.errors.category) {
        return 'fa-x'
      } else if (financeStore.categoryKey) {
        if (
          (Object.values(UserWalletType) as string[]).includes(financeStore.categoryKey)
        ) {
          return userWalletTypeIconMap[financeStore.categoryKey as UserWalletType]
        } else if (
          (Object.values(FinanceGroupedCategoryKeys) as string[]).includes(
            financeStore.categoryKey,
          )
        ) {
          return financeReportGroupIconMap[
            financeStore.categoryKey as FinanceGroupedCategoryKeys
          ]
        }
        return 'fa-question-circle'
      } else {
        return undefined
      }
    },
    iconPack: () => {
      if (
        !financeStore.loading.category &&
        financeStore.categoryKey === UserWalletType.Ideal
      ) {
        return 'fab'
      }
    },
    color: () => getTabColor('category'),
    hidden: () => !financeStore.categoryKey,
    to: categoryTo,
  },
  {
    label: () => {
      if (financeStore.commissionTotal) {
        return `Commissie (${formatCurrency(financeStore.commissionTotal)})`
      } else {
        return 'Commissie'
      }
    },
    value: 'commission',
    icon: () => getIcon('commission'),
    color: () => getTabColor('commission'),
    to: {
      name: '/finances/commission',
    },
  },
  {
    label: () => {
      if (financeStore.commissionTableTotal) {
        return `Commissie Tabel (${financeStore.commissionTableTotal})`
      } else {
        return 'Commissie Tabel'
      }
    },
    value: 'commissionTable',
    icon: () => getIcon('commissionTable'),
    color: () => getTabColor('commissionTable'),
    to: {
      name: '/finances/commission-table',
    },
  },
  {
    label: () => {
      if (financeStore.mealTotal) {
        return `Maaltijden (${financeStore.mealTotal})`
      } else {
        return 'Maaltijden'
      }
    },
    value: 'meals',
    icon: () => getIcon('meals'),
    color: () => getTabColor('meals'),
    to: {
      name: '/finances/meals',
    },
  },
  {
    label: () => {
      if (financeStore.donationTotal) {
        return `Donaties (${formatCurrency(financeStore.donationTotal)})`
      } else {
        return 'Donaties'
      }
    },
    value: 'donations',
    icon: () => getIcon('donations'),
    color: () => getTabColor('donations'),
    to: {
      name: '/finances/donations',
    },
  },
  {
    label: () => {
      if (financeStore.balanceOverviewTotal) {
        return `Saldo Overzicht (${formatCurrency(financeStore.balanceOverviewTotal)})`
      } else {
        return 'Saldo Overzicht'
      }
    },
    value: 'balanceOverview',
    icon: () => getIcon('balanceOverview'),
    color: () => getTabColor('balanceOverview'),
    to: {
      name: '/finances/balance-overview',
    },
  },
  {
    label: () => {
      if (financeStore.balanceCorrectnessTotal) {
        return `Incorrecte Saldo's (${financeStore.balanceCorrectnessTotal})`
      } else {
        return "Incorrecte Saldo's"
      }
    },
    value: 'balanceCorrectness',
    icon: () => getIcon('balanceCorrectness'),
    color: () => getTabColor('balanceCorrectness'),
    to: {
      name: '/finances/balance-correctness',
    },
  },
  {
    label: () => {
      if (financeStore.data.yearOverview) {
        const years = financeStore.data.yearOverview.years.toSorted()
        if (years.length === 1) {
          return `Jaaroverzicht (${years[0]})`
        } else {
          const yearString = `${years[0]}-${years.at(-1)}`
          return `Jaaroverzicht (${yearString})`
        }
      } else {
        return 'Jaaroverzicht'
      }
    },
    value: 'yearOverview',
    icon: () => getIcon('yearOverview'),
    color: () => getTabColor('yearOverview'),
    to: {
      name: '/finances/year-overview',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)

onMounted(() => {
  if (route.name === '/finances') {
    router.replace({
      name: '/finances/totals',
    })
  }
})

onUnmounted(financeStore.stopAll)
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs">
      <template #pre-tabs>
        <VButton color="info" class="mr-3" @click="openModal"> Alles ophalen </VButton>
      </template>
    </VTabs>

    <RouterView v-slot="{ Component }">
      <Transition name="translate-page-x" mode="out-in">
        <KeepAlive>
          <Component :is="Component" />
        </KeepAlive>
      </Transition>
    </RouterView>

    <FinanceReportFetchAllModal v-model:open="modalIsOpen" />
  </div>
</template>

<style lang="scss" scoped></style>
