export function allowReadOnly() {
  return {
    headers: {
      allowReadOnly: true,
    },
  }
}

export function blockReadOnly() {
  return {
    headers: {
      blockReadOnly: true,
    },
  }
}
