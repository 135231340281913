<script setup lang="ts">
import { useReportingStore } from '/@src/stores/reporting'
import {
  BugAcceptableGroupParam,
  BugCollectTypeParam,
  BugCountsTypeParam,
  BugFiltersParam,
  BugGroupNameFiltersParam,
  BugGroupTypeParam,
  DefaultTimePeriodParam,
  type Report,
  type ReportingSortOption,
  type ReportParamOptions,
  type ReportParams,
  type StatusGrouping,
  type TimeGroup,
} from '/@src/types/reporting'
import { dayJSZod } from '/@src/utils/zodUtils'
import { z } from 'zod'
import { UserRoleEnum } from '/@src/types/users'
import { isMatch } from 'lodash'
import { useAuthStore } from '/@src/stores/auth'
import { match } from 'ts-pattern'
import { bugPresets, defaultReportTimeOptions } from '/@src/mapping/reporting'
import type { OptionsMap } from '/@src/types/elements-ui'
import ReportingTable from '/@src/components/molecules/table/ReportingTable.vue'
import { getReportingRows } from '/@src/utils/reporting'
import { useHead } from '@unhead/vue'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { BugStatus } from '/@src/types/bugReport'
import type { CamelCaseKeys } from 'camelcase-keys'
import { useAsyncReport } from '/@src/composable/useAsyncReport'
import { useTemplateRef } from 'vue'

useHead({
  title: 'Bugs Rapportage | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: 'developer',
  },
})

const authStore = useAuthStore()
const reportingStore = useReportingStore()

const router = useRouter()
const route = useRoute('/reporting/bugs')

const reportingTable = useTemplateRef('reportingTable')

const isLoading = ref(false)

const reportData = ref<Report<'bug', BugStatus> | null>(null)
const { fetchAsyncReport, isLoading: isLoadingAsync } = useAsyncReport('bug', reportData)

const nameFilter = ref<string>('')

const rows = computed<string[]>(() => {
  if (!reportData.value) {
    return []
  }

  const rowKeys = Object.keys(reportData.value.data)

  return getReportingRows(
    rowKeys,
    {
      ...reportData.value.params,
      hideExtraMunicipalities:
        isLoadingAsync.value || reportData.value.params.hideExtraMunicipalities,
    },
    reportingStore.sortOptions.bug,
    nameFilter.value,
    false,
    (row: string) => {
      const bugData = reportData.value!.data[row]
        ?.total as unknown as StatusGrouping<BugStatus>

      if (!bugData) {
        return 0
      }
      return bugData.resolved
    },
  )
})

const resetFormToRole = () => {
  const foundRole = authStore.authUser.roles.find((role) =>
    Object.keys(bugPresets).find((presetKey) => presetKey === role.role),
  )

  const newPreferences = reportingStore.resetPreferences<'bug'>(
    'bug',
    (foundRole?.role as UserRoleEnum) ?? UserRoleEnum.Matchmaker,
  )

  if (newPreferences) {
    resetForm({
      values: newPreferences,
    })
  }
}

const { handleSubmit, resetForm, setValues, values, useField } = useTypedForm<
  ReportParams<'bug'>
>({
  id: 'report-bugs',
  schema: {
    from: dayJSZod(),
    to: dayJSZod(),
    collectType: BugCollectTypeParam,
    countsType: BugCountsTypeParam,
    timePeriod: DefaultTimePeriodParam,
    groupType: BugGroupTypeParam,
    acceptableGroup: BugAcceptableGroupParam.nullable(),
    targets: z.never().nullable(),
    sort: z.string().nullable(),
    filters: BugFiltersParam,
    groupNameFilters: BugGroupNameFiltersParam.array().nullable(),
    allTime: z.boolean().optional(),
    returnIds: z.boolean().optional(),
  },
  initialValues: reportingStore.preferences.bug,
})

const { value: acceptableGroup } = useField('acceptableGroup')

if (!reportingStore.preferences.bug) {
  resetFormToRole()
}

const acceptableGroupsSwitch = computed({
  get(): boolean {
    return !!acceptableGroup.value
  },
  set(newValue): void {
    acceptableGroup.value = newValue ? values.groupType : null
  },
})
watch(
  () => values.groupType,
  (newValue) => {
    if (acceptableGroup.value) {
      acceptableGroup.value = newValue
    }
  },
)

const acceptableGroupText = computed(() =>
  match(values.groupType)
    .with('developer', () => 'ex-developers')
    .otherwise(() => 'error :D'),
)

const setPreset = (newRole: UserRoleEnum) => {
  if (!(newRole in bugPresets)) {
    return
  }

  setValues({
    from: values.from,
    to: values.to,
    allTime: values.allTime,
    returnIds: values.returnIds,
    ...bugPresets[newRole],
  })
}

const selectedPreset = computed<UserRoleEnum | null>(() => {
  const foundRole = Object.keys(bugPresets).find((role) => {
    const userRole = role as UserRoleEnum
    if (!bugPresets[userRole]) {
      return false
    }
    return isMatch(values, bugPresets[userRole]!)
  })

  if (foundRole) {
    return foundRole as UserRoleEnum
  }

  return null
})

const fetchReport = handleSubmit(
  async (values) => {
    isLoading.value = true

    const result = await reportingStore.fetchReporting<'bug', BugStatus>('bug', values)

    if (result) {
      reportData.value = result
      reportingTable.value?.closeSettings()
      reportingTable.value?.closePresets()

      await router.replace({ path: route.fullPath, query: { code: result.code } })
    }

    isLoading.value = false
  },
  () => reportingTable.value?.openSettings(),
)

const getAsyncReport = handleSubmit(
  async (values) => {
    const result = await fetchAsyncReport(values)

    if (result) {
      reportingTable.value?.closeSettings()
      reportingTable.value?.closePresets()

      await router.replace({ path: route.fullPath, query: { code: result.code } })
    }
  },
  () => reportingTable.value?.openSettings(),
)

const fetchIds = async (row: string, column: TimeGroup, status: BugStatus | 'total') => {
  if (!reportData.value) {
    return
  }

  isLoading.value = true

  await reportingStore.fetchIdsOfGroup(
    'bug',
    reportData.value.params,
    row,
    column,
    status,
  )

  isLoading.value = false
}

const reportParams: ReportParamOptions<'bug'> = {
  collectType: [
    {
      id: 'bugCreatedAt',
      name: 'Aangemaakt op',
    },
  ],
  timePeriod: defaultReportTimeOptions,
}

const sortOptions: OptionsMap<ReportingSortOption>[] = [
  {
    id: 'alphabetical',
    name: 'Alfabetisch',
  },
  {
    id: 'most-to-least',
    name: 'Meeste naar minste bugs',
  },
  {
    id: 'most-to-least-reversed',
    name: 'Minste naar meeste bugs',
  },
]
</script>

<template>
  <div>
    <ReportingTable
      ref="reportingTable"
      :data="reportData"
      :rows="rows"
      :loading="isLoading || isLoadingAsync"
      :param-options="reportParams"
      :selected-preset="selectedPreset"
      @submit="fetchReport"
      @submit-async="getAsyncReport"
      @reset="resetFormToRole"
      @preset="setPreset"
    >
      <template #acceptableGroup>
        <VField :label="`Groepeer ${acceptableGroupText}?`">
          <VSwitchBlock
            v-model="acceptableGroupsSwitch"
            name="acceptable-group-switch"
            left-label="Nee"
            right-label="Ja"
          />
        </VField>
      </template>

      <!-- Empty template to make the icon appear -->
      <template #developer />

      <template #legend>
        <table class="table">
          <tbody>
            <tr>
              <td>Totaal</td>
              <td>Actief</td>
              <td>Opgelost</td>
            </tr>
          </tbody>
        </table>
      </template>

      <template #table-filters>
        <TableFilter v-model="nameFilter" column-class="is-one-fifth" />
        <div class="column is-one-fifth py-0 mb-0">
          <VMultiselect v-model="reportingStore.sortOptions.bug" :options="sortOptions" />
        </div>
      </template>

      <template #body-row-cell="{ data, showPercentages, click }">
        <BugCell :data="data" :show-percentages="showPercentages" @click="click" />
      </template>

      <template #footer-cell="{ data, showPercentages, click }">
        <BugCell :data="data" :show-percentages="showPercentages" @click="click" />
      </template>

      <template
        #cell-modal="{
          data,
          showPercentages,
        }: {
          data:
            | {
                data: CamelCaseKeys<StatusGrouping<BugStatus>>
                row: string
                column: TimeGroup
              }
            | undefined
          showPercentages: boolean
        }"
      >
        <BugModalCell
          v-if="data"
          :data="data.data"
          :show-percentages="showPercentages"
          @click="(status) => fetchIds(data!.row, data!.column, status)"
        />
      </template>
    </ReportingTable>
  </div>
</template>

<style scoped lang="scss"></style>
