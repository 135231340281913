import type { MaybeArray } from '/@src/types/utils'
import type { PersistenceOptions } from 'pinia-plugin-persistedstate'
import type { PiniaPluginContext, StateTree } from 'pinia'
import { parseDateResponse } from '/@src/utils/date-formatter'
import { isArray } from 'lodash'

type RestoreFunction = (context: PiniaPluginContext) => void

interface PiniaPersistHelperOptions<S extends StateTree> extends PersistenceOptions<S> {
  convertToAmsterdamTimeZone?: boolean
}

function addRestoreFunction<S extends StateTree>(
  option: PiniaPersistHelperOptions<S>,
): PersistenceOptions<S> {
  if (option.afterHydrate) {
    const oldHydrate = option.afterHydrate

    const newRestoreFunction: RestoreFunction = (context) => {
      parseDateResponse(context.store, option.convertToAmsterdamTimeZone)
      oldHydrate(context)
    }

    return {
      ...option,
      afterHydrate: newRestoreFunction,
    }
  } else {
    return {
      ...option,
      afterHydrate: ({ store }) => {
        parseDateResponse(store, option.convertToAmsterdamTimeZone)
      },
    }
  }
}

export function usePersist<S extends StateTree>(
  options: boolean | MaybeArray<PiniaPersistHelperOptions<S>> | undefined,
): MaybeArray<PiniaPersistHelperOptions<S>> | undefined {
  if (typeof options === 'boolean') {
    return {
      afterHydrate: ({ store }) => {
        parseDateResponse(store, false)
      },
    }
  } else if (isArray(options)) {
    return options.map((o) => addRestoreFunction(o))
  } else if (options) {
    return addRestoreFunction(options)
  }

  return undefined
}
