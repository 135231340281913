<script setup lang="ts" generic="Data extends object, Keys extends string = string">
import type { RouteLocationRaw } from 'vue-router'
import type { VTableColumn } from '/@src/types/elements-ui'
import { isFunction } from 'lodash'

interface BodyCellProps {
  row: Data
  column: VTableColumn<Keys, Data>
}

const props = defineProps<BodyCellProps>()

const iconLeft = computed(() =>
  isFunction(props.column.iconLeft)
    ? props.column.iconLeft(props.row as Data, props.column)
    : props.column.iconLeft,
)
const iconRight = computed(() =>
  isFunction(props.column.iconRight)
    ? props.column.iconRight(props.row as Data, props.column)
    : props.column.iconRight,
)
const iconOnly = computed(() =>
  isFunction(props.column.iconOnly)
    ? props.column.iconOnly(props.row as Data, props.column)
    : props.column.iconOnly,
)
const iconPack = computed(() =>
  isFunction(props.column.iconPack)
    ? props.column.iconPack(props.row as Data, props.column)
    : props.column.iconPack,
)
const iconColor = computed(() =>
  isFunction(props.column.iconColor)
    ? props.column.iconColor(props.row as Data, props.column)
    : (props.column.iconColor ?? 'primary'),
)

const cellClass = computed(() => {
  const cell = props.column.cellClass

  if (!cell) {
    return undefined
  }

  if (isFunction(cell)) {
    return cell(props.row as Data, props.column)
  } else {
    return cell
  }
})
</script>

<template>
  <div>
    <RouterLink
      v-if="column.to && !!column.to(row as unknown as Data)"
      :to="column.to(row as unknown as Data) as RouteLocationRaw"
    >
      <span class="light-text" :class="cellClass">
        <template v-if="column.transform">
          {{ column.transform(row as unknown as Data, column.key) }}
        </template>
        <template v-else>
          <VIcon
            v-if="iconLeft"
            :icon-pack="iconPack"
            :icon="iconLeft"
            :color="iconColor"
            class="mr-1"
          />
          <VIconBox
            v-if="iconOnly"
            :icon-pack="iconPack"
            :icon="iconOnly"
            :color="iconColor"
            size="small"
          />
          <span v-if="!iconOnly" class="icon-label">
            {{ props.row[column.key as unknown as keyof Data] }}
          </span>
          <VIcon
            v-if="iconRight"
            :icon-pack="iconPack"
            :icon="iconRight"
            :color="iconColor"
            class="ml-1"
          />
        </template>
      </span>
    </RouterLink>
    <span v-else class="light-text" :class="cellClass">
      <VIcon
        v-if="iconLeft"
        :icon-pack="iconPack"
        :icon="iconLeft"
        :color="iconColor"
        class="mr-1"
      />
      <VIconBox
        v-if="iconOnly"
        :icon-pack="iconPack"
        :icon="iconOnly"
        :color="iconColor"
        size="small"
      />
      <span v-if="!iconOnly" class="icon-label">
        <template v-if="column.transform">
          {{ column.transform(row as unknown as Data, column.key) }}
        </template>
        <template v-else-if="column.key in props.row">
          {{ props.row[column.key as unknown as keyof Data] }}
        </template>
      </span>
      <VIcon
        v-if="iconRight"
        :icon-pack="iconPack"
        :icon="iconRight"
        :color="iconColor"
        class="ml-1"
      />
    </span>
  </div>
</template>

<style scoped lang="scss">
.icon-label:empty {
  display: none;
}
</style>
