<script setup lang="ts">
import { useReportingStore } from '/@src/stores/reporting'
import dayjs, { type Dayjs } from 'dayjs'
import type {
  MatchmakerKPIColumn,
  MatchmakerKPIResource,
  MatchmakersKPIReport,
  SpecificMatchmakerKPIResource,
} from '/@src/types/reporting'
import type { VTableColumns } from '/@src/types/elements-ui'
import { cloneDeep, sumBy } from 'lodash'
import { useModal } from '/@src/composable/useModal'
import { MatchmakerKPIReportChart } from '/@src/models/reporting'
import { useMatchRequestStore } from '/@src/stores/matchRequests'
import type { UserId } from '/@src/types/users'
import type { MatchRequestId } from '/@src/types/matchRequests'
import { dayjsUTC } from '/@src/utils/date-formatter'
import { useAdminUsersStore } from '/@src/stores/adminUsers'

definePage({
  meta: {
    roles: 'lead-matchmaker',
  },
})

interface TableData {
  id: UserId | 'total'
}

const reportingStore = useReportingStore()
const matchRequestStore = useMatchRequestStore()
const adminUserStore = useAdminUsersStore()

const router = useRouter()

const isLoading = ref(false)

const weekNumberInput = ref<Dayjs>(dayjs().startOf('week'))

const startDate = computed(() => weekNumberInput.value)
const endDate = computed(() => weekNumberInput.value.endOf('week'))

const data = ref<MatchmakerKPIResource>()
const kpiReport = computed<MatchmakersKPIReport | undefined>(() => data.value?.kpis)

const individualKpiData = ref<SpecificMatchmakerKPIResource>()

const matchmakers = computed<TableData[]>(() => {
  const matchmakers: TableData[] = cloneDeep(adminUserStore.matchmakers)
    .sort((user1, user2) => {
      if (user1.municipalities.length === 0) {
        return 1
      } else if (user2.municipalities.length === 0) {
        return -1
      } else {
        return user1.municipalities
          .sort()
          .join(',')
          .localeCompare(user2.municipalities.sort().join(','))
      }
    })
    .map((matchmaker) => {
      return {
        id: matchmaker.userId,
      }
    })

  matchmakers.push({
    id: 'total',
  })

  return matchmakers
})

const getKPIData = async () => {
  isLoading.value = true
  const result = await reportingStore.getMatchmakerKPIData(startDate.value, endDate.value)
  isLoading.value = false

  if (result) {
    data.value = result
  }
}

const getTotalPercentage = (key: ColumnKeys): string => {
  if (!kpiReport.value || key === 'name') {
    return '-'
  }

  const columValue = sumBy(Object.keys(kpiReport.value), (matchmakerId) => {
    const report = kpiReport.value![Number(matchmakerId)]?.[key] ?? 0

    if (key === 'match') {
      return report + (kpiReport.value![Number(matchmakerId)]?.matchStopped ?? 0)
    }
    return report
  })

  const total = sumBy(
    Object.keys(kpiReport.value),
    (matchmakerId) => kpiReport.value![Number(matchmakerId)]?.total ?? 0,
  )

  if (total === 0) {
    return '-'
  }

  const percentage = columValue / total

  return `${(percentage * 100).toFixed(0)}%`
}

const getPercentage = (id: number | 'total', key: ColumnKeys) => {
  if (key === 'name') {
    return '-'
  }

  if (id === 'total') {
    return getTotalPercentage(key)
  }

  let columnValue = kpiReport.value?.[id]?.[key] ?? 0
  if (key === 'match') {
    columnValue += kpiReport.value?.[id]?.matchStopped ?? 0
  }

  const total = kpiReport.value?.[id]?.total

  if (total === undefined) {
    return '-'
  }

  const percentage = columnValue / total

  return `${(percentage * 100).toFixed(0)}%`
}

const { modalIsOpen, openModal: getIndividualKPIReport } = useModal({
  open: async (matchmakerId: UserId | 'total') => {
    isLoading.value = true
    const result = await reportingStore.getSpecificMatchmakerKPIData(
      matchmakerId,
      dayjsUTC().startOf('year'),
      dayjsUTC(),
    )
    isLoading.value = false

    if (result) {
      individualKpiData.value = result
    } else {
      return false
    }
  },
  onClose: () => {
    individualKpiData.value = undefined
  },
})

const getMatchRequestIds = async (matchmakerId: UserId | 'total', key: ColumnKeys) => {
  if (key === 'name' || !data.value) {
    return
  }

  const result = await reportingStore.getSpecificMatchmakerKPIIds(
    matchmakerId,
    data.value!.startDate,
    data.value.endDate,
    key,
  )

  if (result) {
    openMatchRequests(result)
  }
}

const openMatchRequests = (result: MatchRequestId[]) => {
  if (result.length === 0) {
    return
  }

  matchRequestStore.reportIds = result

  // Workaround for not being able to open with a middle mouse click
  router.push({
    name: '/matching/reporting',
  })
}

const apexChartData = computed(() => {
  const defaultData = cloneDeep(MatchmakerKPIReportChart)

  if (individualKpiData.value) {
    defaultData.series[0].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.suggestedInOneWeek * 100),
    )
    defaultData.series[1].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.suggestedInTwoWeeks * 100),
    )
    defaultData.series[2].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.matchedInTwoWeeks * 100),
    )
    defaultData.series[3].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.matchedInOneMonth * 100),
    )
    defaultData.series[4].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.finishedAfter * 100),
    )
    defaultData.series[5].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.match * 100),
    )
    defaultData.series[6].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.matchStopped * 100),
    )
    defaultData.series[7].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.noCookFound * 100),
    )
    defaultData.series[8].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.cancelled * 100),
    )
    defaultData.series[9].data = Object.values(individualKpiData.value!.kpis).map(
      (data) => Math.round(data.notVulnerable * 100),
    )
    defaultData.xaxis.categories = individualKpiData.value.columns.map((column) =>
      column.replace(/(\d)+\?/, 'Week '),
    )
  }

  return defaultData
})

onMounted(getKPIData)

type ColumnKeys = 'name' | MatchmakerKPIColumn

const columns: VTableColumns<ColumnKeys, TableData> = [
  {
    key: 'name',
    label: 'Matchmaker',
    transform: (model) => {
      if (model.id === 'total') {
        return 'Totaal'
      } else {
        return adminUserStore.getMatchmaker(model.id).firstname
      }
    },
    onClick: (row) => getIndividualKPIReport(row.id),
  },
  {
    key: 'suggestedInOneWeek',
    label: 'Voorgesteld (1 week)',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'suggestedInTwoWeeks',
    label: 'Voorgesteld (2 weken)',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'matchedInTwoWeeks',
    label: 'Gematched (2 weken)',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'matchedInOneMonth',
    label: 'Gematched (1 maand)',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'matchedInTwoMonths',
    label: 'Gematched (2 maanden)',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'finishedAfter',
    label: 'Afgerond %',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'match',
    label: 'Match %',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'noCookFound',
    label: 'Geen kok gevonden %',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'notVulnerable',
    label: 'Niet kwetsbaar %',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
  {
    key: 'cancelled',
    label: 'Geannuleerd %',
    transform: (model, column) => getPercentage(model.id, column),
    onClick: (row, column) => getMatchRequestIds(row.id, column),
  },
]
</script>

<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column is-three-quarters">
        <WeekNumberInput v-model="weekNumberInput" @enter="getKPIData" />
      </div>

      <div class="column is-one-quarter">
        <VButton color="success" :loading="isLoading" @click.prevent="getKPIData">
          Haal gegevens op
        </VButton>
      </div>
    </div>

    <VTable :data="matchmakers" :columns="columns" :loading="isLoading" />

    <VModal
      v-model:open="modalIsOpen"
      size="big"
      :title="`KPI gegevens van ${
        adminUserStore.getMatchmaker(individualKpiData?.userId).firstname
      } tussen ${individualKpiData?.startDate.format(
        'D MMMM',
      )} en ${individualKpiData?.endDate.format('D MMMM')}`"
    >
      <template v-if="individualKpiData" #content>
        <apexchart
          :series="apexChartData.series"
          :options="apexChartData"
          :type="apexChartData.chart.type"
        />
      </template>
    </VModal>
  </div>
</template>

<style scoped lang="scss"></style>
