<script setup lang="ts">
import {
  type MinimalAdminTaskParams,
  type SearchTaskableResult,
  type TaskableId,
  TaskableOptions,
  type TaskableOptionsType,
  TaskableType,
} from '/@src/types/admin-tasks'
import { useTasksStore } from '/@src/stores/tasks'
import { dayJSZod, idValidation } from '/@src/utils/zodUtils'
import { z } from 'zod'
import { computed } from 'vue'
import { match, P } from 'ts-pattern'
import { LoadingSpinner, SaveIcon } from '/@src/models/standardIcons'
import type { RouteLocationNormalized } from 'vue-router/auto'
import { getTaskableNameTitle, getTaskableRoute } from '/@src/utils/admin-tasks'
import {
  TaskableIcon,
  TaskableTypeMapping,
  taskableTypeOptions,
} from '/@src/mapping/admin-tasks'
import { debounce } from 'lodash'
import { useTypedForm } from '/@src/composable/useTypedForm'
import type { RouteLocationRaw } from 'vue-router'

type AdminTaskModalEmits = {
  close: []
}

interface AdminTaskModalProps {
  taskableId?: TaskableId
  taskableType?: TaskableOptionsType
}

const emits = defineEmits<AdminTaskModalEmits>()
const props = defineProps<AdminTaskModalProps>()

const taskStore = useTasksStore()

const route = useRoute()

const isOpen = defineModel<boolean>('open')
const isLoading = ref(false)

const isSearchingTaskable = ref(false)
const searchedTaskableResult = ref<SearchTaskableResult>()

watch(
  () => isOpen.value,
  (newIsOpen) => {
    if (!newIsOpen) {
      return
    }

    setFieldValue('taskableType', props.taskableType)
    setFieldValue('taskableId', props.taskableId)

    if (!props.taskableType && !props.taskableId) {
      const { type, id } = match<
        RouteLocationNormalized,
        { type?: TaskableOptionsType | null; id?: string | string[] | null }
      >(route)
        .with(
          {
            name: P.when((name) => name?.toString().includes('matching')),
            params: P.select('params'),
          },
          ({ params }) => {
            return {
              type: TaskableType.MatchRequest,
              id: (params as { matching: string } | undefined)?.matching,
            }
          },
        )
        .with(
          {
            name: P.when((name) => name?.toString().includes('match')),
            params: P.select('params'),
          },
          ({ params }) => {
            return {
              type: TaskableType.Match,
              id: (params as { match: string } | undefined)?.match,
            }
          },
        )
        .with(
          {
            name: P.when((name) => name?.toString().includes('user')),
            params: P.select('params'),
          },
          ({ params }) => {
            return {
              type: TaskableType.User,
              id: (params as { user: string } | undefined)?.user,
            }
          },
        )
        .with(
          {
            name: P.when((name) => name?.toString().includes('event')),
            params: P.select('params'),
          },
          ({ params }) => {
            return {
              type: TaskableType.EventRequest,
              id: (params as { event: string } | undefined)?.event,
            }
          },
        )
        .otherwise(() => {
          return {
            type: null,
            id: null,
          }
        })

      setFieldValue('taskableType', type ?? null)
      setFieldValue('taskableId', id ? (parseInt(id as string) as TaskableId) : null)
    }
  },
)

const {
  handleSubmit,
  values,
  handleReset,
  meta,
  resetForm,
  setFieldValue,
  useField,
  formProps,
} = useTypedForm<MinimalAdminTaskParams>({
  id: 'quick-task-modal',
  schema: {
    taskableType: TaskableOptions.nullable(),
    taskableId: idValidation<TaskableId>().nullable(),
    scheduledFor: dayJSZod(),
    title: z.string().min(1),
  },
  initialValues: {
    title: undefined,
    scheduledFor: undefined,
    taskableId: null,
    taskableType: null,
  },
})

const { value: taskableIdValue, setErrors: setTaskableIdErrors } = useField('taskableId')
const { value: taskableTypeValue } = useField('taskableType')

const idPrefix = computed(() =>
  match(values.taskableType)
    .with(TaskableType.User, () => 'User')
    .with(TaskableType.Match, () => 'Match')
    .with(TaskableType.MatchRequest, () => 'Aanvraag')
    .with(TaskableType.EventRequest, () => 'Evenement')
    .otherwise(() => 'Taskable'),
)

const searchTaskable = async () => {
  if (!taskableIdValue.value || !taskableTypeValue.value) {
    return
  }

  if (
    taskableIdValue.value === searchedTaskableResult.value?.taskableId &&
    taskableTypeValue.value === searchedTaskableResult.value?.taskableType
  ) {
    return
  }

  isSearchingTaskable.value = true
  const result = await taskStore.searchTaskable(
    taskableIdValue.value,
    taskableTypeValue.value,
  )
  isSearchingTaskable.value = false

  if (result) {
    searchedTaskableResult.value = result
  } else {
    setTaskableIdErrors(`${TaskableTypeMapping[taskableTypeValue.value]} niet gevonden`)
    searchedTaskableResult.value = undefined
  }
}

const searchTaskableDebounce = debounce(searchTaskable, 500)

watch(taskableIdValue, searchTaskableDebounce)
watch(taskableTypeValue, searchTaskableDebounce)

const onSubmit = handleSubmit(async (values) => {
  isLoading.value = true
  const result = await taskStore.createMinimalTask(values)
  isLoading.value = false

  if (result) {
    resetForm({
      values: {
        title: undefined,
        scheduledFor: undefined,
        taskableId: null,
        taskableType: null,
      },
    })
    closeModal()
  }
})

const closeModal = () => {
  isOpen.value = false
  emits('close')
}
</script>

<template>
  <VModal
    v-model:open="isOpen"
    title="Snel een herinnering maken"
    actions="center"
    size="big"
    cancel-label="Sluiten"
    @close="closeModal"
  >
    <template #content>
      <FormWrapper v-bind="formProps" v-slot="{ k }">
        <div class="columns is-multiline has-text-centered">
          <div class="column is-half">
            <FormDatePicker :name="k.scheduledFor" label="Datum + Tijd" type="datetime" />
          </div>

          <div class="column is-half">
            <FormInput :name="k.title" label="Titel" />
          </div>

          <div class="column is-full">
            <FormRadioIcon
              :name="k.taskableType"
              label="Type (Optioneel)"
              :options="taskableTypeOptions"
            />
          </div>

          <template v-if="values.taskableType">
            <div v-show="values.taskableType" class="column is-half">
              <FormInput
                :name="k.taskableId"
                :label="`${idPrefix} ID (Optioneel)`"
                type="number"
                :icon-right="isSearchingTaskable ? LoadingSpinner : undefined"
              />
            </div>

            <RouterLink
              v-if="
                searchedTaskableResult?.taskableType &&
                searchedTaskableResult?.taskableName
              "
              class="column is-half is-flex is-justify-content-center"
              :to="
                getTaskableRoute({
                  taskableType: searchedTaskableResult.taskableType,
                  taskableId: searchedTaskableResult.taskableId,
                }) as RouteLocationRaw
              "
            >
              <VBlock
                class="is-justify-content-center is-align-items-end"
                :icon="TaskableIcon[searchedTaskableResult.taskableType]"
                icon-color="info"
                icon-outlined
                :title="getTaskableNameTitle(searchedTaskableResult)"
                :subtitle="searchedTaskableResult.taskableName"
                icon-rounded
              />
            </RouterLink>
            <VBlock
              v-else
              class="column is-half is-flex is-justify-content-center is-align-items-center mb-0"
              :icon="
                isSearchingTaskable
                  ? LoadingSpinner
                  : taskableTypeValue
                    ? TaskableIcon[taskableTypeValue]
                    : 'fa-question'
              "
              icon-color="danger"
              :title="
                taskableTypeValue && taskableIdValue
                  ? getTaskableNameTitle({
                      taskableType: taskableTypeValue!,
                      taskableId: taskableIdValue!,
                    })
                  : 'Geen'
              "
              subtitle="-"
              icon-rounded
            />
          </template>
        </div>
      </FormWrapper>
    </template>

    <template #actions>
      <div class="column is-flex is-justify-content-center">
        <ResetButton
          :time="0.5"
          min-width="80%"
          :loading="isLoading"
          :disabled="!meta.dirty"
          @reset="handleReset"
        />
      </div>
      <div class="column">
        <VButton
          width="full"
          :disabled="!meta.dirty"
          color="success"
          :icon-left="SaveIcon"
          :loading="isLoading"
          @click="onSubmit"
        >
          Opslaan
        </VButton>
      </div>
    </template>
  </VModal>
</template>

<style scoped lang="scss"></style>
