<script setup lang="ts">
import type {
  VPlaceloadAvatarRounded,
  VPlaceloadAvatarSize,
} from '/@src/types/elements-ui'

export interface VPlaceloadAvatarProps {
  size?: VPlaceloadAvatarSize
  rounded?: VPlaceloadAvatarRounded
  centered?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<VPlaceloadAvatarProps>(), {
  size: 'small',
  rounded: 'sm',
})
</script>

<template>
  <div
    class="placeload-avatar"
    :class="[
      !props.disabled && `loads`,
      props.size && `is-${props.size}`,
      props.centered && `is-centered`,
      props.rounded && `is-rounded-${props.rounded}`,
    ]"
  ></div>
</template>

<style lang="scss"></style>
