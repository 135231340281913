import { Dayjs } from 'dayjs'
import { z } from 'zod'
import type { UserId } from '/@src/types/users'
import type { AbstractId } from '/@src/types/utils'
import type { MatchId } from '/@src/types/matches'
import type { MatchRequestId } from '/@src/types/matchRequests'
import type { EventRequestId } from '/@src/types/eventRequests'

export type AdminTaskId = AbstractId<'admin-task'>

export type TaskableId = UserId | MatchId | MatchRequestId | EventRequestId

export enum TaskableType {
  User = 'user',
  Match = 'match',
  MatchRequest = 'match_request',
  EventRequest = 'event_request',
}

export const TaskableOptions = z.nativeEnum(TaskableType)
export type TaskableOptionsType = z.infer<typeof TaskableOptions>

export enum AdminTaskStatus {
  Active = 'active',
  Completed = 'completed',
  Postponed = 'postponed',
  Cancelled = 'cancelled',
}

export enum AdminTaskPriority {
  Normal = 'normal',
  High = 'high',
}
export const PriorityOptions = z.nativeEnum(AdminTaskPriority)
export type PriorityOptionsType = z.infer<typeof PriorityOptions>

export type RepeatType = 'days' | 'weeks' | 'months'
export type RepeatAction = `repeat:${number} ${RepeatType}`

export enum AdminTaskCategoryList {
  MatchRequestSuggestedFollowUp = 'Opvolging voorgesteld',
  MatchFollowUp = 'Opvolging match',
  Balance = 'Saldo gerelateerd',
  NotAutomatic = 'Zelf aangemaakt',
  Null = 'Overige',
  Finished = 'Afgerond',
}
export type CategoryListType = keyof typeof AdminTaskCategoryList

export interface MinimalAdminTaskParams {
  taskableType: TaskableOptionsType | null
  taskableId: TaskableId | null

  scheduledFor: Dayjs
  title: string
}

export interface AdminTaskParams extends MinimalAdminTaskParams {
  category: string | null
  description?: string | null //CKEditor ruining the day
  action: RepeatAction | null

  shouldNotify: boolean
  priority: PriorityOptionsType

  userId: UserId | null
}

export interface AdminTask {
  id: AdminTaskId
  userId: UserId

  taskableType: TaskableOptionsType | null
  taskableId: TaskableId | null
  taskableName: string | null
  taskableDate: Dayjs | null

  scheduledFor: Dayjs
  title: string

  category: string | null
  description: string | null
  action: RepeatAction | null

  shouldNotify: boolean
  priority: PriorityOptionsType

  isAutomatic: boolean
  status: AdminTaskStatus
  type: string | null
}

export interface AutomaticTask {
  title: string
  titleLine: number
  description: string
  descriptionLine: number
  fileName: string
  lineNumber: number
  function: string
  functionLine: number
  type: string
  typeLine: number
}

export interface TaskModelParams {
  open: boolean
  task?: AdminTask
  taskableId?: TaskableId
  taskableType?: TaskableOptionsType
}

export type TaskPostponeType = 'day' | 'week' | 'other'

export interface SearchTaskableResult {
  exists: boolean
  taskableName: string
  taskableId: TaskableId
  taskableType: TaskableType
}

export type AdminTaskAutomaticUpdateField = 'should_notify' | 'priority'
export type AdminTaskAutomaticUpdateParams = {
  id: AdminTaskId
  field: AdminTaskAutomaticUpdateField
}

export interface ExMatchmakerAdminTask {
  id: AdminTaskId
  userId: UserId

  taskableType: TaskableType | null
  taskableId: TaskableId | null
  taskableName: string | null
  taskableDate: Dayjs | null

  title: string

  scheduledFor: Dayjs

  status: AdminTaskStatus
  priority: AdminTaskPriority
  type: string | null
}
