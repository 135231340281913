import defu from 'defu'

export interface SpawnEmojiProps {
  location: {
    x: number
    y: number
  }
  chance?: number
  emojis?: string[]
  text?: string[]
  centerOfPage?: boolean
}

type Subscriber = (props: Required<SpawnEmojiProps>) => boolean

class EmojiObserver {
  subscribers: Subscriber[]

  constructor() {
    this.subscribers = []
  }

  subscribe = (subscriber: Subscriber) => {
    this.subscribers.push(subscriber)

    return () => {
      const index = this.subscribers.indexOf(subscriber)
      this.subscribers.splice(index, 1)
    }
  }

  pop = (initialProps: SpawnEmojiProps): boolean => {
    const props = defu(initialProps, {
      chance: 0.1,
      emojis: ['🦄', '😎', '🥳', '🎉', '🐧'],
      text: ['Nice!', 'Super!', 'Goed bezig!'],
      centerOfPage: false,
    })

    if (Math.random() > props.chance) {
      return false
    }

    return this.subscribers.some((subscriber) => subscriber(props))
  }
}

const state = new EmojiObserver()

export const emojiPopper = {
  subscribe: state.subscribe,
  pop: state.pop,
}
