import { type ComputedRef, inject, type InjectionKey } from 'vue'
import { $FieldKey } from '/@src/types/inject-types'

export interface Field {
  color?: ComputedRef<string | undefined>
}

export function useVFieldInjection(injectionString: InjectionKey<Field> = $FieldKey) {
  const $field = inject(injectionString, undefined)

  return {
    color: $field?.color,
  }
}
