<script setup lang="ts">
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { SimpleWebSocketMessage } from '/@src/types/webSockets'
import { UserRoleEnum } from '/@src/types/users'
import { toast } from 'vue-sonner'
import { useAuthStore } from '/@src/stores/auth'
import { useGlobalsStore } from '/@src/stores/global'
import { useTasksStore } from '/@src/stores/tasks'
import type { MatchmakerStatisticsResult } from '/@src/types/dashboard'
import type { TotalMonthlyTargets } from '/@src/types/monthly-targets'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { useMonthlyTargetsStore } from '/@src/stores/monthlyTargets'
import dayjs from 'dayjs'

interface GlobalsProps {
  hideBars?: boolean
}

const props = defineProps<GlobalsProps>()

const globalsStore = useGlobalsStore()
const authStore = useAuthStore()

const taskStore = useTasksStore()

const focusMunicipalityStore = useFocusMunicipalitiesStore()
const monthlyTargetStore = useMonthlyTargetsStore()

const lockdownModalIsOpen = ref(false)
const lockdownReason = ref<string>()
const lockdownAdmin = (message: { reason?: string }) => {
  lockdownModalIsOpen.value = true
  lockdownReason.value = message.reason
}

const websocketCallback = (message: SimpleWebSocketMessage) => {
  if (
    (!message.userId && authStore.hasRole(UserRoleEnum.Developer)) ||
    (message.userId && authStore.user?.id === message.userId)
  ) {
    toast.success(message.message)
  }
}

const updateMatchmakerStats = (stats: { statistics: MatchmakerStatisticsResult }) => {
  globalsStore.matchmakerStatistics = stats.statistics
}

useWebSocket({ event: 'AdminMessage', callback: websocketCallback })
useWebSocket({ event: 'MatchmakerStatistics', callback: updateMatchmakerStats })
useWebSocket({ event: 'LockdownAdmin', callback: lockdownAdmin })

const redFocusCount = computed<number>(() => {
  return focusMunicipalityStore.groupedMunicipalityTargetStatuses.red.matchActual
})

const redFocusTargets = computed<TotalMonthlyTargets>(() => {
  return {
    bronzeValue:
      focusMunicipalityStore.groupedMunicipalityTargetStatuses.red.normalisedTarget ?? 0,
    silverValue: -1,
    goldValue:
      focusMunicipalityStore.groupedMunicipalityTargetStatuses.red.totalTarget ?? 0,
  }
})

const redFocusTexts = {
  gold: 'Aantal personen die aan het einde van het kwartaal in rode focus-gemeentes gematcht moet zijn: {value}',
  silver: '',
  bronze:
    'Aantal personen die nu gematcht zou moeten zijn in rode focus-gemeentes: {value}',
  value: 'Gematchte personen in rode focus-gemeentes: {value}',
}

const localCount = computed<number>(() => {
  if (!focusMunicipalityStore.targetStatus) {
    return 0
  }

  const municipalities = Object.values(focusMunicipalityStore.targetStatus)
  return municipalities.reduce((acc, municipality) => {
    if (municipality.totalTarget && municipality.matchActual > municipality.totalTarget) {
      return acc + (municipality.totalTarget ?? 0)
    } else {
      return acc + municipality.matchActual
    }
  }, 0)
})

const localTargets = computed<TotalMonthlyTargets>(() => {
  if (!focusMunicipalityStore.targetStatus) {
    return {
      bronzeValue: 0,
      silverValue: -1,
      goldValue: 0,
    }
  }
  const municipalities = Object.values(focusMunicipalityStore.targetStatus)
  return municipalities.reduce(
    (acc, municipality) => {
      return {
        bronzeValue: acc.bronzeValue + (municipality.normalisedTarget ?? 0),
        silverValue: -1,
        goldValue: acc.goldValue + (municipality.totalTarget ?? 0),
      }
    },
    {
      bronzeValue: 0,
      silverValue: -1,
      goldValue: 0,
    },
  )
})

const localTexts = {
  gold: 'Lokaal: Aantal personen dat aan het einde van het kwartaal gematcht moet zijn: {value}',
  silver: '',
  bronze: 'Lokaal: Aantal personen die nu gematcht zou moeten zijn: {value}',
  value: 'Lokaal: Gematchte personen: {value}',
}

const nationCount = computed<number>(() => {
  return (globalsStore.newMatchesCount ?? 0) - localCount.value
})

const nationTargets = computed<TotalMonthlyTargets>(() => {
  const totalTarget = monthlyTargetStore.currentYearlyTotalTarget

  const weekNumber = dayjs().week()

  return {
    bronzeValue: (weekNumber / 52) * totalTarget.bronzeValue,
    silverValue: -1,
    goldValue: totalTarget.bronzeValue,
  }
})

const nationTexts = {
  gold: 'Landelijk: Aantal personen dat aan het einde van het kwartaal gematcht moet zijn: {value}',
  silver: '',
  bronze: 'Landelijk: Aantal personen die nu gematcht zou moeten zijn: {value}',
  value: 'Landelijk: Gematchte personen: {value}',
}

const initialDeficit = -230

const currentDeficit = computed(
  () =>
    (focusMunicipalityStore.groupedMunicipalityTargetStatuses.red?.matchActual ?? 0) -
    (focusMunicipalityStore.groupedMunicipalityTargetStatuses.red?.normalisedTarget ?? 0),
)

const deficitDifference = computed(() =>
  currentDeficit.value === 0 ? 0 : Math.round(initialDeficit - currentDeficit.value),
)
const deficitDifferencePercentage = computed(
  () => `${50 + (deficitDifference.value / initialDeficit) * 50}%`,
)
const deficitDifferenceLabel = computed(() => {
  if (Math.abs(deficitDifference.value) < 0.5) {
    return '0'
  } else if (deficitDifference.value < 0) {
    return `+${Math.abs(deficitDifference.value).toFixed(0)}`
  } else {
    return `-${deficitDifference.value.toFixed(0)}`
  }
})

/**
 * Deficit difference of +2 = Bronze
 * Deficit difference of +72 = Silver
 * Deficit difference of +122 = Gold
 */
const deficitColour = computed(() => {
  if (deficitDifference.value === 0) {
    return 'has-background-gray-light'
  } else if (deficitDifference.value <= -122) {
    return 'has-background-gold'
  } else if (deficitDifference.value <= -72) {
    return 'has-background-silver'
  } else if (deficitDifference.value <= -2) {
    return 'has-background-bronze'
  } else {
    return 'has-background-danger'
  }
})

onMounted(async () => {
  if (authStore.hasRole(UserRoleEnum.External)) {
    globalsStore.hasLoaded = true
  } else if (!globalsStore.doneLoading && !authStore.hasRole(UserRoleEnum.External)) {
    await globalsStore.fetchGlobalData()
  }

  const userHash = await authStore.fetchUserHash()

  if (userHash !== authStore.userHash || !authStore.user) {
    await authStore.check()
    authStore.userHash = userHash
  }
})
</script>

<template>
  <div>
    <template v-if="!hideBars && globalsStore.doneLoading">
      <GlobalTargetBar
        :targets="redFocusTargets"
        :value="redFocusCount"
        :disable-targets="{ silver: true }"
        :texts="redFocusTexts"
        :bottom="0"
      />

      <GlobalTargetBar
        :targets="localTargets"
        :value="localCount"
        :disable-targets="{ silver: true }"
        :texts="localTexts"
        bottom="-0.23rem"
      />

      <GlobalTargetBar
        :targets="nationTargets"
        :value="nationCount"
        :disable-targets="{ silver: true }"
        :texts="nationTexts"
        bottom="-0.46rem"
      />

      <div class="deficit-bar has-background-grey is-hidden">
        <div
          v-tooltip.left="
            `Achterstand: ${currentDeficit.toFixed(0)}, verschil begin Q3: ${deficitDifferenceLabel}`
          "
          class="mid-point"
          :class="deficitDifference < 0 ? deficitColour : 'has-background-grey'"
          :style="{ width: '50%', zIndex: deficitDifference < 0 ? 2 : 1 }"
        ></div>

        <div
          v-tooltip.left="
            `Achterstand: ${currentDeficit.toFixed(0)}, verschil begin Q3: ${deficitDifferenceLabel}`
          "
          class="current-status"
          :class="deficitColour"
          :style="{
            width: deficitDifferencePercentage,
            zIndex: deficitDifference < 0 ? 1 : 2,
          }"
        ></div>
      </div>
    </template>

    <EmojiPopper />

    <LockdownModal
      v-if="lockdownModalIsOpen"
      v-model:open="lockdownModalIsOpen"
      :reason="lockdownReason"
      @close="lockdownModalIsOpen = false"
    />

    <QuickAdminTaskModal
      v-model:open="taskStore.taskModalParams.open"
      :taskable-id="taskStore.taskModalParams.taskableId"
      :taskable-type="taskStore.taskModalParams.taskableType"
      @close="taskStore.closeTaskModal"
    />
  </div>
</template>

<style scoped lang="scss">
%bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.2rem;
}

%border {
  border-right: 2px solid black;
}

.deficit-bar {
  @extend %bar;

  bottom: -0.28rem;
  width: 100vw;

  .mid-point {
    @extend %bar;
    @extend %border;
  }

  .current-status {
    @extend %bar;
    @extend %border;
  }
}
</style>
