<script setup lang="ts">
import { mobileOperatingSystem } from '/@src/utils/entity-checks'

definePage({
  meta: {
    requiresAdmin: true,
  },
})
</script>

<template>
  <div class="page-content-inner">
    <h3 class="title is-5 my-4">TG-Admin apps</h3>
    <p>Hier staan links en instructies om de app te downloaden</p>

    <div
      class="columns is-multiline mt-3 is-flex"
      :class="mobileOperatingSystem === 'iOS' && 'is-flex-direction-column-reverse'"
    >
      <div class="column is-one-half-desktop is-full-mobile">
        <VCard
          :highlight="mobileOperatingSystem === 'Android'"
          :lowlight="
            mobileOperatingSystem !== 'Android' && mobileOperatingSystem !== 'unknown'
          "
          full-height
        >
          <template #title>Android</template>
          <template #icon>
            <VIcon
              icon="fa-android"
              icon-pack="fa-brands"
              size="large"
              font-awesome-icon-size="2x"
              color="info"
            />
          </template>
          <template #content>
            <div class="columns is-multiline">
              <div class="column is-full">
                Klik op de knop hieronder op je Android telefoon om naar de Google Play
                Store te gaan. Zorg wel dat je daar bent ingelogd met je Thuisgekookt
                e-mailadres, anders kan je de app niet zien. In de Google Play Store kan
                je vervolgens de app installeren en zou alles gelijk moeten werken
              </div>
            </div>

            <div class="column is-full is-flex is-justify-content-center">
              <a
                class="is-fullwidth"
                href="https://play.google.com/store/apps/details?id=com.thuisgekookt.thuisgekooktImportContacts"
                target="_blank"
              >
                <VButton width="full" color="info">
                  Google Play Store
                  <template #icon-left>
                    <VIcon icon="fa-android" icon-pack="fa-brands" />
                  </template>
                </VButton>
              </a>
            </div>
          </template>
        </VCard>
      </div>

      <div class="column is-one-half-desktop is-full-mobile">
        <VCard
          :highlight="mobileOperatingSystem === 'iOS'"
          :lowlight="
            mobileOperatingSystem !== 'iOS' && mobileOperatingSystem !== 'unknown'
          "
          full-height
        >
          <template #title>iOS</template>
          <template #icon>
            <VIcon
              icon="fa-apple"
              icon-pack="fa-brands"
              size="large"
              font-awesome-icon-size="2x"
              color="info"
            />
          </template>
          <template #content>
            <div class="columns is-multiline">
              <div class="column is-full">
                Klik op de knop hieronder op je iPhone om naar de App Store te gaan. Daar
                kan je de app installeren. Daarna kan je de app gelijk helemaal gebruiken.
                Als we de app updaten worden die of vanzelf geïnstalleerd of kan je naar
                de App Store gaan om de app handmatig te updaten.
              </div>
            </div>

            <div class="column is-full is-flex is-justify-content-center">
              <a
                class="is-fullwidth"
                href="https://apps.apple.com/app/tg-admin/id6450715429"
                target="_blank"
              >
                <VButton width="full" color="info">
                  App Store
                  <template #icon-left>
                    <VIcon icon="fa-apple" icon-pack="fa-brands" />
                  </template>
                </VButton>
              </a>
            </div>
          </template>
        </VCard>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
