import { z } from 'zod'

/**
 * Named this with the Enum suffix because else it's a bit too generic of a name
 */
export enum TransportEnum {
  NoPreference = 'no_preference',
  Delivery = 'delivery',
  Takeaway = 'takeaway',
}
export const TransportZodOptions = z.nativeEnum(TransportEnum)
export type TransportType = z.infer<typeof TransportZodOptions>

export enum RoleTypeEnum {
  Foodie = 'foodie',
  Cook = 'cook',
  Both = 'both',
}

export const RoleTypeOptions = z.nativeEnum(RoleTypeEnum)
export type RoleType = z.infer<typeof RoleTypeOptions>
