<script setup lang="ts">
import type {
  BulmaSize,
  FAIconName,
  FAIconPack,
  VIconColor,
} from '/@src/types/elements-ui'
import { LoadingSpinner } from '/@src/models/standardIcons'

interface VBlockProps {
  title?: string | number
  subtitle?: string
  infratitle?: string
  icon?: FAIconName
  iconSize?: BulmaSize
  iconColor?: VIconColor
  iconPack?: FAIconPack
  iconBorder?: boolean
  iconRounded?: boolean
  iconOutlined?: boolean
  center?: boolean
  lighter?: boolean
  narrow?: boolean
  vertical?: boolean
  reversed?: boolean
  loading?: boolean
  overrideClickable?: boolean
}

const props = withDefaults(defineProps<VBlockProps>(), {
  title: undefined,
  subtitle: undefined,
  infratitle: undefined,
  icon: undefined,
  iconSize: 'normal',
  iconColor: 'info',
  iconPack: undefined,
})
</script>

<template>
  <div
    class="is-icon-block is-align-items-center"
    :class="[
      !center && 'media-flex',
      center && 'media-flex-center',
      narrow && 'no-margin',
      !vertical && reversed && 'is-flex-direction-row-reverse',
      vertical && !reversed && 'is-flex-direction-column',
      vertical && reversed && 'is-flex-direction-column-reverse',
      $attrs.onClick && !overrideClickable && 'is-clickable',
    ]"
  >
    <slot name="icon">
      <VIconBox
        v-if="icon"
        :icon="loading ? LoadingSpinner : icon"
        :icon-pack="iconPack"
        :color="iconColor"
        :bordered="iconBorder"
        :rounded="iconRounded"
        :outlined="iconOutlined"
        :size="iconSize"
      />
    </slot>
    <div
      class="flex-meta"
      :class="[props.lighter && 'is-lighter', vertical && 'has-text-centered']"
    >
      <slot name="title">
        <span class="block-title">{{ title }}</span>
        <span v-if="subtitle">{{ subtitle }}</span>
        <span v-if="infratitle">{{ infratitle }}</span>
      </slot>
      <slot></slot>
    </div>
    <div v-if="$slots['action']" class="flex-end">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.media-flex {
  display: flex !important;

  &:last-child {
    margin-bottom: 1rem;
  }
}

.media-flex.is-flex-direction-column {
  .flex-meta {
    margin-left: 0;
  }
}

.media-flex.is-flex-direction-row-reverse {
  .flex-meta {
    margin-left: 0;
    margin-right: 12px;
    text-align: right;
  }
}
</style>
