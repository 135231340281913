<script setup lang="ts">
import { initDarkMode } from '/@src/stores/darkmode'

/**
 * Initialize the darkmode watcher
 *
 * @see /@src/stores/darkmode
 */
initDarkMode()

const route = useRoute()
</script>

<template>
  <div>
    <Suspense>
      <NavbarLayout v-if="route.name !== '/login' && route.name !== '/[...all]'">
        <RouterView v-slot="{ Component }">
          <Transition name="fade-slow" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </NavbarLayout>
      <MinimalLayout v-else>
        <RouterView v-slot="{ Component }">
          <Transition name="fade-slow" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </MinimalLayout>
    </Suspense>
  </div>
</template>
