import { createApp as createClientApp } from 'vue'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { PiniaLogger } from 'pinia-logger'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import * as Sentry from '@sentry/vue'
import { createRouter } from './router'
import TGAdminApp from './TGAdminApp.vue'
import './styles'

import { createApi } from '/@src/composable/useApi'

import { validateLocalStorage } from '/@src/state/appVersionState'
import type { AuthUser } from '/@src/types/auth'
import { connectEcho } from '/@src/composable/useWebSocket'
import { useAuthStore } from '/@src/stores/auth'
import type { VuePlugin } from '/@src/utils/app-helper'
import { PiniaLogout } from '/@src/utils/pinia/pinia-logout'

const plugins = import.meta.glob<{ default: VuePlugin }>('./plugins/*.ts', {
  eager: true,
})

export async function createApp() {
  const app = createClientApp(TGAdminApp)
  const router = createRouter()
  const api = createApi()

  const head = createHead()
  app.use(head)

  const pinia = createPinia()
  pinia.use(
    PiniaLogger({
      logErrors: true,
      disabled: import.meta.env.PROD,
      expanded: false,
      showStoreName: false,
      showDuration: false,
    }),
  )
  pinia.use(piniaPluginPersistedState)
  pinia.use(PiniaLogout)
  app.use(pinia)

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: import.meta.env.PROD,
    trackComponents: true,
    hooks: ['mount', 'update', 'destroy'],
    logErrors: true,
  })

  const authStore = useAuthStore(pinia)
  if (authStore.user) {
    const authUser: AuthUser = authStore.user
    Sentry.setUser({ username: `${authUser.firstName} ${authUser.lastName}` })

    connectEcho()
  }

  const tgAdmin = {
    app,
    api,
    router,
    head,
    pinia,
  }

  app.provide('tg-admin', tgAdmin)

  for (const path in plugins) {
    try {
      const { default: plugin } = plugins[path]
      await plugin(tgAdmin)
    } catch (error) {
      console.error(`Error while loading plugin "${path}".`)
      console.error(error)
    }
  }

  // use router after plugin registration, so we can register navigation guards
  app.use(tgAdmin.router)

  // Clear clients cache if version is updated
  validateLocalStorage()

  return tgAdmin
}
