<script lang="ts" setup generic="T extends RadioBaseOption = RadioBaseOption">
import { computed } from 'vue'
import { useField } from 'vee-validate'
import type { OptionsIconMap, RadioBaseOption } from '/@src/types/elements-ui'

interface FormRadioIconProps {
  name: string
  label: string
  options: OptionsIconMap<T>[]
  disabled?: boolean
  vertical?: boolean
  centered?: boolean
}

const props = withDefaults(defineProps<FormRadioIconProps>(), {})

const { value, errors } = useField<T | undefined>(props.name)

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'is-danger'
  } else if (value.value) {
    return 'is-success'
  }
  return undefined
})

const computedControlClass = computed(() =>
  props.vertical
    ? 'is-radio columns is-multiline pl-3'
    : 'is-radio columns is-align-items-baseline pl-3',
)
</script>

<template>
  <VField
    :color="color"
    :label="props.label"
    :message="errors.join('\n')"
    label-class="mb-4"
    :control-class="computedControlClass"
  >
    <slot name="content"></slot>
    <VRadioIcon
      v-model="value"
      :name="name"
      :options="options"
      :disabled="disabled"
      :vertical="vertical"
      :centered="centered"
    />
  </VField>
</template>

<style lang="scss" scoped>
.field {
  :deep(.has-addons) {
    justify-content: center !important;
  }
}
</style>
