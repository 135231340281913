import {
  type RepeatAction,
  type RepeatType,
  type TaskableId,
  type TaskableOptionsType,
  TaskableType,
} from '/@src/types/admin-tasks'
import { match } from 'ts-pattern'
import type { RouteLocationNormalized } from 'vue-router/auto'
import { repeatActionRegex } from '/@src/mapping/admin-tasks'

interface TaskableParam {
  taskableType: TaskableType | null
  taskableId: TaskableId | null
  type?: string | null
}

export function getTaskableRoute(task: TaskableParam): Partial<RouteLocationNormalized> {
  if (!task.taskableType || !task.taskableId) {
    return {
      name: '/[...all]',
      params: { all: 'hi' },
    }
  }

  return match<TaskableOptionsType, Partial<RouteLocationNormalized>>(task.taskableType)
    .with(TaskableType.User, () => {
      return {
        name: '/users/[user]/edit/',
        params: { user: task.taskableId!.toString() },
      }
    })
    .with(TaskableType.Match, () => {
      const params = { match: task.taskableId!.toString() }

      if (task.type === 'match-status') {
        return {
          name: '/matches/[match]/contact-moments',
          params: params,
        }
      } else {
        return {
          name: '/matches/[match]/',
          params: params,
        }
      }
    })
    .with(TaskableType.MatchRequest, () => {
      return {
        name: '/matching/[matching]/',
        params: { matching: task.taskableId!.toString() },
      }
    })
    .with(TaskableType.EventRequest, () => {
      return {
        name: '/events/[event]/',
        params: { event: task.taskableId!.toString() },
      }
    })
    .exhaustive()
}

export function getTaskableNameTitle(task: TaskableParam): string | undefined {
  if (!task.taskableType || !task.taskableId) {
    return undefined
  }

  return match(task.taskableType)
    .with(TaskableType.User, () => 'Gebruiker')
    .with(TaskableType.Match, () => 'Match van')
    .with(TaskableType.MatchRequest, () => 'Aanvraag van')
    .with(TaskableType.EventRequest, () => 'Evenement van')
    .exhaustive()
}

export function parseTaskAction(
  action: string | null,
): { repeatNumber: number; repeatType: RepeatType } | undefined {
  if (!action) {
    return undefined
  }

  const matchedString = action.match(repeatActionRegex)

  if (!matchedString) {
    return undefined
  }

  return {
    repeatNumber: Number(matchedString[1]),
    repeatType: matchedString[2] as RepeatType,
  }
}

export function transformToTaskAction(
  repeatNumber: number,
  repeatType: RepeatType,
): RepeatAction {
  return `repeat:${repeatNumber} ${repeatType}`
}
