import { isArray } from 'lodash'
import type { CamelCaseKeys } from 'camelcase-keys'
import type {
  CollectType,
  FocusMunicipalityTargetStatusOption,
  GroupTypeParam,
  ReportParams,
  ReportParamsResponse,
  SortingOptions,
  StatusGrouping,
  TimeColumn,
  TimePeriodParam,
} from '/@src/types/reporting'
import { MatchRequestStatus } from '/@src/types/matchRequests'
import { match } from 'ts-pattern'
import { getMunicipalityName } from '/@src/mapping/focus-municipalities'
import { useSignupReferencesStore } from '/@src/stores/signupReferences'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import type { SignupReferenceId } from '/@src/types/signup-references'
import type { AdminUserId } from '/@src/types/admin-users'
import { dayjsUTC } from '/@src/utils/date-formatter'

// Extracts the first element of an array of tuples, i.e. the key of the filter.
type KeyType<T extends any[]> = T extends [infer K, ...unknown[]] ? K : never

/**
 * Attempts to get a tuple from a filter array
 * @param filters
 * @param filterKey
 */
export function getFilterValue<T extends any[]>(
  filters: T[] | undefined,
  filterKey: KeyType<T>,
): T | undefined {
  if (!filters) {
    return undefined
  }

  return filters.find(([filter, _]) => filter === filterKey)
}

/**
 * Adds or removes a specific filter from the filters.
 * This filter will not have a parameter, thus it has to be a boolean filter
 *
 * @param filters
 * @param filterKey
 */
export function toggleFilterValue<T extends any[]>(
  filters: T[] | undefined,
  filterKey: KeyType<T>,
): T[] {
  if (!filters) {
    filters = []
  }

  const filterIndex = filters.findIndex(([filter, _]) => filter === filterKey)

  if (filterIndex !== -1) {
    filters.splice(filterIndex, 1)
  } else {
    filters.push([filterKey, ''] as T)
  }

  return filters
}

/**
 * Sets (and adds if needed) a filter value to the filters array.
 * If `unset` is set to true, it instead removes the filter from the array if it exists.
 * @param filters
 * @param filterKey
 * @param newValue
 * @param unset
 */
export function setFilterValue<T extends any[]>(
  filters: T[] | undefined,
  filterKey: KeyType<T>,
  newValue: unknown | unknown[],
  unset: boolean,
): T[] {
  if (!filters) {
    filters = []
  }

  if (!isArray(newValue)) {
    newValue = [newValue]
  }

  const addValue = newValue as unknown[]
  const filterIndex = filters.findIndex(([filter, _]) => filter === filterKey)

  if (filterIndex === -1) {
    filters.push([filterKey, ...addValue] as T)
  } else {
    if (unset) {
      filters.splice(filterIndex, 1)
    } else {
      filters[filterIndex] = [filterKey, ...addValue] as T
    }
  }

  return filters
}

/**
 * Force removes a filter from the filter array
 * @param filters
 * @param filterKey
 */
export function clearFilterValue<T extends any[]>(
  filters: T[] | undefined,
  filterKey: KeyType<T>,
): T[] {
  if (!filters) {
    filters = []
  }

  const filterIndex = filters.findIndex(([filter, _]) => filter === filterKey)

  if (filterIndex !== -1) {
    filters.splice(filterIndex, 1)
  }

  return filters
}

export function calculateNormalisedTarget(
  currentTarget: number,
  timePeriod: TimePeriodParam['matchRequest'],
  numberOfTargets: number,
  numberOfColumns: number,
) {
  const normalisedTargetCount =
    numberOfTargets *
    match(timePeriod)
      .with('day', () => 365)
      .with('week', () => 52)
      .with('month', () => 12)
      .with('quarter', () => 4)
      .with('year', () => 1)
      .with('allTime', () => 1)
      .exhaustive()

  if (timePeriod === 'week' && normalisedTargetCount === 52 && numberOfColumns === 53) {
    return currentTarget
  } else if (numberOfColumns > normalisedTargetCount) {
    return currentTarget
  } else {
    return (numberOfColumns / normalisedTargetCount) * currentTarget
  }
}

export function calculateMatchRequestTargetValue(
  data: CamelCaseKeys<StatusGrouping<MatchRequestStatus>> | undefined,
  key: FocusMunicipalityTargetStatusOption,
): undefined | number {
  if (!data) {
    return undefined
  }

  return match(key)
    .with(
      'match-expected',
      () =>
        // Some of these will not become matches, so only count them for 50%
        (data.new +
          data.intake +
          data.naCalled +
          data.forwarded +
          data.followUp +
          data.suggested +
          data.sampleMeal) *
          0.5 +
        data.match +
        data.estimatedMatch +
        data.matchStopped,
    )
    .with('match-actual', () => data.match + data.estimatedMatch + data.matchStopped)
    .exhaustive()
}

export function setGroupedTogetherParameter<T extends CollectType>(
  values: ReportParams<T>,
): ReportParams<T> {
  if (values.groupType === 'municipality' && !values.acceptableGroup) {
    values.groupNameFilters?.unshift('grouped_together')
    values.groupNameFilters =
      values.groupNameFilters?.filter(
        (name) => name !== 'grouped_together_municipalities',
      ) ?? null
  } else if (
    values.groupType === 'municipality' &&
    values.groupNameFilters?.includes('grouped_together')
  ) {
    values.groupNameFilters?.unshift('grouped_together_municipalities')
    values.groupNameFilters = values.groupNameFilters?.filter(
      (name) => name !== 'grouped_together',
    )
  } else if (
    values.groupType !== 'municipality' &&
    !values.groupNameFilters?.includes('grouped_together')
  ) {
    values.groupNameFilters?.unshift('grouped_together')
    values.groupNameFilters =
      values.groupNameFilters?.filter(
        (name) => name !== 'grouped_together_municipalities',
      ) ?? null
  }

  return values
}

export function getReportingRows<T extends CollectType>(
  rowKeys: string[],
  params: ReportParamsResponse<T>,
  sortOption: SortingOptions[T],
  filter: string,
  showExpectedMunicipalities: boolean,
  mostToLeastFunction: (row: string) => number,
  extraSortFunction?: (rows: string[]) => string[],
): string[] {
  let rows = rowKeys.filter((row) => row !== 'notAssigned')

  const groupType = params.groupType
  const years = params.years

  if (groupType === 'municipality' && !params.hideExtraMunicipalities) {
    const focusMunicipalitiesStore = useFocusMunicipalitiesStore()

    const extraRows = focusMunicipalitiesStore
      .getFocusMunicipalitiesWithYears(years)
      .filter((municipality) => {
        if (!showExpectedMunicipalities && municipality.isExpected) {
          return false
        }

        const checkMunicipality = getMunicipalityName(municipality)
          .replace('(', '')
          .replace(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')

        return !rows.some((row) => {
          return row.toLowerCase() === checkMunicipality.toLowerCase()
        })
      })
      .map((row) => row.name)

    rows.push(...extraRows)
  }

  rows = filterReportingRows(rows, filter, groupType)

  if (sortOption.includes('most-to-least')) {
    rows = rows
      .map((row) => {
        const count = mostToLeastFunction(row)
        return {
          row: row,
          count: count,
        }
      })
      .sort((row1, row2) => row2.count - row1.count)
      .map((row) => row.row)
  } else if (sortOption.includes('alphabetical')) {
    alphabeticalReportingSort(rows, groupType)
  } else if (extraSortFunction) {
    rows = extraSortFunction(rows)
  }

  if (sortOption.includes('reversed')) {
    rows.reverse()
  }

  if (rowKeys.includes('notAssigned')) {
    rows.push('notAssigned')
  }

  return rows
}

function filterReportingRows<T extends CollectType>(
  rows: string[],
  filter: string,
  groupType: GroupTypeParam[T],
): string[] {
  if (filter) {
    const adminUserStore = useAdminUsersStore()
    const signupReferenceStore = useSignupReferencesStore()

    if (groupType === 'matchmaker') {
      rows = rows.filter((row) => {
        const matchmaker = adminUserStore.getMatchmaker(parseInt(row))
        return matchmaker.firstname.toLowerCase().includes(filter.toLowerCase())
      })
    } else if (groupType === 'developer') {
      rows = rows.filter((row) => {
        const developer = adminUserStore.getDeveloper(parseInt(row) as AdminUserId)
        return developer?.firstname.toLowerCase().includes(filter.toLowerCase())
      })
    } else if (groupType === 'signup_reference_id') {
      rows = rows.filter((row) => {
        const signupReference = signupReferenceStore.getSignupReference(
          Number(row) as SignupReferenceId,
        )
        return signupReference?.toLowerCase().includes(filter.toLowerCase())
      })
    } else {
      rows = rows.filter((row) => row.toLowerCase().includes(filter.toLowerCase()))
    }
  }

  return rows
}

function alphabeticalReportingSort<T extends CollectType>(
  rows: string[],
  groupType: GroupTypeParam[T],
) {
  const adminUserStore = useAdminUsersStore()

  if (groupType === 'matchmaker') {
    rows.sort((row1, row2) => {
      const matchmaker1 = adminUserStore.getMatchmaker(row1).firstname
      const matchmaker2 = adminUserStore.getMatchmaker(row2).firstname

      return matchmaker1.localeCompare(matchmaker2)
    })
  } else {
    rows.sort((row1, row2) => {
      if (row1 === "'sHertogenbosch") {
        return 'Den Bosch'.localeCompare(row2)
      } else if (row2 === "'sHertogenbosch") {
        return row1.localeCompare('Den Bosch')
      } else if (row1 === "'sGravenhage") {
        return 'Den Haag'.localeCompare(row2)
      } else if (row2 === "'sGravenhage") {
        return row1.localeCompare('Den Haag')
      }

      return row1.localeCompare(row2)
    })
  }
}

export function municipalitiesAreEqualForStatistics(
  municipalityFromRoute: string,
  municipalityFromStatistics?: string,
): boolean {
  if (!municipalityFromStatistics) {
    return false
  }

  const statisticMunicipality = municipalityFromStatistics.toLowerCase()
  const routeMunicipality = municipalityFromRoute.toLowerCase()

  if (routeMunicipality.includes('-')) {
    return (
      routeMunicipality === statisticMunicipality ||
      routeMunicipality.replaceAll('-', ' ') === statisticMunicipality
    )
  } else if (routeMunicipality.includes(' ')) {
    return (
      routeMunicipality === statisticMunicipality ||
      routeMunicipality.replaceAll(' ', '-') === statisticMunicipality
    )
  } else {
    return routeMunicipality === statisticMunicipality
  }
}

/**
 * Converts a column like 2024?28 to the corresponding day/week/month/etc number, in this case: 28
 *
 * Different possibilities:
 * Day:     2024?07?11 => 192
 * Week:    2024?28    => 28
 * Month:   2024?07    => 7
 * Quarter: 2024?03    => 3
 * Year:    2024       => 1
 * All Time:           => 1
 * @param columnName
 * @param timePeriod
 */
export function getColumnNumberFromTimePeriod(
  columnName: TimeColumn,
  timePeriod: TimePeriodParam['matchRequest'],
) {
  return match(timePeriod)
    .with('day', () => {
      const [yearNumber, monthNumber, dayNumber] = columnName
        .split('?')
        .map((v) => parseInt(v))
      return dayjsUTC().year(yearNumber).month(monthNumber).date(dayNumber).dayOfYear()
    })
    .with('week', () => columnName.split('?').map((v) => parseInt(v))[1])
    .with('month', () => columnName.split('?').map((v) => parseInt(v))[1])
    .with('quarter', () => columnName.split('?').map((v) => parseInt(v))[1])
    .with('year', 'allTime', () => 1)
    .exhaustive()
}
