import { createRouter as createClientRouter, createWebHistory } from 'vue-router/auto'
import { handleHotUpdate, routes } from 'vue-router/auto-routes'
import type { MaybeArray } from '/@src/types/utils'
import type { UserRoleEnum } from '/@src/types/users'

// console.log(routes)

declare module 'vue-router' {
  interface RouteMeta {
    requiresAdmin?: boolean
    requiresMunicipalityRole?: boolean
    roles?: MaybeArray<`${UserRoleEnum}`> // Enums cannot be imported yet during the `definePage` macro
  }
}

export function createRouter() {
  const router = createClientRouter({
    history: createWebHistory(),
    routes,
  })

  if (import.meta.hot) {
    handleHotUpdate(router)
  }

  return router
}
