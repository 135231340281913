import type { OptionsMap } from '/@src/types/elements-ui'
import { PressAvailabilityEnum, type UserId } from '/@src/types/users'

export const undefinedUserId = -1 as UserId

export const pressAvailabilityOptions: OptionsMap<PressAvailabilityEnum>[] = [
  {
    name: 'Niet beschikbaar',
    id: PressAvailabilityEnum.NotAvailable,
  },
  {
    name: 'Niet geschikt',
    id: PressAvailabilityEnum.Unsuitable,
  },
  {
    name: 'Onbekend',
    id: PressAvailabilityEnum.Unknown,
  },
  {
    name: 'Geschikt',
    id: PressAvailabilityEnum.Suitable,
  },
  {
    name: 'Beschikbaar',
    id: PressAvailabilityEnum.Available,
  },
]
