<script setup lang="ts">
import { hasRole, sortAndMapAdminUsersByName } from '/@src/utils/helpers'
import { type UserId, UserRoleEnum } from '/@src/types/users'
import { match } from 'ts-pattern'
import type { ComponentClass, OptionsMap } from '/@src/types/elements-ui'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { roles } from '/@src/mapping/users'
import VMultiselect from '/@src/components/atoms/select/VMultiselect.vue'
import { useTemplateRef } from 'vue'

type AdminUserFilterEmits = {
  updated: [value: UserId | null | undefined]
}

interface AdminUserFilterProps {
  type: UserRoleEnum | 'all'
  loading?: boolean
  label?: string
  disableUnassigned?: boolean
  requireModelValue?: boolean
  clearToNull?: boolean
  fieldClass?: ComponentClass
}

const emits = defineEmits<AdminUserFilterEmits>()
const props = withDefaults(defineProps<AdminUserFilterProps>(), {
  label: undefined,
  requireModelValue: true,
})
const modelValue = defineModel<UserId | null | undefined>({
  required: false,
  set(value) {
    emits('updated', value)
    return value
  },
})

const adminUserStore = useAdminUsersStore()

const multiselect = useTemplateRef('multiselect')
const multiselectIsOpen = computed(() => multiselect.value?.multiSelectIsOpen)
defineExpose({ multiselectIsOpen })

const label = computed<string>(() => {
  if (props.label) {
    return props.label
  }

  if (props.type === 'all' || props.type === UserRoleEnum.Migrated) {
    return 'Filter op medewerker'
  }

  return `Filter op ${roles[props.type].name.toLowerCase()}`
})

const users = computed<OptionsMap<UserId>[]>(() => {
  return match(props.type)
    .with(UserRoleEnum.Matchmaker, () => {
      if (props.disableUnassigned) {
        return sortAndMapAdminUsersByName(adminUserStore.matchmakers)
      } else {
        return [
          {
            id: 0 as UserId,
            name: 'Nog niet toegewezen',
          },
          ...sortAndMapAdminUsersByName(adminUserStore.matchmakers),
        ]
      }
    })
    .with(UserRoleEnum.ExMatchmaker, () =>
      sortAndMapAdminUsersByName(adminUserStore.exMatchmakers),
    )
    .with(UserRoleEnum.ImpactAccelerator, () =>
      sortAndMapAdminUsersByName(adminUserStore.impactAccelerators),
    )
    .with('all', () =>
      sortAndMapAdminUsersByName([
        ...adminUserStore.matchmakers,
        ...adminUserStore.helpdeskers,
        ...adminUserStore.impactAccelerators,
        ...adminUserStore.misc,
      ]),
    )
    .otherwise((type) => {
      const allUsers = [
        ...adminUserStore.matchmakers,
        ...adminUserStore.helpdeskers,
        ...adminUserStore.impactAccelerators,
        ...adminUserStore.misc,
      ]
      return sortAndMapAdminUsersByName(allUsers.filter((u) => hasRole(u, type)))
    })
})

const internalLoading = computed<boolean>(() => {
  if (props.loading) {
    return props.loading
  }

  return match(props.type)
    .with(UserRoleEnum.Matchmaker, () => adminUserStore.matchmakers.length === 0)
    .with(
      UserRoleEnum.ImpactAccelerator,
      () => adminUserStore.impactAccelerators.length === 0,
    )
    .otherwise(
      () =>
        [
          ...adminUserStore.matchmakers,
          ...adminUserStore.helpdeskers,
          ...adminUserStore.impactAccelerators,
          ...adminUserStore.misc,
        ].length === 0,
    )
})
</script>

<template>
  <VField :label="label" :class="fieldClass">
    <VMultiselect
      ref="multiselect"
      v-bind="$attrs"
      v-model="modelValue"
      :options="users"
      :loading="internalLoading"
      searchable
      can-clear
      allow-empty
      :require-model-value="requireModelValue"
      :clear-to-null="clearToNull"
    />
  </VField>
</template>

<style scoped lang="scss"></style>
