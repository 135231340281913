<script setup lang="ts">
import { computed } from 'vue'
import { useField } from 'vee-validate'
import type {
  ComponentClass,
  FAIconName,
  InputAutoComplete,
  InputMode,
  InputType,
} from '/@src/types/elements-ui'

type FormInputEmits = {
  enter: []
}

interface FormInputProps {
  name: string
  type?: InputType
  label: string
  placeholder?: string
  autocomplete?: InputAutoComplete
  inputMode?: InputMode
  tabIndex?: number
  hidden?: boolean // Visually hide field (i.e. to help browsers with password storing)
  iconLeft?: FAIconName
  iconRight?: FAIconName
  fieldClass?: ComponentClass
}

defineOptions({
  inheritAttrs: false,
})

const emits = defineEmits<FormInputEmits>()
const props = withDefaults(defineProps<FormInputProps>(), {
  type: 'text',
  placeholder: '',
  autocomplete: undefined,
  inputMode: 'text',
  tabIndex: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  fieldClass: undefined,
})

const { value, handleBlur, errors } = useField<string>(props.name)

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'is-danger'
  } else if (value.value?.length > 0) {
    return 'is-success'
  }
  return undefined
})
</script>

<template>
  <VField
    :label="props.label"
    :color="color"
    :message="errors.join('\n')"
    :class="[hidden && 'is-hidden', fieldClass]"
    :icon-left="iconLeft"
    :icon-right="iconRight"
  >
    <template v-if="$slots['label']" #label>
      <slot name="label"></slot>
    </template>

    <slot />
    <VInput
      v-model="value"
      v-bind="$attrs"
      :type="props.type"
      :placeholder="props.placeholder"
      :autocomplete="props.autocomplete"
      :inputmode="props.inputMode"
      :tabindex="props.tabIndex"
      :aria-hidden="hidden"
      @blur="handleBlur"
      @keyup.enter="emits('enter')"
    >
    </VInput>
  </VField>
</template>

<style scoped lang="scss"></style>
