<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useAnalysisStore } from '/@src/stores/analysis'
import { useFlow } from '/@src/composable/useFlow'
import { type BugsAnalysis, type BugsAnalysisParams } from '/@src/types/analysis'
import { idValidation, nullableDayJSZod } from '/@src/utils/zodUtils'
import { useTypedForm } from '/@src/composable/useTypedForm'
import type { FlowEdge, FlowNode, OptionsMap } from '/@src/types/elements-ui'
import { Handle, PanelPosition, Position, VueFlow } from '@vue-flow/core'
import { Controls } from '@vue-flow/controls'
import FlowScreenshotButton from '/@src/components/atoms/flow/buttons/FlowScreenshotButton.vue'
import { Background } from '@vue-flow/background'
import { groupBy, mapValues, sum, uniqBy } from 'lodash'
import type { AdminUserId } from '/@src/types/admin-users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import {
  BugPriority,
  bugPriorityLabel,
  BugStatus,
  bugStatusLabel,
} from '/@src/types/bugReport'
import { formatPercentage } from '/@src/utils/helpers'
import { type TeamRoleEnum, type UserId } from '/@src/types/users'
import { teamOptionsMap, teamRoleMapping, userRoleMapping } from '/@src/mapping/users'
import { DeleteIcon } from '/@src/models/standardIcons'

useHead({
  title: 'Bugs Analyse | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: 'developer',
  },
})

type BaseNode =
  | 'input-parent-data'
  | 'input-parent-teams'

  // Filters
  | 'start-date'
  | 'end-date'
  | 'developers'
  | 'users'
  | 'teams'
  | 'show-percentages'
  | 'show-stacked-columns'

  // Submit
  | 'submit'

  // Stats
  | 'total-bugs'
  | 'active-bugs'
  | 'resolved-bugs'
  | 'no-bugs'

  // Statuses
  | 'status-parent'
  | 'status-active'
  | 'status-cancelled'
  | 'status-resolved'
  | 'status-wont-fix'
  | 'status-no-bug'
  | 'status-belongs-to-third-party'
  | 'status-resolved-by-third-party'
  | 'status-waiting-for-confirmation'
  | 'status-new'

  // Priorities
  | 'priority-parent'
  | 'priority-high'
  | 'priority-medium'
  | 'priority-low'
  | 'priority-unknown'

  // Graphs
  | 'graph-bugs-per-week'
  | 'graph-time-on-bugs'
  | 'graph-submitted-bugs'

  // Team
  | 'team-title'

type BaseEdge =
  | 'e-start-date'
  | 'e-end-date'
  | 'e-developers'
  | 'e-users'
  | 'e-teams'
  | 'e-show-percentages'
  | 'e-show-stacked-columns'
  | 'e-total-bugs'
  | 'e-active-bugs'
  | 'e-resolved-bugs'
  | 'e-no-bugs'
  | 'e-status-parent'
  | 'e-priority-parent'
  | 'e-graph-time-on-bugs'
  | 'e-graph-bugs-per-week'
  | 'e-graph-submitted-bugs'
  | 'e-team-title'
  | 'e-input-parent-teams'

const analysisStore = useAnalysisStore()
const adminUserStore = useAdminUsersStore()

const isLoading = ref(false)
const showPercentages = ref(false)
const showStackedColumns = ref(false)

const analysisData = ref<BugsAnalysis>({})
const selectedTeam = ref<TeamRoleEnum | 'other'>()

const { watchIsLoading, watchNodeLabel, watchNodesInParent } = useFlow<
  BaseNode,
  BaseEdge
>()

const { handleSubmit, useField } = useTypedForm<BugsAnalysisParams>({
  id: 'bugs-analysis',
  schema: {
    startDate: nullableDayJSZod(),
    endDate: nullableDayJSZod(),
    developerId: idValidation<AdminUserId>().nullable(),
    userIds: idValidation<UserId>().array(),
  },
  initialValues: analysisStore.bugsParams,
})

const { value: developerIdValue, setValue: setDeveloperIdValue } = useField('developerId')
const { value: userIdsValue, setValue: setUserIdsValue } = useField('userIds')

const flattenAnalysisData = computed(() => Object.values(analysisData.value).flat())

const totalBugs = computed(() => flattenAnalysisData.value.length)

const developers = computed(() => {
  return adminUserStore.developers.map((developer) => {
    return {
      id: developer.id,
      name: developer.firstname,
    }
  })
})

const teamOptions = computed<OptionsMap[]>(() => {
  const options: OptionsMap[] = teamOptionsMap

  return options.concat([
    {
      id: 'other',
      name: 'Overig',
    },
  ])
})

const currentTeamMembers = computed(() =>
  teams.value.flatMap((user) =>
    user.values.filter(
      (u) => u.role == selectedTeam.value && userIdsValue.value?.includes(u.name),
    ),
  ),
)

const teams = computed(() => {
  const groupedDataByRole = groupBy(flattenAnalysisData.value, (bug) => bug.role)

  const data = mapValues(groupedDataByRole, (bug, roleKey) => {
    return {
      label: teamRoleMapping[roleKey as TeamRoleEnum] ?? 'Overig',
      values: uniqBy(
        bug
          .map((b) => {
            return {
              category: teamRoleMapping[b.role as TeamRoleEnum] ?? 'Overig',
              label: adminUserStore.getAdminUser(b.userId)?.firstname ?? 'Onbekend',
              name: b.userId,
              role: b.role,
            }
          })
          // User ID 'null' was found
          .filter((b) => !!b.name),
        'name',
      ),
    }
  })

  return Object.values(data).sort((a, b) => {
    if (a.label === 'Overig') {
      return 1
    } else if (b.label === 'Overig') {
      return -1
    } else {
      return a.label.localeCompare(b.label)
    }
  })
})

const removeUser = (id: UserId) => {
  const newUserIds = userIdsValue.value.filter((userId) => userId !== id)
  setUserIdsValue(newUserIds)
}

const resetTeamUserIds = () => {
  if (!selectedTeam.value) {
    return
  }

  const teamUserIds = teams.value
    .flatMap((user) => user.values)
    .filter((u) => u.role === selectedTeam.value)
    .map((u) => u.name)

  setUserIdsValue(teamUserIds)
}

const getTeamTitle = () => {
  if (!selectedTeam.value) {
    return 'Team'
  } else if (selectedTeam.value === 'other') {
    return 'Team Overig'
  } else if (selectedTeam.value) {
    return `Team ${userRoleMapping[selectedTeam.value]}`
  }
}

const priorityCount = (priority: BugPriority) =>
  flattenAnalysisData.value.filter((bug) => bug.priority === priority).length

const statusCount = (status: BugStatus) =>
  flattenAnalysisData.value.filter((bug) => bug.status === status).length

const getData = handleSubmit(async (values) => {
  analysisStore.bugsParams = values

  isLoading.value = true
  const result = await analysisStore.getBugsAnalysis(values)
  isLoading.value = false

  if (result) {
    analysisData.value = result
  }
})

const timeOnBugsGraphOptions = computed(() => {
  return {
    series: [
      {
        name: 'Aantal uur',
        data: Object.values(analysisData.value).map((bug) =>
          sum(bug.map((b) => b.timeSpentInHours)),
        ),
      },
    ],
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: Object.keys(analysisData.value).map((weekNumber) =>
        weekNumber.replace('?', '-'),
      ),
    },
  }
})

const bugsPerWeekGraphOptions = computed(() => {
  return {
    series: [
      {
        name: 'Valide bugs',
        data: Object.values(analysisData.value).map(
          (bug) =>
            bug.filter((b) => ![BugStatus.NoBug, BugStatus.Cancelled].includes(b.status))
              .length,
        ),
      },
      {
        name: 'Niet valide bugs',
        data: Object.values(analysisData.value).map(
          (bug) =>
            bug.filter((b) => [BugStatus.NoBug, BugStatus.Cancelled].includes(b.status))
              .length,
        ),
      },
    ],
    chart: {
      type: 'bar',
      stacked: showStackedColumns.value,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          total: {
            enabled: true,
          },
        },
      },
    },
    xaxis: {
      categories: Object.keys(analysisData.value).map((weekNumber) =>
        weekNumber.replace('?', '-'),
      ),
    },
  }
})

watch(selectedTeam, resetTeamUserIds)

watch(userIdsValue, (newValue) => {
  if (!newValue) {
    setUserIdsValue([])
    selectedTeam.value = undefined
  }
})

watch(developerIdValue, (newValue) => {
  if (!newValue) {
    setDeveloperIdValue(null)
  }
})

watchIsLoading(isLoading)

watchNodeLabel('active-bugs', () => {
  const bugsCount = flattenAnalysisData.value.filter((bug) =>
    [
      BugStatus.Active,
      BugStatus.New,
      BugStatus.BelongsToThirdParty,
      BugStatus.WaitingForConfirmation,
    ].includes(bug.status),
  ).length

  return `Actieve bugs: ${showPercentages.value ? formatPercentage(bugsCount, totalBugs.value) : bugsCount}`
})

watchNodeLabel('resolved-bugs', () => {
  const bugsCount = flattenAnalysisData.value.filter((bug) =>
    [BugStatus.Resolved, BugStatus.WontFix, BugStatus.ResolvedByThirdParty].includes(
      bug.status,
    ),
  ).length

  return `Opgeloste bugs: ${showPercentages.value ? formatPercentage(bugsCount, totalBugs.value) : bugsCount}`
})

watchNodeLabel('no-bugs', () => {
  const bugsCount = flattenAnalysisData.value.filter((bug) =>
    [BugStatus.NoBug, BugStatus.Cancelled].includes(bug.status),
  ).length

  return `Geen bugs: ${showPercentages.value ? formatPercentage(bugsCount, totalBugs.value) : bugsCount}`
})

watchNodesInParent(
  () => {
    return currentTeamMembers.value.map((teamMember) => {
      return {
        key: teamMember.name.toString(),
        label: adminUserStore.getAdminUser(teamMember.name)?.firstname ?? 'Onbekend',
        data: {
          type: 'data',
          width: 165,
          data: {
            canBeDeleted: true,
            userId: teamMember.name,
            baseLabel:
              adminUserStore.getAdminUser(teamMember.name)?.firstname ?? 'Onbekend',
            amount: flattenAnalysisData.value.filter(
              (bug) => bug.userId === teamMember.name,
            ).length,
          },
        },
      }
    })
  },
  'input-parent-teams',
  {
    maxRowsOrCols: 6,
    direction: 'horizontal',
    yOffsets: 90,
    xOffsets: [200],
  },
)

const baseNodes = ref<FlowNode<BaseNode>[]>([
  // Input
  {
    id: 'input-parent-data',
    type: 'input-data',
    position: { x: -360, y: -650 },
    style: {
      backgroundColor: 'rgba(71,126,229,0.1)',
      width: '830px',
      height: '500px',
    },
  },
  {
    id: 'input-parent-teams',
    type: 'input-teams',
    position: { x: -550, y: 75 },
    style: {
      backgroundColor: 'rgba(229,71,71,0.1)',
      width: '1210px',
      height: '520px',
    },
  },

  // Filters
  {
    id: 'start-date',
    type: 'date',
    position: { x: 10, y: 10 },
    parentNode: 'input-parent-data',
    data: {
      name: 'startDate',
      label: 'Startdatum',
    },
  },
  {
    id: 'end-date',
    type: 'date',
    position: { x: 175, y: 10 },
    parentNode: 'input-parent-data',
    data: {
      name: 'endDate',
      label: 'Einddatum',
    },
  },
  {
    id: 'developers',
    type: 'developers',
    position: { x: 340, y: 10 },
    parentNode: 'input-parent-data',
  },
  {
    id: 'users',
    type: 'users',
    position: { x: 505, y: 10 },
    parentNode: 'input-parent-data',
  },
  {
    id: 'teams',
    type: 'teams',
    position: { x: 670, y: 15 },
    parentNode: 'input-parent-data',
  },
  {
    id: 'submit',
    type: 'button',
    position: { x: 350, y: 210 },
    parentNode: 'input-parent-data',
  },

  // Stats
  {
    id: 'total-bugs',
    type: 'total-bugs',
    position: { x: 350, y: 375 },
    parentNode: 'input-parent-data',
  },
  {
    id: 'active-bugs',
    type: 'output',
    position: { x: 650, y: 325 },
    parentNode: 'input-parent-data',
    targetPosition: Position.Left,
  },
  {
    id: 'resolved-bugs',
    type: 'output',
    position: { x: 650, y: 375 },
    parentNode: 'input-parent-data',
    targetPosition: Position.Left,
  },
  {
    id: 'no-bugs',
    type: 'output',
    position: { x: 650, y: 425 },
    parentNode: 'input-parent-data',
    targetPosition: Position.Left,
  },
  {
    id: 'show-percentages',
    type: 'switch-show-percentages',
    position: { x: 30, y: 345 },
    parentNode: 'input-parent-data',
  },
  {
    id: 'show-stacked-columns',
    type: 'switch-show-stacked-columns',
    position: { x: 300, y: 650 },
    parentNode: 'graph-bugs-per-week',
  },

  // Statuses
  {
    id: 'status-parent',
    type: 'input-status',
    position: { x: -350, y: -1290 },
    style: {
      backgroundColor: 'rgba(126,12,71,0.1)',
      width: '360px',
      height: '450px',
    },
  },
  {
    id: 'status-active',
    type: 'data',
    position: { x: 10, y: 10 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.Active],
      status: BugStatus.Active,
    },
  },
  {
    id: 'status-cancelled',
    type: 'data',
    position: { x: 200, y: 10 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.Cancelled],
      status: BugStatus.Cancelled,
    },
  },
  {
    id: 'status-resolved',
    type: 'data',
    position: { x: 10, y: 100 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.Resolved],
      status: BugStatus.Resolved,
    },
  },
  {
    id: 'status-wont-fix',
    type: 'data',
    position: { x: 200, y: 100 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.WontFix],
      status: BugStatus.WontFix,
    },
  },
  {
    id: 'status-no-bug',
    type: 'data',
    position: { x: 10, y: 190 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.NoBug],
      status: BugStatus.NoBug,
    },
  },
  {
    id: 'status-belongs-to-third-party',
    type: 'data',
    position: { x: 200, y: 190 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.BelongsToThirdParty],
      status: BugStatus.BelongsToThirdParty,
    },
  },
  {
    id: 'status-resolved-by-third-party',
    type: 'data',
    position: { x: 10, y: 280 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.ResolvedByThirdParty],
      status: BugStatus.ResolvedByThirdParty,
    },
  },
  {
    id: 'status-waiting-for-confirmation',
    type: 'data',
    position: { x: 200, y: 280 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.WaitingForConfirmation],
      status: BugStatus.WaitingForConfirmation,
    },
  },
  {
    id: 'status-new',
    type: 'data',
    position: { x: 10, y: 370 },
    parentNode: 'status-parent',
    data: {
      baseLabel: bugStatusLabel[BugStatus.New],
      status: BugStatus.New,
    },
  },

  // Priorities
  {
    id: 'priority-parent',
    type: 'input-priority',
    position: { x: 200, y: -1170 },
    style: {
      backgroundColor: 'rgba(126,71,229,0.1)',
      width: '170px',
      height: '330px',
    },
  },
  {
    id: 'priority-high',
    type: 'data',
    position: { x: 10, y: 10 },
    parentNode: 'priority-parent',
    data: {
      baseLabel: bugPriorityLabel[BugPriority.High],
      priority: BugPriority.High,
    },
  },
  {
    id: 'priority-medium',
    type: 'data',
    position: { x: 10, y: 90 },
    parentNode: 'priority-parent',
    data: {
      baseLabel: bugPriorityLabel[BugPriority.Medium],
      priority: BugPriority.Medium,
    },
  },
  {
    id: 'priority-low',
    type: 'data',
    position: { x: 10, y: 170 },
    parentNode: 'priority-parent',
    data: {
      baseLabel: bugPriorityLabel[BugPriority.Low],
      priority: BugPriority.Low,
    },
  },
  {
    id: 'priority-unknown',
    type: 'data',
    position: { x: 10, y: 250 },
    parentNode: 'priority-parent',
    data: {
      baseLabel: bugPriorityLabel[BugPriority.Unknown],
      priority: BugPriority.Unknown,
    },
  },

  // Graphs
  {
    id: 'graph-bugs-per-week',
    type: 'graph-bugs-per-week',
    position: { x: -1400, y: -700 },
    style: {
      width: '750px',
      height: '600px',
    },
  },
  {
    id: 'graph-time-on-bugs',
    type: 'graph-time-on-bugs',
    position: { x: 750, y: -700 },
    style: {
      width: '800px',
      height: '600px',
    },
  },
  {
    id: 'graph-submitted-bugs',
    type: 'graph-submitted-bugs',
    position: { x: -1200, y: 110 },
    style: {
      width: '550px',
      height: '450px',
    },
  },

  // Team
  {
    id: 'team-title',
    type: 'team-title',
    position: { x: 800, y: -100 },
    parentNode: 'input-parent-teams',
    targetPosition: Position.Bottom,
    style: {
      width: '300px',
    },
  },
])

const submittedBugGraphOptions = computed(() => {
  const submittedBugAmounts = currentTeamMembers.value
    .filter((user) => userIdsValue.value?.includes(user.name))
    .map((user) => flattenAnalysisData.value.filter((u) => u.userId === user.name).length)

  const teamMemberNames = currentTeamMembers.value
    .filter((user) => userIdsValue.value?.includes(user.name))
    .map((u) => adminUserStore.getAdminUser(u.name)?.firstname ?? 'Onbekend')

  return {
    series: submittedBugAmounts,
    chart: {
      width: 520,
      type: 'pie',
    },
    labels: teamMemberNames,
    dataLabels: {
      formatter: (_: string, { seriesIndex, w }: { seriesIndex: number; w: any }) =>
        w.config.series[seriesIndex],
    },
  }
})

const baseEdges = ref<FlowEdge<BaseEdge, BaseNode>[]>([
  {
    id: 'e-start-date',
    source: 'start-date',
    target: 'submit',
  },
  {
    id: 'e-end-date',
    source: 'end-date',
    target: 'submit',
  },
  {
    id: 'e-developers',
    source: 'developers',
    target: 'submit',
  },
  {
    id: 'e-users',
    source: 'users',
    target: 'submit',
  },
  {
    id: 'e-teams',
    source: 'teams',
    target: 'users',
  },
  {
    id: 'e-show-percentages',
    source: 'submit',
    target: 'show-percentages',
  },
  {
    id: 'e-show-stacked-columns',
    source: 'show-stacked-columns',
    target: 'graph-bugs-per-week',
  },
  {
    id: 'e-total-bugs',
    source: 'submit',
    target: 'total-bugs',
  },
  {
    id: 'e-active-bugs',
    source: 'total-bugs',
    target: 'active-bugs',
  },
  {
    id: 'e-resolved-bugs',
    source: 'total-bugs',
    target: 'resolved-bugs',
  },
  {
    id: 'e-no-bugs',
    source: 'total-bugs',
    target: 'no-bugs',
  },
  {
    id: 'e-status-parent',
    source: 'status-parent',
    target: 'input-parent-data',
  },
  {
    id: 'e-priority-parent',
    source: 'priority-parent',
    target: 'input-parent-data',
  },
  {
    id: 'e-graph-time-on-bugs',
    source: 'graph-time-on-bugs',
    target: 'input-parent-data',
  },
  {
    id: 'e-graph-bugs-per-week',
    source: 'graph-bugs-per-week',
    target: 'input-parent-data',
  },
  {
    id: 'e-graph-submitted-bugs',
    source: 'input-parent-teams',
    target: 'graph-submitted-bugs',
  },
  {
    id: 'e-team-title',
    source: 'team-title',
    target: 'input-parent-teams',
  },
  {
    id: 'e-input-parent-teams',
    source: 'input-parent-data',
    target: 'input-parent-teams',
  },
])

onMounted(getData)
</script>

<template>
  <div>
    <h3 class="title is-5 my-4">Bugs Analyse</h3>

    <div class="is-flex is-justify-content-center">
      <VueFlow :nodes="baseNodes" :edges="baseEdges" fit-view-on-init>
        <Background />
        <Controls :position="PanelPosition.TopRight">
          <FlowScreenshotButton title="bugs" />
        </Controls>

        <template #node-input-data>
          <Handle type="target" :position="Position.Top" />
          <Handle type="source" :position="Position.Bottom" />
        </template>

        <template #node-input-teams>
          <Handle type="target" :position="Position.Top" />
          <Handle type="source" :position="Position.Left" />
        </template>

        <template #node-input-status>
          <Handle type="target" :position="Position.Bottom" />
        </template>

        <template #node-input-priority>
          <Handle type="target" :position="Position.Bottom" />
        </template>

        <template #node-date="props">
          <Handle type="target" :position="Position.Bottom" />
          <FormDatePicker v-bind="props.data" />
        </template>

        <template #node-developers>
          <Handle type="target" :position="Position.Bottom" />
          <VField label="Developer">
            <VMultiselect v-model="developerIdValue" :options="developers" allow-empty />
          </VField>
        </template>

        <template #node-users>
          <Handle type="target" :position="Position.Right" />
          <VField label="Ingediend door">
            <VMultiselectGroup
              v-model="userIdsValue"
              :loading="isLoading"
              :options="teams"
              searchable
              multiple
              allow-empty
            />
          </VField>
          <Handle type="source" :position="Position.Bottom" />
        </template>

        <template #node-teams>
          <Handle type="target" :position="Position.Left" />
          <VField label="Teams">
            <VMultiselect
              v-model="selectedTeam"
              :loading="isLoading"
              :options="teamOptions"
              searchable
              allow-empty
            />
          </VField>
        </template>

        <template #node-button>
          <div class="is-flex is-justify-content-center is-align-items-center">
            <Handle type="target" :position="Position.Top" />
            <VButton
              color="success"
              icon-right="fa-check"
              width="full"
              :loading="isLoading"
              @click.prevent="getData"
            >
              Ophalen
            </VButton>
            <Handle type="source" :position="Position.Bottom" />
          </div>
        </template>

        <template #node-switch-show-percentages>
          <Handle type="target" :position="Position.Top" />
          <VField label="Laat percentages zien?">
            <VSwitchBlock
              v-model="showPercentages"
              name="show-percentages"
              left-label="Nee"
              right-label="Ja"
            />
          </VField>
        </template>

        <template #node-total-bugs>
          <Handle type="target" :position="Position.Top" />
          <p>Totaal aantal bugs: {{ totalBugs }}</p>
          <Handle type="source" :position="Position.Right" />
        </template>

        <template #node-switch-show-stacked-columns>
          <Handle type="target" :position="Position.Top" />
          <VField label="Kolommen op elkaar stapelen?">
            <VSwitchBlock
              v-model="showStackedColumns"
              name="show-stacked-columns"
              left-label="Nee"
              right-label="Ja"
            />
          </VField>
        </template>

        <template #node-data="props">
          <div v-if="props.data.canBeDeleted">
            <p>{{ props.data.baseLabel }}</p>
            <VIcon
              color="danger"
              size="small"
              :icon="DeleteIcon"
              class="is-absolute"
              @click="removeUser(props.data.userId)"
            />
          </div>
          <p v-else>{{ props.data.baseLabel }}</p>

          <p
            v-if="props.parent === 'status-parent'"
            class="is-size-5 has-text-weight-semibold"
          >
            {{
              showPercentages
                ? formatPercentage(
                    statusCount(props.data.status),
                    flattenAnalysisData.length,
                  )
                : statusCount(props.data.status)
            }}
          </p>

          <p
            v-else-if="props.parent === 'priority-parent'"
            class="is-size-5 has-text-weight-semibold"
          >
            {{
              showPercentages
                ? formatPercentage(
                    priorityCount(props.data.priority),
                    flattenAnalysisData.length,
                  )
                : priorityCount(props.data.priority)
            }}
          </p>

          <p v-else class="is-size-5 has-text-weight-semibold">
            {{
              showPercentages
                ? formatPercentage(props.data.amount, flattenAnalysisData.length)
                : props.data.amount
            }}
          </p>
        </template>

        <template #node-team-title>
          <Handle type="target" :position="Position.Bottom" />
          <span class="is-size-4 has-text-weight-semibold">{{ getTeamTitle() }}</span>
          <VIcon
            v-if="!!selectedTeam"
            icon="fa-arrow-rotate-back"
            class="ml-2"
            @click="resetTeamUserIds"
          />
        </template>

        <template #node-graph-bugs-per-week>
          <Handle type="target" :position="Position.Bottom" />
          <p class="title is-size-5">Bugs per week</p>
          <apexchart
            :series="bugsPerWeekGraphOptions.series"
            :options="bugsPerWeekGraphOptions"
            :type="bugsPerWeekGraphOptions.chart.type"
          />
          <Handle type="source" :position="Position.Top" />
        </template>

        <template #node-graph-time-on-bugs>
          <Handle type="target" :position="Position.Top" />
          <p class="title is-size-5">Tijdsbesteding aan bugs per week (in uren)</p>
          <apexchart
            :series="timeOnBugsGraphOptions.series"
            :options="timeOnBugsGraphOptions"
            :type="timeOnBugsGraphOptions.chart.type"
          />
        </template>

        <template #node-graph-submitted-bugs>
          <Handle type="target" :position="Position.Right" />
          <p class="title is-size-5">Aantal ingediende bugs</p>
          <apexchart
            :series="submittedBugGraphOptions.series"
            :options="submittedBugGraphOptions"
            :type="submittedBugGraphOptions.chart.type"
          />
        </template>
      </VueFlow>
    </div>
  </div>
</template>

<style scoped lang="scss">
.is-absolute {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
