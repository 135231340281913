<script setup lang="ts">
import type { BulmaColor, BulmaSize } from '/@src/types/elements-ui'
import { getPlaceholderImage } from '/@src/utils/helpers'
import { BlockedUserIcon } from '/@src/models/standardIcons'

export interface VAvatarProps {
  picture?: string | null
  pictureDark?: string
  placeholder?: string
  badge?: string
  initials?: string
  size?: BulmaSize | 'big' | 'xl'
  color?:
    | BulmaColor
    | 'h-purple'
    | 'h-orange'
    | 'h-blue'
    | 'h-green'
    | 'h-red'
    | 'h-yellow'
  dotColor?: BulmaColor
  squared?: boolean
  dot?: boolean
  forceAnimal?: boolean
  blocked?: boolean
}

const props = withDefaults(defineProps<VAvatarProps>(), {
  picture: undefined,
  pictureDark: undefined,
  placeholder: '/images/placeholders/50x50.webp',
  initials: '?',
  badge: undefined,
  size: undefined,
  color: undefined,
  dotColor: undefined,
})

const isValidPicture = computed(
  () =>
    props.picture &&
    !props.picture.includes('foodie_') &&
    !props.picture.includes('cook_'),
)

const pictureSrc = computed<string>(() => {
  if (props.forceAnimal) {
    return getPlaceholderImage()
  } else if (isValidPicture.value) {
    return props.picture!
  } else {
    return getPlaceholderImage()
  }
})
</script>

<template>
  <div
    class="v-avatar"
    :class="[
      size && `is-${props.size}`,
      dot && 'has-dot',
      dotColor && `dot-${props.dotColor}`,
      squared && dot && 'has-dot-squared',
    ]"
  >
    <slot name="avatar">
      <VIconBox
        v-if="blocked"
        :icon="BlockedUserIcon"
        rounded
        size="large"
        color="warning"
      />
      <img
        v-else-if="props.picture || props.forceAnimal"
        class="avatar"
        :class="[props.squared && 'is-squared', props.pictureDark && 'light-image']"
        :src="pictureSrc"
        alt=""
      />
      <span
        v-else
        class="avatar is-fake"
        :class="[props.squared && 'is-squared', props.color && `is-${props.color}`]"
      >
        <span>{{ props.initials }}</span>
      </span>
      <img
        v-if="props.picture && props.pictureDark"
        class="avatar dark-image"
        :class="[props.squared && 'is-squared']"
        :src="pictureSrc"
        alt=""
      />
    </slot>

    <slot name="badge">
      <img v-if="props.badge" class="badge" :src="props.badge" alt="" />
    </slot>
  </div>
</template>

<style scoped lang="scss"></style>
