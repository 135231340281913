import { assign, cloneDeep, keys, pick } from 'lodash'
import { toast } from 'vue-sonner'
import type { AbstractId } from '/@src/types/utils'

/**
 * Overwrites the keys of modelA with the values from modelB.
 * If useKeys is present, this function will only overwrite those keys instead.
 * Warning: this function replaces modelA in place and also returns the result.
 * If you want modelA to not be updated, use `updateModelWithClone` instead.
 *
 * Example:
 *
 * modelA: id, firstName, comments
 *
 * modelB: id, firstName, frequency
 *
 * updateModel(modelA, modelB)
 * -> { id: modelB, firstName: modelB, comments: modelA }
 *
 * updateModel(modelA, modelB, ['firstName']
 * -> { id: modelA, firstName: modelB, comments: modelA }
 * @param modelA
 * @param modelB
 * @param useKeys
 */
// #region updateModel
export function updateModel<T>(
  modelA: T,
  modelB: Partial<Record<keyof T, any>>,
  useKeys: (keyof T)[] | undefined = undefined,
): T {
  if (useKeys) {
    const modelBRemovedKeys = pick(modelB, ...useKeys)
    return assign(modelA, modelBRemovedKeys)
  } else {
    return assign(modelA, pick(modelB, keys(modelA)))
  }
}
// #endregion updateModel

// #region updateModelWithClone
export function updateModelWithClone<T>(
  modelA: T,
  modelB: Partial<Record<keyof T, any>>,
  useKeys: (keyof T)[] | undefined = undefined,
): T {
  return updateModel(cloneDeep(modelA), modelB, useKeys)
}
// #endregion updateModelWithClone

// #region removeItemFromList
export function removeItemFromList<T extends { id: AbstractId<string> }>(
  list: T[] | undefined,
  item: T,
) {
  if (!list) {
    return list
  }

  const eventIndex = list.findIndex((model) => model.id === item.id)

  if (eventIndex !== -1) {
    list.splice(eventIndex, 1)
  }
  return list
}
// #endregion removeItemFromList

// #region removeItemFromListWithClone
export function removeItemFromListWithClone<T extends { id: AbstractId<string> }>(
  list: T[] | undefined,
  item: T,
) {
  if (!list) {
    return list
  }
  return removeItemFromList(cloneDeep(list), item)
}
// #endregion removeItemFromListWithClone

// #region resolveWebSocketUpdate
export function resolveWebSocketUpdate(
  resolve: (arg0: boolean | PromiseLike<boolean>) => void,
  message?: string,
) {
  return () => {
    if (message) toast.success(message)
    resolve(true)
  }
}
// #endregion resolveWebSocketUpdate

// #region rejectWebSocketUpdate
export function rejectWebSocketUpdate(
  resolve: (arg0: false | PromiseLike<false>) => void,
  errorMessage: string = 'Fout bij updaten',
) {
  return () => {
    toast.error(errorMessage)
    resolve(false)
  }
}
// #endregion rejectWebSocketUpdate
