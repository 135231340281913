<script setup lang="ts">
import { computed, ref } from 'vue'
import { getPlaceholderImage } from '/@src/utils/helpers'
import type { FavoriteAnimal } from '/@src/types/admin-users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import type { RouteIndexCard } from '/@src/types/elements-ui'
import { DietWishIcon, FocusMunicipalityIcon } from '/@src/models/standardIcons'
import { isLeadMatchmaker, isManager } from '/@src/utils/entity-checks'
import { useAuthStore } from '/@src/stores/auth'
import { UserRoleEnum } from '/@src/types/users'

definePage({
  meta: {
    requiresAdmin: true,
  },
})

const adminUserStore = useAdminUsersStore()
const authStore = useAuthStore()

const adminUser = computed(() => adminUserStore.adminUser)

const examplePicture = ref(getPlaceholderImage())

const deselectFavoriteAnimal = ref<string | undefined>(
  !adminUserStore.adminUser?.favoriteAnimal ? 'none' : undefined,
)
const favoriteAnimal = ref<FavoriteAnimal | null>(
  adminUserStore.adminUser?.favoriteAnimal ?? null,
)
watch(favoriteAnimal, async (newValue) => {
  if (adminUserStore.adminUser && adminUserStore.adminUser.favoriteAnimal !== newValue) {
    await adminUserStore.updateField({
      field: 'favorite-animal',
      value: favoriteAnimal.value,
    })
    examplePicture.value = getPlaceholderImage(newValue)
  }
})
watch(deselectFavoriteAnimal, async () => {
  if (adminUserStore.adminUser && adminUserStore.adminUser.favoriteAnimal) {
    await adminUserStore.updateField({
      field: 'favorite-animal',
      value: null,
    })
  }
})
watch(
  () => adminUserStore.adminUser?.favoriteAnimal,
  (newValue) => {
    favoriteAnimal.value = newValue ?? null
    examplePicture.value = getPlaceholderImage(newValue)
  },
)

const cards: RouteIndexCard[] = [
  {
    title: 'Lokale doelen',
    value: 'local-targets',
    icon: FocusMunicipalityIcon,
    disabled: () =>
      !isManager.value &&
      !authStore.hasRole(UserRoleEnum.ImpactAccelerator) &&
      !authStore.hasRole(UserRoleEnum.Marketing),
    to: {
      name: '/settings/local-targets',
    },
  },
  {
    title: 'Landelijke doelen',
    value: 'monthly-targets',
    icon: 'fa-bullseye',
    disabled: () => !isManager.value,
    to: {
      name: '/settings/monthly-targets',
    },
  },
  {
    title: 'Gevonden via',
    value: 'signup-references',
    icon: 'fa-city',
    disabled: () =>
      !isManager.value &&
      !authStore.hasRole(UserRoleEnum.ImpactAccelerator) &&
      !authStore.hasRole(UserRoleEnum.Marketing),
    to: {
      name: '/settings/signup-references',
    },
  },
  {
    title: 'Dieetvoorkeuren',
    value: 'meal-wishes',
    icon: DietWishIcon,
    disabled: () => !isLeadMatchmaker.value,
    to: {
      name: '/settings/meal-wishes',
    },
  },
  {
    title: 'Contactmomenten',
    value: 'contact-moments',
    icon: 'fa-comments',
    disabled: () => !isLeadMatchmaker.value,
    to: {
      name: '/settings/contact-moments',
    },
  },
  {
    title: 'Tags',
    value: 'tags',
    icon: 'fa-tags',
    disabled: () =>
      !isManager.value &&
      !authStore.hasRole(UserRoleEnum.ImpactAccelerator) &&
      !authStore.hasRole(UserRoleEnum.Marketing) &&
      !authStore.hasRole(UserRoleEnum.LeadMatchmaker),
    to: {
      name: '/settings/tags',
    },
  },
  {
    title: 'Stopredenen',
    value: 'stop-reasons',
    icon: 'fa-stop-circle',
    disabled: () => !isLeadMatchmaker.value,
    to: {
      name: '/settings/stop-reasons',
    },
  },
]
</script>

<template>
  <div class="page-content-inner">
    <RouteIndexCards :cards="cards" card-key="settings" :cards-per-row="4" />

    <h3 class="title is-5 my-4">Gebruikers instellingen</h3>
    <WorkdaySettings v-if="adminUser" :admin-user="adminUser" />

    <div class="columns mt-3 is-align-content-space-evenly">
      <div class="column is-half">
        <MunicipalitySettings v-if="adminUser" :admin-user="adminUser" />
      </div>
      <div class="column is-half">
        <VCard
          title="Hier kan je je favoriete dier kiezen. De keuze zal in sommige plekken gebruikt worden om de admin wat vrolijker te maken."
        >
          <template #content>
            <VField>
              <VRadio
                v-model="favoriteAnimal"
                label="Katten"
                value="cat"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Honden"
                value="dog"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Axolotls"
                value="axolotl"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Eekhoorns"
                value="squirrel"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Eenhoorns"
                value="unicorn"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Vogelbekdieren"
                value="platypus"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Meeuwen"
                value="seagull"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="Pingu"
                value="pingu"
                name="animal-selector"
              />

              <VRadio
                v-model="favoriteAnimal"
                label="???"
                value="googly-eye"
                name="animal-selector"
              />

              <VRadio
                v-model="deselectFavoriteAnimal"
                label="Geen"
                value="none"
                name="animal-selector"
              />
            </VField>

            <VAvatar size="large" :picture="examplePicture" />
          </template>
        </VCard>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
