<script setup lang="ts">
import { useVFieldInjection } from '/@src/composable/useVFieldInjection'
import type {
  BulmaColor,
  BulmaSize,
  InputAutoComplete,
  InputMode,
  InputType,
} from '/@src/types/elements-ui'

interface InputProps {
  type?: InputType
  color?: BulmaColor
  size?: BulmaSize
  autocomplete?: InputAutoComplete
  inputMode?: InputMode
  placeholder?: string
  tabindex?: number
  rounded?: boolean
  hovered?: boolean
  focused?: boolean
  readOnly?: boolean
  static?: boolean
  fixedSize?: boolean
  step?: number
}

const props = withDefaults(defineProps<InputProps>(), {
  type: 'text',
  color: undefined,
  size: undefined,
  autocomplete: undefined,
  placeholder: '',
  tabindex: undefined,
  inputMode: 'text',
  step: undefined,
})

const modelValue = defineModel<any>({ required: true })

const input = ref<HTMLInputElement | null>(null)
const textArea = ref<HTMLTextAreaElement | null>(null)

const focusElement = () => {
  setTimeout(() => {
    if (props.type === 'textarea') {
      textArea.value?.focus()
    } else {
      input.value?.focus()
    }
  }, 50)
}
defineExpose({
  textArea,
  focusElement,
})

const $field = useVFieldInjection()

const computedSize = computed(() => `is-${props.size}`)
const computedColor = computed(() => {
  if ($field.color?.value) {
    return $field.color.value
  }

  if (props.color) {
    return `is-${props.color}`
  }
  return 'is-default'
})
</script>

<template>
  <input
    v-if="type !== 'textarea'"
    ref="input"
    v-model="modelValue"
    :type="type"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :inputmode="inputMode"
    :tabindex="tabindex"
    :step="step"
    :readonly="readOnly"
    class="input"
    :class="[
      computedColor,
      size && computedSize,
      { 'is-rounded': rounded },
      { 'is-hovered': hovered },
      { 'is-focused': focused },
      { 'is-static': static },
    ]"
  />
  <textarea
    v-else
    ref="textArea"
    v-model="modelValue"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :inputmode="inputMode"
    :tabindex="tabindex"
    :readonly="readOnly"
    class="textarea"
    :class="[
      computedColor,
      size && computedSize,
      { 'is-rounded': rounded },
      { 'is-hovered': hovered },
      { 'is-focused': focused },
      { 'is-static': static },
      { 'has-fixed-size': fixedSize },
    ]"
  />
</template>

<style scoped lang="scss"></style>
