<script setup lang="ts">
import { clone } from 'lodash'
import type { AbstractId } from '/@src/types/utils'

type CommentModalEmits = {
  update: [value: string]
  close: [isUpdating: boolean]
}

interface CommentModalProps {
  label?: string
  initialInput: string | null
  type?: 'input' | 'ckeditor'
  standardInfoId?: AbstractId<any>
  standardInfoName?: string
  addStandardInfo?: boolean
  modalId?: string
  loading?: boolean
}

const emits = defineEmits<CommentModalEmits>()
const props = withDefaults(defineProps<CommentModalProps>(), {
  label: 'Notities',
  type: 'ckeditor',
  standardInfoId: undefined,
  standardInfoName: undefined,
  addStandardInfo: true,
})

const isOpen = defineModel<boolean>('open', { required: true })

const internalComments = ref<string>('')
watch(
  () => props.initialInput,
  (value) => {
    internalComments.value = value ?? ''
  },
)

const resetChanges = () => {
  internalComments.value = props.initialInput ?? ''
}

const saveChanges = () => {
  emits('update', clone(internalComments.value))
  emits('close', true)
  internalComments.value = ''
}

const prepareString = (inputString: string) => {
  return inputString.replace('&nbsp;', ' ').replace(/\s*/g, '')
}

const closeModal = () => {
  if (
    prepareString(props.initialInput ?? '') !== prepareString(internalComments.value) &&
    internalComments.value !== ''
  ) {
    if (
      !confirm(
        'Er zijn nog wijzigingen die niet zijn opgeslagen. Wil je alsnog doorgaan?',
      )
    ) {
      return
    }
  }

  emits('close', false)
  isOpen.value = false
  internalComments.value = ''
}

onMounted(() => {
  if (isOpen.value && props.initialInput) {
    internalComments.value = props.initialInput ?? ''
  }
})
</script>

<template>
  <VModal
    v-model:open="isOpen"
    actions="center"
    :title="label"
    :modal-id
    @close="closeModal"
  >
    <template #content>
      <section class="has-text-centered">
        <VCKEditor
          v-if="type === 'ckeditor'"
          v-model="internalComments"
          :add-standard-info
          :standard-info-id="standardInfoId"
          :standard-info-name="standardInfoName"
        />
        <VInput v-else-if="type === 'input'" v-model="internalComments" />
      </section>
    </template>
    <template #actions>
      <div class="column">
        <ResetButton @reset="resetChanges" />
      </div>
      <div class="column">
        <VButton
          label="Opslaan"
          color="success"
          :loading="loading"
          @click.prevent="saveChanges"
        />
      </div>
    </template>
  </VModal>
</template>

<style scoped lang="scss"></style>
