import { match, P } from 'ts-pattern'

export class FileStorage {
  public files = ref<UploadableFile[]>([])

  private readonly fileTypes: string[]

  constructor(fileTypes?: string | string[]) {
    if (typeof fileTypes === 'string') {
      this.fileTypes = [fileTypes]
    } else {
      this.fileTypes = fileTypes ?? []
    }
  }

  public getFileTypes() {
    return this.fileTypes
  }

  get file(): UploadableFile | null {
    if (this.files.value.length > 0) {
      return this.files.value[0] as UploadableFile
    } else {
      return null
    }
  }

  public isAcceptableFileType(file: File) {
    return this.fileTypes.includes(file.type)
  }

  private filterFileType(fileType: string): boolean {
    return this.fileTypes.length === 0 || this.fileTypes.includes(fileType)
  }

  public addFiles(newFiles: FileList) {
    const fileArray = Array.from(newFiles)
    const newUploadableFiles = [...fileArray]
      .map((file) => new UploadableFile(file, false))
      .filter((file) => {
        return !this.fileExists(file.id) && this.filterFileType(file.file.type)
      })
    this.files.value = this.files.value.concat(newUploadableFiles)
  }

  public addFile(newFile: File, isUploaded: boolean, base64String?: string) {
    if (this.filterFileType(newFile.type)) {
      this.files.value = [new UploadableFile(newFile, isUploaded, base64String)]
    }
  }

  public fileExists(otherId: string) {
    return this.files.value.some(({ id }) => id === otherId)
  }

  public removeFile(file: UploadableFile) {
    const index = this.files.value.indexOf(file)

    if (index > -1) {
      this.files.value.splice(index, 1)
    }
  }

  public clearFiles() {
    this.files.value = []
  }
}

type StatusType = 'loading' | 'deleting' | true | false | null

export class UploadableFile {
  private _file: File
  private _id: string
  private _url: string
  private _status: StatusType
  private _isUploaded: boolean
  private _base64: string | undefined

  get file(): File {
    return this._file
  }
  set file(value: File) {
    this._file = value
  }

  get id(): string {
    return this._id
  }
  set id(value: string) {
    this._id = value
  }

  get url(): string {
    return this._url
  }
  set url(value: string) {
    this._url = value
  }

  get status(): StatusType {
    return this._status
  }
  set status(value: StatusType) {
    this._status = value
  }

  get isUploaded(): boolean {
    return this._isUploaded
  }

  set isUploaded(value: boolean) {
    this._isUploaded = value
  }

  get base64(): string | undefined {
    return this._base64
  }

  set base64(value: string | undefined) {
    this._base64 = value
  }

  public getExtension() {
    return this.file.type.split('/').pop()
  }

  constructor(file: File, isUploaded: boolean, base64String?: string) {
    this._file = file
    this._id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this._url = URL.createObjectURL(file)
    this._status = null
    this._isUploaded = isUploaded
    this._base64 = base64String
  }
}

/**
 * Source: https://stackoverflow.com/a/14919494
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
  const threshold = si ? 1000 : 1024

  if (Math.abs(bytes) < threshold) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= threshold
    u = u + 1
  } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

/**
 * Attempts to find the MIME-type from an ArrayBuffer
 * Source: https://stackoverflow.com/a/75439718
 * @param arrayBuffer
 */
export function getMimeType(arrayBuffer: ArrayBuffer) {
  const uint8arr = new Uint8Array(arrayBuffer)

  const len = 4
  if (uint8arr.length >= len) {
    const signatureArr = new Array(len)
    for (let i = 0; i < len; i++)
      signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16)
    const signature = signatureArr.join('').toUpperCase()

    return match(signature)
      .with('89504E47', () => 'image/png')
      .with('47494638', () => 'image/gif')
      .with('FF494433', () => 'audio/mp3')
      .with('25504446', () => 'application/pdf')
      .with('424D0000', () => 'image/bmp')
      .with('49492A00', '4D4D002A', () => 'image/tiff')
      .with('504B0304', () => 'application/zip')
      .with(P.string.startsWith('FFD8FF'), () => 'image/jpeg')
      .with('52494646', () => {
        const webpSignatureArr = Array.from(uint8arr.slice(8, 12))
          .map((byte) => byte.toString(16).padStart(2, '0'))
          .join('')
          .toUpperCase()

        if (webpSignatureArr.startsWith('57454250')) {
          return 'image/webp'
        }
        return null
      })
      .otherwise(() => null)
  }
  return null
}
