import type { UserTypeEnum } from '/@src/types/users'
import type { BulmaColor, FAIconName } from '/@src/types/elements-ui'
import type { IsoDay } from '/@src/types/utils'
import type { BasePaymentMethod } from '/@src/types/meals'

export interface PriceFromMatchObject {
  portions: number
  amountOfPeople: number
  price: number
  hasCommission: boolean
  commissionType: UserTypeEnum
  daysOfWeek: IsoDay[]
}

export interface PriceFromMealObject {
  portions: number
  amountOfPeople: number
  price: number
  hasCommission: boolean
  commissionType: UserTypeEnum
}

export interface NeedsAnyPaymentInfoCheck {
  price: number
  portions: number
  amountOfPeople: number
  hasCommission: boolean
  isPrepaid: boolean
  daysOfWeek: IsoDay[]
}

export interface RolePaymentInfoCheck {
  price: number
  portions: number
  amountOfPeople: number
  role: UserTypeEnum
  commissionType: UserTypeEnum
  noCommission: boolean
  isPrepaid: boolean
  daysOfWeek: IsoDay[]
}

export abstract class AbstractPaymentInfo {
  static readonly name: string = 'Abstract'
  static readonly icon: FAIconName = 'fa-x'
  static readonly color: BulmaColor = 'danger'

  abstract readonly showCommissionInfo: boolean

  abstract getName(commissionType: UserTypeEnum, noSuffix?: boolean): string
  abstract getIcon(): FAIconName
  abstract getColor(): BulmaColor

  abstract getBasePaymentMethod(): BasePaymentMethod

  abstract getCommissionType(commissionType: UserTypeEnum, hasCommission: boolean): string

  abstract needsAnyPaymentInfo(params: NeedsAnyPaymentInfoCheck): boolean
  abstract roleNeedsPaymentInfo(params: RolePaymentInfoCheck): boolean

  abstract getPricePerPortionFromMeal(
    role: UserTypeEnum,
    meal: PriceFromMealObject,
  ): number

  abstract getPriceFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number

  abstract getActualPriceFromMeal(role: UserTypeEnum, meal: PriceFromMealObject): number

  abstract getBalanceMutationFromMatch(
    role: UserTypeEnum,
    match: PriceFromMatchObject,
  ): number
}
