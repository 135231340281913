import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import { toast } from 'vue-sonner'
import type {
  Bug,
  BugId,
  BugParams,
  SearchBugParams,
  UpdateBugParams,
} from '/@src/types/bugReport'
import { BugStatus } from '/@src/types/bugReport'
import type { ApiResponse } from '/@src/types/utils'
import { rejectWebSocketUpdate, resolveWebSocketUpdate } from '/@src/utils/webSockets'
import { cloneDeep } from 'lodash'
import { allowReadOnly } from '/@src/utils/axios-utils'

const api = useApi()

interface State {
  searchResults: Bug[]
  searchParams: SearchBugParams
}

const defaultParams: SearchBugParams = {
  query: '',
  limit: 30,
  maxDaysOld: null,
}

export const useBugsStore = defineStore('bugs', {
  state: (): State => ({
    searchResults: [],
    searchParams: cloneDeep(defaultParams),
  }),
  logout: {
    pick: ['searchParams'],
  },
  actions: {
    resetFilter() {
      this.searchParams = cloneDeep(defaultParams)
    },

    async createBug(params: BugParams): Promise<boolean> {
      return new Promise((resolve) => {
        api
          .post('admin/bugs', params, allowReadOnly())
          .then((response) => {
            this.searchResults.push(response.data.data)
            resolve(true)
          })
          .catch(() => {
            toast.error('Fout bij aanmaken bug')
            resolve(false)
          })
      })
    },

    async updateBug(bugId: BugId, params: UpdateBugParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/bugs/${bugId}/update`, params)
          .then(resolveWebSocketUpdate(resolve, 'Bug is aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Bug aanpassen is mislukt'))
      })
    },

    async assignDeveloper(bugId: BugId, developerId: number) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/bugs/${bugId}/assign-developer`, {
            developerId: developerId,
          })
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Bug niet aan developer gekoppeld.'))
      })
    },

    async changeBugStatus(bugId: BugId, status: BugStatus) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/bugs/${bugId}/update/status`, {
            status: status,
          })
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Bug status is niet geüpdate.'))
      })
    },

    async updatePriorityFieldAutomatically(bugId: BugId) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/bugs/${bugId}/update/priority`)
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Bug prioriteit is niet geüpdate.'))
      })
    },

    async searchBug() {
      return new Promise<boolean>((resolve) => {
        api
          .post<
            ApiResponse<Bug[]>
          >('admin/bugs/search', this.searchParams, allowReadOnly())
          .then(
            (response) => {
              const json = response.data
              this.searchResults = json.data
              resolve(true)
            },
            () => {
              toast.error('Zoeken naar bugs mislukt.')
              resolve(false)
            },
          )
      })
    },

    async getActiveBugs() {
      return new Promise<Bug[] | false>((resolve) => {
        api
          .get<ApiResponse<Bug[]>>('admin/bugs/active')
          .then((response) => {
            resolve(response.data.data)
          })
          .catch(() => resolve(false))
      })
    },
  },
  getters: {},
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBugsStore as any, import.meta.hot))
}
