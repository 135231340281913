<script setup lang="ts" generic="T extends RadioBaseOption = RadioBaseOption">
import type { OptionsIconMap, RadioBaseOption } from '/@src/types/elements-ui'

interface VRadioIconProps {
  name: string
  options: OptionsIconMap<T>[]
  disabled?: boolean
  vertical?: boolean
  centered?: boolean
}

const props = defineProps<VRadioIconProps>()

const modelValue = defineModel<T>()

const filteredOptions = computed(() => props.options.filter((option) => !option.disabled))

const setValue = (id: T) => {
  if (props.disabled) {
    return
  }
  modelValue.value = id
}
</script>

<template>
  <div
    v-for="(option, index) in filteredOptions"
    :key="`${name}-radio-icon-option-${index}`"
    class="column is-align-items-center is-flex is-flex-direction-row"
    :class="[
      vertical && 'is-full',
      !disabled && 'is-clickable',
      centered && 'is-justify-content-center',
    ]"
    @click.prevent="setValue(option.id as T)"
    @keyup.prevent="setValue(option.id as T)"
  >
    <VIconBox
      :icon="option.icon"
      :icon-pack="option.iconPack"
      :color="modelValue === option.id ? 'info' : undefined"
      size="small"
      :disabled="disabled"
      :outlined="modelValue !== option.id"
    />
    <p
      class="ml-1"
      :class="[
        modelValue === option.id && 'has-text-weight-bold',
        disabled && 'is-unselectable',
      ]"
    >
      {{ option.name }}
    </p>
  </div>
</template>

<style scoped lang="scss"></style>
