import * as Sentry from '@sentry/browser'
import type { Context } from '@sentry/types'

export function sendSentryMessage(
  message: string,
  tag: string,
  data: Context | null = null,
) {
  Sentry.withScope((scope) => {
    scope.setLevel('debug')
    scope.setFingerprint([tag])
    scope.setContext(tag, data)

    Sentry.captureMessage(message)
  })
}
