import type { ArgsFunctionType } from '/@src/types/utils'

interface UseModalProps<TIn extends any[] = any[], TOut extends any[] = any[]> {
  defaultOpen?: boolean
  open?: ArgsFunctionType<TIn>
  close?: ArgsFunctionType<TOut>
  onOpen?: () => void
  onClose?: () => void
}

export function useModal<TIn extends any[] = any[], TOut extends any[] = any[]>(
  props: UseModalProps<TIn, TOut> | undefined = {},
) {
  const { defaultOpen = false, open, onOpen, close, onClose } = props

  const modalIsOpen = ref(defaultOpen)
  const openModal: ArgsFunctionType<TIn> = async (...args) => {
    if (open) {
      modalIsOpen.value = (await open(...args)) !== false
    } else {
      modalIsOpen.value = true
    }

    return modalIsOpen.value
  }

  const closeModal: ArgsFunctionType<TOut> = async (...args) => {
    if (close) {
      const result = await close(...args)
      if (!result && result != null) {
        return false
      }
    }
    modalIsOpen.value = false

    return true
  }

  if (onOpen || onClose) {
    watch(modalIsOpen, (newValue) => {
      if (newValue && onOpen) {
        onOpen()
      } else if (!newValue && onClose) {
        onClose()
      }
    })
  }

  return {
    modalIsOpen,
    openModal,
    closeModal,
  }
}
