<script setup lang="ts">
import { Ckeditor } from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import type { BulmaColor, BulmaSize } from '/@src/types/elements-ui'
import { useCommentAutomation } from '/@src/composable/useCommentAutomation'
import type { AbstractId } from '/@src/types/utils'

//FIXME: This component causes an error when it gets hot reloaded
interface VCKEditorProps {
  color?: BulmaColor
  size?: BulmaSize
  readOnly?: boolean
  disabled?: boolean
  addStandardInfo?: boolean
  standardInfoId?: AbstractId<any>
  standardInfoName?: string
}

const props = defineProps<VCKEditorProps>()

const modelValue = defineModel<string | null>({
  required: true,
  default: '',
  set: (newValue) => {
    if (newValue === null) {
      return ''
    }
    return newValue
  },
})

const { addStandardInfo: addStandardInfoInternal } = useCommentAutomation(
  modelValue,
  () => props.standardInfoId,
  () => props.standardInfoName,
)

const checkStandardInfo = () => {
  if (props.addStandardInfo) {
    addStandardInfoInternal()
  }
}

const editorConfig = {
  fontFamily: {
    options: ['"Montserrat", sans-serif', '"Roboto", sans-serif'],
  },
}
</script>

<template>
  <Ckeditor
    v-model="modelValue"
    :config="editorConfig"
    :editor="ClassicEditor"
    :disabled="readOnly || disabled"
    @focus="checkStandardInfo"
  />
</template>

<style scoped lang="scss"></style>
