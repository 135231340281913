<script setup lang="ts" generic="T extends number = number">
import { computed } from 'vue'
import type { ComponentClass, FAIconName, RatingOption } from '/@src/types/elements-ui'
import { useField } from 'vee-validate'

interface FormRatingProps {
  name: string
  label: string
  options: RatingOption<T>[]
  tabIndex?: number
  iconLeft?: FAIconName
  iconRight?: FAIconName
  fieldClass?: ComponentClass
  centered?: boolean
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<FormRatingProps>(), {
  type: 'text',
  tabIndex: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  fieldClass: undefined,
})

const { value, errors, setValue } = useField<number>(props.name)

const color = computed(() => {
  if (errors.value.length > 0) {
    return 'is-danger'
  }
  return 'is-success'
})

const onClick = (value: number) => {
  setValue(value)
}
</script>

<template>
  <VField
    :label="props.label"
    :color="color"
    :message="errors.join('\n')"
    :icon-left="iconLeft"
    :icon-right="iconRight"
    :class="fieldClass"
  >
    <div class="tabs is-rating is-toggle is-toggle-rounded">
      <ul :class="[centered && 'is-flex is-justify-content-center']">
        <li
          v-for="option in options"
          :key="`rating-option-${name}-${option.id}`"
          :class="[option.color ? `is-${option.color}` : undefined]"
          @click.prevent="() => onClick(option.id)"
          @keyup.prevent="() => onClick(option.id)"
        >
          <a :class="value === option.id && 'is-selected'">
            <span>{{ option.id }}</span>
          </a>
        </li>
      </ul>
    </div>
  </VField>
</template>

<style scoped lang="scss"></style>
