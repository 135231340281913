<script setup lang="ts">
import { formatDateAndTime } from '/@src/utils/date-formatter'
import { useDashboardStore } from '/@src/stores/dashboard'
import { useTemplateRef } from 'vue'
import type { DashboardLoadout, DashboardLoadoutId } from '/@src/types/dashboard'
import { toast } from 'vue-sonner'
import { copyStringToClipBoard } from '/@src/utils/helpers'
import { useModal } from '/@src/composable/useModal'
import { LoadingSpinner } from '/@src/models/standardIcons'
import SideCard from '/@src/components/molecules/card/SideCard.vue'
import { isLeadMatchmaker } from '/@src/utils/entity-checks'
import type { UserId } from '/@src/types/users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'

const isEditing = defineModel<boolean>('editing')

const adminUserStore = useAdminUsersStore()
const dashboardStore = useDashboardStore()

const isChangingLayouts = ref(false)

const isLoadingLayout = ref<DashboardLoadoutId>()

const loadoutSideCard = useTemplateRef<InstanceType<typeof SideCard>>('loadoutSideCard')

const selectedLoadout = ref<DashboardLoadout>()

const selectedUser = ref<UserId | null>(null)

watch(
  () => dashboardStore.doneLoading,
  (newValue) => {
    if (newValue && dashboardStore.currentLoadoutIsEmpty) {
      isEditing.value = true
    }
  },
)

watch(selectedUser, async (newValue) => {
  isChangingLayouts.value = true
  let result = false
  if (newValue) {
    result = await dashboardStore.getLoadouts(newValue)
  } else if (
    adminUserStore.adminUser &&
    dashboardStore.currentLoadout?.adminUserId !== adminUserStore.adminUser.id
  ) {
    result = await dashboardStore.getLoadouts(adminUserStore.adminUser.userId)
  }
  isChangingLayouts.value = false

  if (!result) {
    selectedUser.value = null
  }
})

const setLoadout = (loadoutId: DashboardLoadoutId | null, createStandard: boolean) => {
  if (dashboardStore.currentLoadoutIsEmpty && loadoutId) {
    const currentLoadoutIndex = dashboardStore.loadouts.findIndex(
      (l) => l.id === dashboardStore.currentLoadout?.id,
    )
    if (currentLoadoutIndex !== -1) {
      dashboardStore.loadouts.splice(currentLoadoutIndex, 1)
    }
  }

  if (loadoutId === null && dashboardStore.loadouts.length > 0) {
    isEditing.value = true
  }

  if (
    loadoutId === null &&
    dashboardStore.loadouts.some((l) => l.id === (-1 as DashboardLoadoutId))
  ) {
    return
  }

  dashboardStore.setCurrentLoadout(
    loadoutId,
    loadoutId === null && !createStandard,
    selectedUser.value,
  )
}

const deleteLoadout = async (loadoutId: DashboardLoadoutId) => {
  const loadout = dashboardStore.loadouts.find((l) => l.id === loadoutId)
  if (!loadout) {
    return
  }

  if (
    loadoutId !== (-1 as DashboardLoadoutId) &&
    !confirm(`Weet je zeker dat je layout '${loadout.loadoutName}' wilt verwijderen?`)
  ) {
    return
  }
  isLoadingLayout.value = loadoutId
  await dashboardStore.deleteLoadout(loadoutId)
  isLoadingLayout.value = undefined

  // No more loadouts left
  if (dashboardStore.loadouts.length === 0) {
    isEditing.value = true
    setLoadout(null, false)
  }
}

const toggleEditing = () => {
  if (dashboardStore.currentLoadoutIsEmpty) {
    return
  }
  isEditing.value = !isEditing.value
}

/**
 * Creates a base64 representation of the loadout and copies it to the clipboard
 */
const copyLoadoutString = async () => {
  if (!dashboardStore.currentLoadout) {
    return
  }

  const loadout = dashboardStore.currentLoadout
  const result = await copyStringToClipBoard(btoa(JSON.stringify(loadout)))
  if (result) {
    toast.success('Layout is gekopieerd')
  }
}

const {
  modalIsOpen: importModalIsOpen,
  openModal: openImportModal,
  closeModal: closeImportModal,
} = useModal()

/**
 * Imports a loadout and sets it to current
 * @param inputString
 */
const importLoadout = (inputString: string) => {
  const didSucceed = dashboardStore.importLoadout(inputString)
  if (didSucceed) {
    closeImportModal()
    loadoutSideCard.value?.closeCard()
    isEditing.value = false
  }
}

const {
  modalIsOpen: changeNameModalIsOpen,
  openModal: openChangeNameModal,
  closeModal: closeChangeNameModal,
} = useModal({
  open: (loadout: DashboardLoadout) => {
    selectedLoadout.value = loadout
  },
  onClose: () => {
    selectedLoadout.value = undefined
  },
})

const changeLoadoutName = (inputString: string) => {
  if (!selectedLoadout.value) {
    return
  }

  selectedLoadout.value.loadoutName = inputString
  closeChangeNameModal()
  if (inputString !== '(Tutorial) Mijn eerste nieuwe loadout!') {
    dashboardStore.storeLoadout(selectedLoadout.value)
  }
}

const openNameInputForTutorial = (loadout: DashboardLoadout) => {
  loadout.loadoutName = '(Tutorial) Mijn eerste nieuwe loadout!'
  openChangeNameModal(loadout)
}

const openCard = () => {
  loadoutSideCard.value?.openCard()
}

const closeCard = () => {
  loadoutSideCard.value?.closeCard()
}

defineExpose({ openCard, closeCard, closeChangeNameModal, openNameInputForTutorial })
</script>

<template>
  <SideCard
    ref="loadoutSideCard"
    id="loadout-sidecard"
    direction="left"
    :side-card-color="
      dashboardStore.loadoutsAreFromAnotherUser && dashboardStore.doneLoading
        ? 'danger'
        : undefined
    "
  >
    <template #title>
      <div class="columns is-fullwidth is-multiline is-flex is-align-items-center">
        <div class="column is-half">
          <p class="title is-5">Layouts</p>
        </div>
        <div class="column is-half is-flex is-flex-direction-row">
          <VIconBox
            icon="fa-download"
            color="info"
            size="small"
            @click="openImportModal"
          />

          <VIconBox
            icon="fa-upload"
            color="info"
            size="small"
            :disabled="dashboardStore.currentLoadoutIsEmpty"
            @click="copyLoadoutString"
          />

          <VIconBox
            :icon="isChangingLayouts ? 'fa-xmark-circle' : 'fa-x'"
            :color="isChangingLayouts ? 'warning' : 'info'"
            size="small"
            @click="isChangingLayouts = !isChangingLayouts"
          />

          <VIconBox
            id="loadout-edit-button"
            :icon="isEditing ? 'fa-pencil' : 'fa-pen-to-square'"
            :color="isEditing ? 'success' : 'info'"
            size="small"
            :disabled="dashboardStore.currentLoadoutIsEmpty"
            @click="toggleEditing"
          />
        </div>
      </div>
    </template>
    <template #content>
      <template v-if="isLeadMatchmaker">
        <AdminUserFilter
          v-model="selectedUser"
          type="all"
          label="Haal layouts van gebruiker op"
          :require-model-value="false"
          clear-to-null
        />

        <div class="is-divider" />
      </template>

      <ul>
        <li v-for="loadout in dashboardStore.loadouts" :id="`loadout-list-${loadout.id}`">
          <div class="columns">
            <div class="column is-four-fifths is-flex is-flex-direction-column">
              <span
                class="title is-size-5 is-clickable"
                @click="openChangeNameModal(loadout)"
              >
                {{ loadout.loadoutName }}
              </span>
              <span class="subtitle is-size-6">
                Laatst aangepast: {{ formatDateAndTime(loadout.updatedAt) }}
              </span>
            </div>
            <div class="column is-one-fifth">
              <VIconBox
                v-if="isChangingLayouts"
                :icon="isLoadingLayout ? LoadingSpinner : 'fa-x'"
                color="danger"
                @click="deleteLoadout(loadout.id)"
              />
              <VIconBox
                v-else
                :icon="
                  dashboardStore.currentLoadout?.id === loadout.id
                    ? 'fa-check'
                    : 'fa-right-from-bracket'
                "
                :color="
                  dashboardStore.currentLoadout?.id === loadout.id ? 'success' : 'info'
                "
                @click="setLoadout(loadout.id, false)"
              />
            </div>
          </div>
        </li>

        <li>
          <div class="is-divider"></div>
        </li>

        <li id="new-loadout-button">
          <div class="columns">
            <div class="column is-four-fifths is-flex is-align-items-center">
              <span class="title is-size-5"> Nieuwe aanmaken </span>
            </div>
            <div class="column is-one-fifth">
              <VIconBox icon="fa-plus" color="info" @click="setLoadout(null, false)" />
            </div>
          </div>
        </li>

        <li id="new-standard-loadout-button">
          <div class="columns">
            <div class="column is-four-fifths is-flex is-align-items-center">
              <span class="title is-size-5"> Standaard layout aanmaken </span>
            </div>
            <div class="column is-one-fifth">
              <VIconBox icon="fa-plus" color="info" @click="setLoadout(null, true)" />
            </div>
          </div>
        </li>
      </ul>
    </template>
  </SideCard>

  <InputModal
    v-model:open="importModalIsOpen"
    label="Loadout importeren"
    initial-input=""
    type="input"
    :add-standard-info="false"
    @update="(value: string) => importLoadout(value)"
  />

  <InputModal
    v-model:open="changeNameModalIsOpen"
    label="Naam wijzigen"
    :initial-input="selectedLoadout?.loadoutName ?? ''"
    type="input"
    :add-standard-info="false"
    modal-id="change-loadout-name-modal"
    @update="(value: string) => changeLoadoutName(value)"
  />
</template>

<style scoped lang="scss"></style>
