import { definePlugin } from '/@src/utils/app-helper'
import { getRole } from '/@src/utils/helpers'
import { useAuthStore } from '/@src/stores/auth'
import { UserRoleEnum } from '/@src/types/users'
import { userRolePermissionsMap } from '/@src/utils/entity-checks'
import type { NavigationGuardReturn, RouteLocationRaw } from 'vue-router'

/**
 * Here we are setting up two router navigation guards
 * (note that we can have multiple guards in multiple plugins)
 *
 * We can add meta to pages either by declaring them manualy in the
 * routes declaration (see /@src/router.ts)
 * or by adding a <route> tag into .vue files (see /@src/pages/sidebar/dashboards.ts)
 *
 * <route lang="yaml">
 * meta:
 *   requiresAuth: true
 * </route>
 *
 * <script setup lang="ts">
 *  // TS script
 * </script>
 *
 * <template>
 *  // HTML content
 * </template>
 */
export default definePlugin(({ router, pinia }) => {
  router.beforeEach(async (to): Promise<NavigationGuardReturn> => {
    const authStore = useAuthStore(pinia)

    if (
      to.matched.some(
        (record) => record.meta.requiresAdmin || record.meta.requiresMunicipalityRole,
      ) &&
      !authStore.isAuthenticated
    ) {
      // Do check of auth
      const result = await authStore.check()
      if (!result) {
        return {
          // Will follow the redirection set in /@src/pages/login.vue
          name: '/login',
          // save the location we were at to come back later
          query: { redirect: to.fullPath },
        }
      }
    } else if (
      !to.name.includes('external') &&
      authStore.hasRole(UserRoleEnum.External) &&
      !authStore.hasRole(UserRoleEnum.Admin)
    ) {
      return {
        name: '/external',
      }
    } else if (
      !to.meta.requiresMunicipalityRole &&
      authStore.user &&
      authStore.hasRole(UserRoleEnum.Reporting) &&
      !authStore.hasRole(UserRoleEnum.Admin)
    ) {
      const municipalityRoleParam = getRole(
        authStore.user,
        UserRoleEnum.Reporting,
      )?.param?.toLowerCase()

      if (
        'municipality' in to.params &&
        municipalityRoleParam &&
        municipalityRoleParam !== to.params.municipality
      ) {
        return {
          name: '/reporting/municipalities/[municipality]/match-requests',
          params: { municipality: municipalityRoleParam },
        }
      }
    } else if (
      to.meta.requiresAdmin &&
      authStore.user &&
      !authStore.hasRole(UserRoleEnum.Admin) &&
      !authStore.adminUser // Not logged in as someone else
    ) {
      if (authStore.hasRole(UserRoleEnum.Reporting)) {
        return {
          name: '/reporting',
        } satisfies RouteLocationRaw
      } else {
        return {
          name: '/[...all]',
          params: {
            all: 'unauthorized',
          },
        }
      }
    }

    if (to.meta.roles && authStore.user) {
      let roles = to.meta.roles
      if (!Array.isArray(roles)) {
        roles = [roles]
      }

      const isAllowed = roles.some((r) => {
        if (r in userRolePermissionsMap && userRolePermissionsMap[r]) {
          return userRolePermissionsMap[r].value
        } else {
          return authStore.hasRole(r as UserRoleEnum)
        }
      })

      if (!isAllowed) {
        return {
          name: '/[...all]',
          params: {
            all: 'unauthorized',
          },
        }
      }
    }
  })
})
