<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { BulmaColor, BulmaSize, ComponentClass } from '/@src/types/elements-ui'
import { useVFieldInjection } from '/@src/composable/useVFieldInjection'

type VSwitchBlockEmits = {
  click: []
}

interface VSwitchBlockProps {
  name: string
  rightLabel?: string
  leftLabel?: string
  color?: BulmaColor
  size?: BulmaSize
  rounded?: boolean
  outlined?: boolean
  disabled?: boolean
  noVModel?: boolean
  check?: boolean
  controlClass?: ComponentClass
}

const emits = defineEmits<VSwitchBlockEmits>()
const props = withDefaults(defineProps<VSwitchBlockProps>(), {
  rightLabel: undefined,
  leftLabel: undefined,
  rounded: true,
  color: undefined,
  size: undefined,
  callback: undefined,
  controlClass: undefined,
})

const modelValue = defineModel<boolean | undefined>()

const $field = useVFieldInjection()

const computedSize = computed(() => `is-${props.size}`)
const computedColor = computed(() => {
  if ($field.color?.value) {
    return $field.color.value
  }

  if (props.color) {
    return `is-${props.color}`
  }
  return 'is-default'
})

const toggleValue = () => {
  if (props.disabled) {
    return
  }
  modelValue.value = !modelValue.value
  emits('click')
}

const checkVModel = computed({
  get() {
    return props.check
  },
  set() {},
})
</script>

<template>
  <label
    v-if="$slots['left-text'] || leftLabel"
    class="control-label left-label"
    :class="[controlClass, size && computedSize, disabled && 'is-disabled']"
    :for="name"
    @click.prevent="toggleValue"
    @keyup.prevent="toggleValue"
  >
    <slot name="left-text">{{ leftLabel }}</slot>
  </label>
  <input
    v-if="!noVModel"
    v-model="modelValue"
    class="switch"
    :class="[
      size && computedSize,
      computedColor,
      { 'is-rounded': rounded },
      { 'is-outlined': outlined },
      { 'is-disabled': disabled },
    ]"
    :name="name"
    type="checkbox"
    :disabled="disabled"
  />
  <input
    v-else
    v-model="checkVModel"
    class="switch"
    :class="[
      size && computedSize,
      computedColor,
      { 'is-rounded': rounded },
      { 'is-outlined': outlined },
      { 'is-disabled': disabled },
    ]"
    :name="name"
    type="checkbox"
    :disabled="disabled"
  />
  <label
    v-if="rightLabel"
    class="control-label"
    :class="controlClass"
    :for="name"
    @click.prevent="toggleValue"
    @keyup.prevent="toggleValue"
  >
    <slot> {{ rightLabel }} </slot>
  </label>
</template>

<style scoped lang="scss">
.left-label:not(:empty) {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.5em;
  line-height: 1.5;
  padding-top: 0.2rem;
  cursor: pointer;
  padding-right: 0.5em;

  &.is-small {
    font-size: 0.75rem;
  }

  &.is-normal {
    font-size: 1rem;
  }

  &.is-medium {
    font-size: 1.25rem;
  }

  &.is-large {
    font-size: 1.5rem;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none !important;
    user-select: none !important;
    cursor: default !important;
  }
}
</style>
