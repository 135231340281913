<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { useAuthStore } from '/@src/stores/auth'
import { useReportingStore } from '/@src/stores/reporting'
import {
  DefaultTimePeriodParam,
  EnpsAnswer,
  EnpsCollectTypeParam,
  EnpsCountsTypeParam,
  EnpsFiltersParam,
  EnpsGroupNameFiltersParam,
  EnpsGroupTypeParam,
  type Report,
  type ReportingSortOption,
  type ReportParamOptions,
  type ReportParams,
  type StatusGrouping,
} from '/@src/types/reporting'
import { defaultReportTimeOptions, enpsPresets } from '/@src/mapping/reporting'
import { UserRoleEnum } from '/@src/types/users'
import { dayJSZod } from '/@src/utils/zodUtils'
import { z } from 'zod'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { isMatch, sum, sumBy } from 'lodash'
import type { OptionsMap } from '/@src/types/elements-ui'
import ReportingTable from '/@src/components/molecules/table/ReportingTable.vue'
import { getReportingRows, setGroupedTogetherParameter } from '/@src/utils/reporting'
import { copyStringToClipBoard, formatPercentage } from '/@src/utils/helpers'
import { toast } from 'vue-sonner'
import { useTemplateRef } from 'vue'

useHead({
  title: 'e-NPS Rapportage | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: ['lead-matchmaker', 'office-manager'],
  },
})

const authStore = useAuthStore()
const reportingStore = useReportingStore()

const router = useRouter()
const route = useRoute('/reporting/enps')

const reportingTable = useTemplateRef('reportingTable')

const isLoading = ref(false)

const reportData = ref<Report<'enps', EnpsAnswer> | null>(null)

const nameFilter = ref<string>('')

const showPromoterScore = ref(false)

const rows = computed<string[]>(() => {
  if (!reportData.value) {
    return []
  }

  const rowKeys = Object.keys(reportData.value.data)

  return getReportingRows(
    rowKeys,
    reportData.value.params,
    reportingStore.sortOptions.enps,
    nameFilter.value,
    false,
    (row: string) => {
      const enpsData = reportData.value!.data[row]
        ?.total as unknown as StatusGrouping<EnpsAnswer>

      if (!enpsData) {
        return 0
      }

      return enpsData['7'] + enpsData['8'] + enpsData['9'] + enpsData['10']
    },
  )
})

const resetFormToRole = () => {
  const foundRole = authStore.authUser.roles.find((role) =>
    Object.keys(enpsPresets).find((presetKey) => presetKey === role.role),
  )

  const newPreferences = reportingStore.resetPreferences<'enps'>(
    'enps',
    (foundRole?.role as UserRoleEnum) ?? UserRoleEnum.Administrator,
  )

  if (newPreferences) {
    resetForm({
      values: newPreferences,
    })
  }
}

const { handleSubmit, resetForm, setValues, values } = useTypedForm<ReportParams<'enps'>>(
  {
    id: 'report-enps',
    schema: {
      from: dayJSZod(),
      to: dayJSZod(),
      collectType: EnpsCollectTypeParam,
      countsType: EnpsCountsTypeParam,
      timePeriod: DefaultTimePeriodParam,
      groupType: EnpsGroupTypeParam,
      acceptableGroup: z.never().nullable(),
      targets: z.never().nullable(),
      sort: z.string().nullable(),
      filters: EnpsFiltersParam,
      groupNameFilters: EnpsGroupNameFiltersParam.array().nullable(),
      allTime: z.boolean().optional(),
      returnIds: z.boolean().optional(),
    },
    initialValues: reportingStore.preferences.enps,
  },
)

if (!reportingStore.preferences.enps) {
  resetFormToRole()
}

const setPreset = (newRole: UserRoleEnum) => {
  if (!(newRole in enpsPresets)) {
    return
  }

  setValues({
    from: values.from,
    to: values.to,
    allTime: values.allTime,
    returnIds: values.returnIds,
    ...enpsPresets[newRole],
  })
}

const selectedPreset = computed<UserRoleEnum | null>(() => {
  const foundRole = Object.keys(enpsPresets).find((role) => {
    const userRole = role as UserRoleEnum
    if (!enpsPresets[userRole]) {
      return false
    }
    return isMatch(values, enpsPresets[userRole]!)
  })

  if (foundRole) {
    return foundRole as UserRoleEnum
  }

  return null
})

const fetchReport = handleSubmit(
  async (values) => {
    isLoading.value = true

    setGroupedTogetherParameter(values)

    const result = await reportingStore.fetchReporting<'enps', EnpsAnswer>('enps', values)

    if (result) {
      reportData.value = result
      reportingTable.value?.closeSettings()
      reportingTable.value?.closePresets()

      await router.replace({ path: route.fullPath, query: { code: result.code } })
    }

    isLoading.value = false
  },
  () => reportingTable.value?.openSettings(),
)

const copyResultToClipboard = async (data: StatusGrouping<EnpsAnswer>) => {
  const total = sum(Object.values(data))
  const lowScores = data['1'] + data['2'] + data['3'] + data['4']
  const mediumScores = data['4'] + data['5'] + data['6'] + data['7']
  const highScores = data['8'] + data['9'] + data['10']

  const passingScores = data['6'] + data['7'] + data['8'] + data['9'] + data['10']

  const averageScore =
    sumBy(Object.entries(data), ([key, value]) => parseInt(key) * value) / total

  const exportString = `${formatPercentage(lowScores, total)}\n${formatPercentage(
    mediumScores,
    total,
  )}\n${formatPercentage(highScores, total)}\n${formatPercentage(
    passingScores,
    total,
  )}\n${averageScore.toFixed(1)}`

  const didCopy = await copyStringToClipBoard(exportString)
  if (didCopy) {
    toast.success('Gegevens zijn gekopieerd')
  }
}

const reportParams: ReportParamOptions<'enps'> = {
  countsType: [
    {
      id: 'enps',
      name: 'e-NPS',
    },
    {
      id: 'enpsEndOfMonth',
      name: 'e-NPS (einde van de maand vraag)',
    },
  ],
  groupType: [
    {
      id: 'team',
      name: 'Per team',
    },
  ],
  timePeriod: defaultReportTimeOptions,
}

const sortOptions: OptionsMap<ReportingSortOption>[] = [
  {
    id: 'alphabetical',
    name: 'Alfabetisch',
  },
  {
    id: 'most-to-least',
    name: 'Meeste naar minste aanvragen',
  },
  {
    id: 'most-to-least-reversed',
    name: 'Minste naar meeste aanvragen',
  },
]
</script>

<template>
  <div>
    <ReportingTable
      ref="reportingTable"
      :data="reportData"
      :rows="rows"
      :loading="isLoading"
      :param-options="reportParams"
      :selected-preset="selectedPreset"
      disable-async
      @submit="fetchReport"
      @reset="resetFormToRole"
      @preset="setPreset"
    >
      <template #admin />

      <template #legend>
        <table class="table">
          <tbody>
            <tr v-if="showPromoterScore">
              <td>Promoters (9-10)</td>
              <td>Criticasters (1-6)</td>
              <td>e-NPS score</td>
            </tr>
            <tr v-else>
              <td>Laag (1-4)</td>
              <td>Voldoende (5-7)</td>
              <td>Hoog (8-10)</td>
              <td>Gemiddelde</td>
            </tr>
          </tbody>
        </table>
      </template>

      <template #table-filters>
        <TableFilter v-model="nameFilter" column-class="is-one-fifth" />
        <div class="column is-one-fifth py-0 mb-0">
          <VMultiselect
            v-model="reportingStore.sortOptions.enps"
            :options="sortOptions"
          />
        </div>
        <div class="column is-one-fifth is-flex is-justify-content-center py-0 mb-0">
          <VField label="Laat promoter score zien?">
            <VSwitchBlock
              v-model="showPromoterScore"
              left-label="Nee"
              right-label="Ja"
              name="promotor-score-toggle"
            />
          </VField>
        </div>
      </template>

      <template #body-row-cell="{ data, showPercentages, click }">
        <EnpsCell
          :data="data"
          :show-percentages="showPercentages"
          :show-promotor-score="showPromoterScore"
          @click="click"
        />
      </template>

      <template #footer-cell="{ data, showPercentages, click }">
        <EnpsCell
          :data="data"
          :show-percentages="showPercentages"
          :show-promotor-score="showPromoterScore"
          @click="click"
        />
      </template>

      <template #cell-modal="{ data, showPercentages }">
        <EnpsModalCell
          v-if="data"
          :data="data.data"
          :show-percentages="showPercentages"
        />
      </template>
      <template #cell-modal-actions="{ data }">
        <div class="column">
          <VButton
            color="info"
            icon-right="fa-copy"
            @click="copyResultToClipboard(data.data)"
          >
            Kopiëren
          </VButton>
        </div>
      </template>
    </ReportingTable>
  </div>
</template>

<style scoped lang="scss"></style>
