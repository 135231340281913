import type { Role, UserId } from '/@src/types/users'

export enum AuthStatus {
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHORIZED_WITH_ERROR = 'UNAUTHORIZED_WITH_ERROR',
  UNAUTHORIZED_TOKEN_REFRESH = 'UNAUTHORIZED_TOKEN_REFRESH',
}

export interface LoginResponse {
  authUser: AuthUser
}

export interface Token {
  accessToken: string
  tokenType: string
  expiresIn: number
}

export interface AuthUser {
  id: UserId
  name: string
  firstName: string
  lastName: string
  email: string
  roles: Role[]
  avatar: string
  authenticated: boolean
  token: Token
}

export interface LoginParams {
  email: string
  password: string
  rememberMe: boolean
}
