<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import { useHead } from '@unhead/vue'

useHead({
  title: 'Instellingen | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
  },
})
</script>

<template>
  <div>
    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<style lang="scss" scoped></style>
