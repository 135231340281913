import { computed, type ComputedRef, type Ref, ref } from 'vue'

import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import {
  type Fn,
  useIntervalFn,
  useTimeoutFn,
  useWebNotification,
  type UseWebNotificationOptions,
} from '@vueuse/core'

import Logo from '/@src/assets/logo.svg'
import { useTasksStore } from '/@src/stores/tasks'
import { toast } from 'vue-sonner'
import { dayjsUTC } from '/@src/utils/date-formatter'

dayjs.extend(RelativeTime)

interface NotificationsInstance {
  permissionStatus: Ref<NotificationPermission>
  hasNotificationPermission: ComputedRef<boolean>
  requestPermission: () => Promise<void>
  isFetchingTasks: Ref<boolean>
  notificationsIsActive: Ref<boolean>
  toggleNotifications: () => void
}

let notificationsSingleton: NotificationsInstance

export function useNotifications() {
  if (!notificationsSingleton) {
    notificationsSingleton = createNotifications()
  }
  return notificationsSingleton
}

export function createNotifications() {
  const taskStore = useTasksStore()

  const permissionStatus = ref(Notification.permission)
  const hasNotificationPermission = computed(() => permissionStatus.value === 'granted')

  const quarterHour = 15
  const quarterHourInMilliseconds = quarterHour * 60 * 1000

  let hasEnabledNotifications = false

  let timeoutStop: Fn | undefined = undefined

  const intervalIsActive = ref(false)
  let intervalResume: Fn | undefined = undefined
  let intervalPause: Fn | undefined = undefined

  const isFetchingTasks = ref(false)

  const startNotifications = () => {
    if (hasEnabledNotifications) {
      return
    }

    const notificationBody: Partial<UseWebNotificationOptions> = {
      body: 'Klik hier om naar de admin te gaan',
      lang: 'nl',
      renotify: true,
      tag: 'to-do',
      icon: Logo,
    }

    const now = dayjsUTC()
    const nextQuarterHour = dayjsUTC()
      .minute(Math.ceil(now.minute() / quarterHour) * quarterHour)
      .second(0)
      .millisecond(0)

    const differenceInMs = nextQuarterHour.diff(now)

    if (timeoutStop) {
      return
    }

    const { stop } = useTimeoutFn(() => {
      if (intervalPause) {
        return
      }

      const { pause, resume } = useIntervalFn(
        async () => {
          isFetchingTasks.value = true
          const tasks = await taskStore.fetchTasksForNotification()
          isFetchingTasks.value = false

          if (tasks.length === 0) {
            return
          }

          if (tasks.length === 1) {
            const task = tasks[0]

            const { isSupported, show, close } = useWebNotification({
              title: `${task.title}`,
              ...notificationBody,
            })

            if (isSupported) {
              await show()
              useTimeoutFn(() => close(), 10000)
            }
          } else {
            const { isSupported, show, close } = useWebNotification({
              title: `${tasks.length} herinneringen`,
              ...notificationBody,
            })
            if (isSupported) {
              await show()
              useTimeoutFn(() => close(), 10000)
            }
          }
        },
        quarterHourInMilliseconds,
        { immediateCallback: true, immediate: true },
      )

      if (!intervalIsActive.value) {
        pause()
      }

      intervalPause = pause
      intervalResume = resume
    }, differenceInMs)

    timeoutStop = stop

    hasEnabledNotifications = true
    intervalIsActive.value = true
  }

  const requestPermission = async () => {
    const permission = await Notification.requestPermission()
    permissionStatus.value = permission

    if (permission === 'granted') {
      toast.success('Notificaties staan nu aan')
      startNotifications()
    } else {
      toast.error('Notificaties aanzetten mislukt')
    }
  }

  if (Notification.permission === 'granted') {
    startNotifications()
  }

  const unloadFunction = () => {
    if (timeoutStop) {
      timeoutStop()
    }

    if (intervalPause) {
      intervalPause()
    }

    removeEventListener('unload', unloadFunction)
  }
  addEventListener('unload', unloadFunction)

  const toggleNotifications = () => {
    if (intervalIsActive.value) {
      if (intervalPause) {
        intervalPause()
      }
      intervalIsActive.value = false
    } else {
      if (intervalResume) {
        intervalResume()
      }
      intervalIsActive.value = true
    }
  }

  return {
    permissionStatus,
    hasNotificationPermission,
    requestPermission,
    isFetchingTasks,
    notificationsIsActive: intervalIsActive,
    toggleNotifications,
  }
}
