<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

useHead({
  title: 'Betalingen | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
    roles: 'finance',
  },
})

type TabKey = 'payments' | 'chargebacks' | 'balances' | 'non-meal-corrections'

const tabs: VTabsItem<TabKey>[] = [
  {
    label: 'Betalingen',
    value: 'payments',
    to: {
      name: '/payments/',
    },
  },
  {
    label: 'Storneringen',
    value: 'chargebacks',
    to: {
      name: '/payments/chargebacks',
    },
  },
  {
    label: 'Saldi',
    value: 'balances',
    to: {
      name: '/payments/balances',
    },
  },
  {
    label: 'Niet maaltijd gerelateerde correcties',
    value: 'non-meal-corrections',
    to: {
      name: '/payments/non-meal-corrections',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" />

    <RouterView v-slot="{ Component }">
      <transition name="translate-page-x" mode="out-in">
        <component :is="Component" />
      </transition>
    </RouterView>
  </div>
</template>

<style lang="scss" scoped></style>
