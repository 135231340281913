export class LimitedArray<T> extends Array<T> {
  private maxSize: number

  constructor(maxSize: number) {
    super()

    this.maxSize = maxSize
  }

  setMaxSize(newMaxSize: number) {
    this.maxSize = newMaxSize
  }

  private limitElementsFromStart() {
    if (this.length > this.maxSize) {
      const numberOfElementsOver = this.length - this.maxSize
      this.splice(0, numberOfElementsOver)
    }
  }

  private limitElementsFromEnd() {
    if (this.length > this.maxSize) {
      const numberOfElementsOver = this.length - this.maxSize
      this.splice(this.length - numberOfElementsOver, numberOfElementsOver)
    }
  }

  push(...items: T[]): number {
    super.push(...items)
    this.limitElementsFromStart()

    return this.length
  }

  unshift(...items: T[]): number {
    super.unshift(...items)
    this.limitElementsFromEnd()

    return this.length
  }
}
