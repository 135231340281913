import { definePlugin } from '/@src/utils/app-helper'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.min.css'

export default definePlugin((thuisgekooktApp) => {
  const { app } = thuisgekooktApp

  library.add(fas)
  app.component('VueFontAwesome', FontAwesomeIcon)
  app.component('VueFontAwesomeLayers', FontAwesomeLayers)
  app.component('VueFontAwesomeLayersText', FontAwesomeLayersText)
})
