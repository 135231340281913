import {
  camelCase,
  clone,
  fromPairs,
  get,
  isArray,
  isBoolean,
  isDate,
  isEqual,
  isNaN,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
  kebabCase as lodashKebabCase,
  sample,
  sortedUniqBy,
  transform,
} from 'lodash'
import type { OptionsMap } from '/@src/types/elements-ui'
import type {
  AbstractId,
  KebabCase,
  NullableBoolean,
  SafeNullableBoolean,
} from '/@src/types/utils'
import { match, P } from 'ts-pattern'
import {
  type Gender,
  GenderEnum,
  type Role,
  type UserId,
  UserRoleEnum,
} from '/@src/types/users'
import type { AdminUser, FavoriteAnimal } from '/@src/types/admin-users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { MaybeRefOrGetter } from 'vue'
import { fakerNL as faker } from '@faker-js/faker'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function diff(object: unknown[], base: unknown[]): any {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? diff(value as unknown[], base[key] as unknown[])
          : value
    }
  })
}

export function resolve(path: string, obj: any) {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null
  }, obj)
}

export function formatCurrency(amount?: number): string {
  if (!amount || Math.abs(amount) === 0 || Math.abs(amount).toFixed(2) === '0.00') {
    return '€0,00'
  }

  const v = Math.abs(amount).toFixed(2).toString().replace('.', ',')
  return `${amount < 0 ? '-' : ''}€${v}`
}

export function formatPercentage(amount?: number, total?: number): string {
  if (!amount || !total) {
    return '0%'
  }
  return `${((amount / total) * 100).toFixed(0)}%`
}

// Source: https://stackoverflow.com/a/50096192
// Retrieves multiple keys from a nested object.
// The `as` variable can optionally list what a key should be mapped to
// TODO: rewrite this properly
export function deepPick<T>(
  obj: T,
  paths: string[],
  as: Record<string, string> = {},
  skip: string[] = [],
): Record<string, string> {
  return fromPairs(
    paths
      .map((p: string) => {
        const key =
          p in as // if the key is in `as`
            ? as[p] // use the mapping
            : p.replaceAll('.', '_') // else, use the key, replacing '.'s with '_'

        let value = get(obj, p) ?? '-'

        // If the resulting value is an object, cast it to a string
        if (isArray(value)) {
          value = value.join(', ')
        } else if (isObject(value)) {
          value = JSON.stringify(value)
        }

        return [camelCase(key), value]
      })
      .filter((p: string[]) => {
        return !skip.includes(p[0])
      }),
  )
}

// Source: https://stackoverflow.com/a/175787
export function isNumeric(str: string) {
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

type ObjectType = string | number | boolean | any[] | Date | Dayjs | null | undefined

/**
 * Returns an array of full key paths, e.g. "cook.userProfile.user.name" of a (nested) object
 * @param object
 */
export function getObjectKeys(object: ObjectType | Record<string, any>): string[] {
  const typeCheck = (object: ObjectType | Record<string, any>): object is ObjectType => {
    return (
      isNumber(object) ||
      isString(object) ||
      isBoolean(object) ||
      isDate(object) ||
      dayjs.isDayjs(object) ||
      isArray(object) ||
      isNull(object) ||
      isUndefined(object)
    )
  }

  const result: string[] = []

  if (typeCheck(object)) {
    return [object?.toString() ?? 'null']
  }

  Object.keys(object).forEach((key: string) => {
    const value = object[key]

    if (typeCheck(value)) {
      result.push(key)
    } else {
      const recursiveResult = getObjectKeys(value)

      recursiveResult.forEach((keyString) => {
        result.push(`${key}.${keyString}`)
      })
    }
  })
  return result
}

interface HasRoleParam {
  roles: Role[]
}

export function hasRole(user: HasRoleParam, roleType: UserRoleEnum): boolean {
  return user.roles.some((role) => role.role === roleType)
}

export function getRole(
  user: HasRoleParam | undefined,
  roleType: UserRoleEnum,
): Role | undefined {
  if (!user) {
    return undefined
  }
  return user.roles.find((role) => role.role === roleType)
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.substring(1)
}

/**
 * Transforms camelCase to Capitalized Case
 * @param word
 * @param joinChar
 */
export function toCapitalizedWords(
  word: string | number | undefined,
  joinChar: string = ' ',
) {
  if (!word) {
    return ''
  }
  if (typeof word === 'number') {
    word = word.toString()
  }

  const words = word.match(/[A-Za-z][a-z\u00E0-\u00FC?]*/g) || []

  return words
    .map(capitalize)
    .map((str) => str.replace('?', '.'))
    .join(joinChar)
}

export function textToKebabCase(word: string) {
  return (
    word
      // Replace spaces, underscores, or multiple hyphens with a single hyphen
      .replace(/\s+|_+|-+/g, '-')
      .toLowerCase()
  )
}

export function camelCaseToSnakeCase(word: string | number) {
  if (typeof word === 'number') {
    word = word.toString()
  }

  return word.replaceAll(/(?<!^)[A-Z]/g, '-$&').toLowerCase()
}

export function camelCaseToPascalCase(word: string | number) {
  if (typeof word === 'number') {
    word = word.toString()
  }

  return word.replaceAll(/(?<!^)[A-Z]/g, '_$&').toLowerCase()
}

export function camelCaseToTitleCase(word: string | number) {
  if (typeof word === 'number') {
    word = word.toString()
  }

  const result = word.replace(/([A-Z])/g, ' $1')
  return toSentenceCase(result)
}

export function snakeCaseToTitleCase(word: string | number) {
  if (typeof word === 'number') {
    word = word.toString()
  }

  const result = word.replaceAll('-', ' ')
  return toSentenceCase(result)
}

export function snakeCaseToCamelCase(word: string | number) {
  if (typeof word === 'number') {
    word = word.toString()
  }

  return word
    .toLowerCase()
    .replace(/([ -_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '').replace(' ', ''),
    )
}

export function toSentenceCase(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export function convertToSlug(text: string | number | undefined) {
  if (typeof text === 'number') {
    text = text.toString()
  }

  if (text) {
    return text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-')
  }
}

export function getDirectionsLink(origin: string, destination: string) {
  const directionsBase = 'https://www.google.com/maps/dir/?api=1'
  return `${directionsBase}&origin=${origin}&destination=${destination}&travelmode=bicycling`
}

export async function copyStringToClipBoard(data?: string | null) {
  if (!data) {
    return false
  }
  const result: PermissionStatus | 'firefox' = await navigator.permissions
    .query({ name: 'clipboard-write' as PermissionName })
    .catch(() => {
      return 'firefox'
    })

  if (result === 'firefox' || result?.state === 'granted' || result?.state === 'prompt') {
    await navigator.clipboard.writeText(data)
    return true
  }

  return false
}

export const sortAndMapAdminUsersByName = (users: AdminUser[]): OptionsMap<UserId>[] => {
  const sortedUsers = clone(users)
    .sort((userA, userB) => userA.firstname.localeCompare(userB.firstname))
    .map((user: AdminUser) => {
      return {
        id: user.userId,
        name: `${user.firstname} ${user.lastname}`,
      }
    })

  return sortedUniqBy(sortedUsers, 'name')
}

export const convertNullableBoolean = (
  nullableBoolean: NullableBoolean,
): SafeNullableBoolean => {
  if (nullableBoolean === null) {
    return 2
  }

  return Number(nullableBoolean) as 0 | 1
}

export const convertSafeNullableBoolean = (
  safeNullableBoolean: SafeNullableBoolean,
): NullableBoolean => {
  if (safeNullableBoolean === 2) {
    return null
  }

  return !!safeNullableBoolean
}

export const generateRandomString = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const getPlaceholderImage = (
  favoriteAnimal: FavoriteAnimal | undefined | null = undefined,
  specificId: 1 | 2 | 3 | 4 | undefined = undefined,
) => {
  // Check if an override was passed
  if (!favoriteAnimal) {
    favoriteAnimal = useAdminUsersStore().adminUser?.favoriteAnimal
  }

  // If it's still null / undefined, no override was passed and the admin user does not have one either
  if (!favoriteAnimal) {
    return '/images/placeholders/50x50.webp'
  }

  let photoId = specificId
  if (!photoId) {
    photoId = sample([1, 2, 3, 4])
  }

  if (favoriteAnimal === 'platypus') {
    return `/images/avatars/animals/platypuses/${favoriteAnimal}-${photoId}.webp`
  }

  return `/images/avatars/animals/${favoriteAnimal}s/${favoriteAnimal}-${photoId}.webp`
}

export function transformKeyToDayNumber(column: string): 1 | 2 | 3 | 4 | 5 | undefined {
  return match<string, 1 | 2 | 3 | 4 | 5 | undefined>(column)
    .with('monday', () => 1)
    .with('tuesday', () => 2)
    .with('wednesday', () => 3)
    .with('thursday', () => 4)
    .with('friday', () => 5)
    .otherwise(() => undefined)
}

export function calculateContrast(hexColor: string): 'has-text-black' | 'has-text-white' {
  const rgbParsed = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  if (!rgbParsed) {
    return 'has-text-white'
  }
  const r = parseInt(rgbParsed[1], 16)
  const g = parseInt(rgbParsed[2], 16)
  const b = parseInt(rgbParsed[3], 16)

  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness < 125 ? 'has-text-white' : 'has-text-black'
}

export function kebabCase<T extends string>(input: T): KebabCase<T> {
  return lodashKebabCase(input) as KebabCase<T>
}

export function parseId<T extends AbstractId<string>>(input: any): T {
  return Number(input) as T
}

export function getGenderName(genderRef: MaybeRefOrGetter<Gender | undefined>): string {
  const gender = toValue(genderRef)
  if (!gender) {
    return 'Onbekend'
  }
  return match(gender)
    .returnType<string>()
    .with(GenderEnum.Male, () => 'Man')
    .with(GenderEnum.Female, () => 'Vrouw')
    .with(GenderEnum.PreferNotToSay, () => 'Deel ik liever niet')
    .with(P.string, (val) => val)
    .exhaustive()
}

export function getRandomColor() {
  let color = capitalize(faker.color.human())
  if (color === 'Geel') {
    color = 'Gele'
  } else if (color.endsWith('geel')) {
    color = color.replace('geel', 'gele')
  } else if (color === 'Grijs') {
    color = 'Grijze'
  } else if (color.endsWith('grijs')) {
    color = color.replace('grijs', 'grijze')
  } else if (color === 'Rood') {
    color = 'Rode'
  } else if (color === 'Ivoor') {
    color = 'Ivoren'
  } else if (color === 'Zilver') {
    color = 'Zilveren'
  } else if (color === 'Indigo') {
    color = 'Indigo'
  } else if (color === 'Terracotta') {
    color = 'Terracotta'
  } else if (color === 'Oker') {
    color = 'Okeren'
  } else if (color === 'Fuchsia') {
    color = 'Fuchsia'
  } else if (color === 'Limoen') {
    color = 'Limoenen'
  } else if (color === 'Goud') {
    color = 'Gouden'
  } else if (color === 'Magenta') {
    color = 'Magenta'
  } else if (color === 'Turkoois') {
    color = 'Turquoise'
  } else if (color === 'Lavendel') {
    color = 'Lavendelen'
  } else if (color === 'Cyaan') {
    color = 'Cyanen'
  } else if (color.endsWith('t') && !color.endsWith('rt')) {
    color = `${color}te`
  } else if (!color.endsWith('e')) {
    color = `${color}e`
  }

  return color
}
