<script
  setup
  lang="ts"
  generic="Data extends object, Keys extends string = string, RouterValue extends Data | undefined = undefined, IdKey extends string = string"
>
import type {FAIconName, VTableColumn, VTableColumns} from '/@src/types/elements-ui'
import type {AbstractId, KeyOfType} from '/@src/types/utils'
import {isFunction} from "lodash";

interface VTableSlots {
  'toolbar-left'?: (props: {}) => any
  'toolbar-right'?: (props: {}) => any
  'item-select-popup'?: (props: { items: AbstractId<IdKey>[] }) => any
  'header-column'?: (props: { column: VTableColumn<Keys, RouterValue> }) => any
  'pre-body-cell'?: (props: {
    index: number
    column: VTableColumn<Keys, RouterValue>
  }) => any
  'body-cell'?: (props: {
    row: Data
    column: VTableColumn<Keys, RouterValue>
    index: number
  }) => any
  action?: (props: { row: Data }) => any
  'placeholder-cell'?: (props: { column: VTableColumn<Keys, RouterValue> }) => any
  'no-results'?: (props: { hasLoaded: boolean }) => any
}

interface VTableProps {
  columns: VTableColumns<Keys, RouterValue>
  data: Data[]
  idName?: KeyOfType<Data, AbstractId<string>>
  loading?: boolean
  showNoResults?: boolean | FAIconName
  virtual?: boolean
  automaticVirtual?: boolean
  itemSize?: number
  tableHeight?: string
  hideSelectItems?: boolean
  actionsName?: string
  noBorder?: boolean
  numberOfPreBodyCellRows?: number
  maxPlaceholders?: number
}

defineSlots<VTableSlots>()
const props = withDefaults(defineProps<VTableProps>(), {
  itemSize: undefined,
  tableHeight: undefined,
  idName: undefined,
  actionsName: 'Acties',
  numberOfPreBodyCellRows: 1,
  maxPlaceholders: 20
})

const selectedItems = defineModel<AbstractId<IdKey>[]>('selectedItems', {
  required: false,
  default: undefined
})

const canSelectItems = !!selectedItems.value && !!props.idName

let isUpdatingFromSelectedAllItems = false

const selectedAllItems = ref(false)

const selectItems = () => {
  if(!selectedItems.value) {
    return
  }
  const allIsSelected = selectedItems.value.length === props.data.length

  if (allIsSelected) {
    selectedItems.value = []
  } else {
    // props.idName is guaranteed to exist here, since this function is only called when canSelectItems is true.
    // canSelectItems can only be true when props.idName exists.
    selectedItems.value = props.data.map((item) => item[props.idName!] as AbstractId<IdKey>)
  }

  selectedAllItems.value = allIsSelected
}

watch(selectedItems, (newValues) => {
  if (isUpdatingFromSelectedAllItems) {
    isUpdatingFromSelectedAllItems = false
    return
  }

  isUpdatingFromSelectedAllItems = false

  selectedAllItems.value = newValues?.length === props.data.length
  // emits('update:selectedItems', newValues)
})

const hasLoaded = ref(false)
watch(
  () => props.loading,
  (newValue) => (hasLoaded.value = (hasLoaded.value || newValue) ?? false),
)

const getColumnClasses = (column: VTableColumn<Keys, RouterValue>) => {
  return [
    column.grow === true && 'is-grow',
    column.grow === 'lg' && 'is-grow-lg',
    column.grow === 'xl' && 'is-grow-xl',
    column.grow === 'half' && 'is-grow-half',
    column.shrink === true && 'is-shrink',
    column.shrink === 'sm' && 'is-shrink-sm',
    column.shrink === 'xs' && 'is-shrink-xs',
    column.shrink === 'half' && 'is-shrink-half',
    column.align === 'end' && 'cell-end',
    column.align === 'center' && 'cell-center',
  ]
}

const getHeaderClass = (column: VTableColumn<Keys, RouterValue>) => {
  const headerClass = column.headerClass

  if (!headerClass) {
    return undefined
  }

  if (isFunction(headerClass)) {
    return headerClass(column)
  } else {
    return headerClass
  }
}

const getPreRowCellClass = (column: VTableColumn<Keys, RouterValue>) => {
  const preRowClass = column.preRowClass

  if (!preRowClass) {
    return undefined
  }

  if (isFunction(preRowClass)) {
    return preRowClass(column)
  } else {
    return preRowClass
  }
}

const getCellClass = (row: Data | undefined, column: VTableColumn<Keys, RouterValue>) => {
  const cellClass = column.cellClass

  if (!cellClass) {
    return undefined
  }

  if (isFunction(cellClass) && !!row) {
    return cellClass(row as RouterValue, column)
  } else {
    return cellClass
  }
}

const placeholderColumns = computed<VTableColumns<Keys, RouterValue>>(() => props.columns.filter(column => !column.skipForPlaceholder))
</script>

<template>
  <div class="flex-table-wrapper" :class="noBorder && 'has-no-border'">
    <div
      v-if="$slots['toolbar-left'] || $slots['toolbar-right']"
      class="flex-table-toolbar"
    >
      <div class="left">
        <slot name="toolbar-left"></slot>
      </div>
      <div class="right">
        <slot name="toolbar-right"></slot>
      </div>
    </div>

    <template v-if="$slots['item-select-popup']">
      <Transition name="fade-fast" mode="in-out">
        <div
          v-if="selectedItems && selectedItems.length > 0"
          class="r-card is-raised mb-5"
        >
          <slot name="item-select-popup" :items="selectedItems" />
        </div>
      </Transition>
    </template>

    <div class="flex-table">
      <div class="flex-table-header">
        <div
          v-if="canSelectItems && !('checkbox' in columns) && !hideSelectItems"
          class="is-shrink-xs is-select-all"
        >
          <VCheckbox
            v-model="selectedAllItems"
            value="1"
            circle
            color="info"
            @click="selectItems"
          />
        </div>
        <template v-for="column in columns" :key="'col' + column.key">
          <slot name="header-column" :column="column">
            <span
              :class="[
                ...getColumnClasses(column),
                column.headerClass && getHeaderClass(column),
              ]"
            >
              {{ column.label }}
            </span>
          </slot>
        </template>
        <span
          v-if="$slots['action'] && !('actions' in columns)"
          class="header-column cell-end is-shrink-xs"
        >
          {{ actionsName }}
        </span>
      </div>

      <template v-if="!loading && data.length > 0">
        <template v-if="$slots['pre-body-cell']">
          <div
            v-for="n in numberOfPreBodyCellRows"
            :key="`pre-body-row-${n}`"
            class="flex-table-item"
          >
            <div
              v-for="column in columns"
              :key="`pre-body-cell-col${column.key}`"
              class="flex-table-cell"
              :class="[
                ...getColumnClasses(column),
                column.preRowClass && getPreRowCellClass(column),
              ]"
            >
              <slot name="pre-body-cell" :index="n" :column="column" />
            </div>
          </div>
        </template>

        <!--
        /////////////
        Virtual Table
        ////////////
        -->
        <template v-if="virtual || (automaticVirtual && data.length > 30)">
          <RecycleScroller
            v-slot="{ item: row, index }: { item: Data; index: number }"
            class="scroller"
            :items="data"
            :key-field="idName"
            :item-size="itemSize"
            :page-mode="!tableHeight"
            :style="!!tableHeight && { height: tableHeight }"
          >
            <div class="flex-table-item">
              <div
                v-if="canSelectItems && !('checkbox' in columns) && !hideSelectItems"
                class="flex-table-cell is-shrink-xs"
              >
                <VCheckbox
                  v-model="selectedItems"
                  :value="row[idName as keyof Data] as number"
                  circle
                  color="info"
                />
              </div>
              <template v-for="column in columns" :key="'row' + column.key">
                <div
                  class="flex-table-cell"
                  :class="[
                    ...getColumnClasses(column),
                    column.cellClass && getCellClass(row, column),
                    column.onClick && 'is-clickable',
                  ]"
                  :tabindex="column.onClick ? 0 : undefined"
                  @keydown.enter="
                    () => {
                      column.onClick && column.onClick(row as RouterValue, column.key)
                    }
                  "
                  @click="
                    () => {
                      column.onClick && column.onClick(row as RouterValue, column.key)
                    }
                  "
                >
                  <slot name="body-cell" :row="row" :column="column" :index="index">
                    <VTableBodyCell
                      :row="row"
                      :column="column as unknown as VTableColumn<Keys, Data>"
                    />
                  </slot>
                </div>
              </template>
              <div
                v-if="$slots['action'] && !('actions' in columns)"
                class="flex-table-cell is-clickable cell-end is-shrink-xs"
              >
                <slot name="action" :row="row"> TODO</slot>
              </div>
            </div>
          </RecycleScroller>
        </template>
        <!--
        /////////////
        Regular Table
        ////////////
        -->
        <template v-else>
          <template v-for="(row, index) in data" :key="`table-${row[idName]}`">
            <div class="flex-table-item">
              <div
                v-if="canSelectItems && !('checkbox' in columns) && !hideSelectItems"
                class="flex-table-cell is-shrink-xs"
              >
                <VCheckbox
                  v-model="selectedItems"
                  :value="row[idName as keyof Data] as number"
                  circle
                  color="info"
                />
              </div>
              <template v-for="column in columns" :key="'row' + column.key">
                <div
                  class="flex-table-cell"
                  :class="[
                    ...getColumnClasses(column),
                    column.cellClass && getCellClass(row, column),
                    column.onClick && 'is-clickable',
                  ]"
                  :tabindex="column.onClick ? 0 : undefined"
                  @keydown="
                    () => {
                      column.onClick && column.onClick(row as RouterValue, column.key)
                    }
                  "
                  @click="
                    () => {
                      column.onClick && column.onClick(row as RouterValue, column.key)
                    }
                  "
                >
                  <slot name="body-cell" :row="row" :column="column" :index="index">
                    <VTableBodyCell
                      :row="row"
                      :column="column as unknown as VTableColumn<Keys, Data>"
                    />
                  </slot>
                </div>
              </template>
              <div
                v-if="$slots['action'] && !('actions' in columns)"
                class="flex-table-cell is-clickable cell-end is-shrink-xs"
              >
                <slot name="action" :row="row"> TODO</slot>
              </div>
            </div>
          </template>
        </template>
      </template>

      <!-- Placeholders -->
      <template v-else-if="loading">
        <div
          v-for="row in [...Array(maxPlaceholders).keys()]"
          :key="`placeholder-${row}`"
          class="flex-table-item"
        >
          <div
            v-if="canSelectItems && !('checkbox' in columns)"
            class="flex-table-cell is-relative is-shrink-xs"
          >
            <VPlaceloadAvatar size="small" rounded="sm" />
          </div>
          <template v-for="column in placeholderColumns" :key="'row' + column.key">
            <div
              class="flex-table-cell is-relative"
              :class="[
                ...getColumnClasses(column),
                column.cellClass && getCellClass(undefined, column),
              ]"
            >
              <slot name="placeholder-cell" :column="column">
                <VPlaceload class="mx-1" />
              </slot>
            </div>
          </template>
          <div
            v-if="$slots['action'] && !('actions' in columns)"
            class="flex-table-cell is-relative is-clickable cell-end is-shrink-xs"
          >
            <VPlaceloadAvatar />
          </div>
        </div>
      </template>
      <template v-else-if="showNoResults && !loading && data.length === 0">
        <section class="section has-text-centered">
          <VIcon
            :icon="typeof showNoResults === 'string' ? showNoResults : hasLoaded ? 'fa-book-open' : 'fa-search'"
            size="large"
            font-awesome-icon-size="2x"
          />
          <slot name="no-results" :has-loaded="hasLoaded"></slot>
        </section>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
