<script setup lang="ts">
import { onboarding } from '/@src/utils/onboarding'
import { useDarkMode } from '/@src/stores/darkmode'
import type { VIconColor } from '/@src/types/elements-ui'

const darkMode = useDarkMode()

const hasTutorial = onboarding.hasTutorial
const iconColor = computed<VIconColor>(() => {
  if (darkMode.isDark) {
    return hasTutorial.value ? 'white' : 'grey-dark'
  } else {
    return hasTutorial.value ? 'black' : 'grey'
  }
})
</script>
<template>
  <VTooltip class="mr-2">
    <template #popper>
      <template v-if="hasTutorial">
        Klik om de tutorial van deze pagina te beginnen
      </template>
      <template v-else> Deze pagina heeft nog geen tutorial </template>
    </template>
    <VIcon
      :class="hasTutorial && 'is-onboarding-icon'"
      icon="fa-question"
      size="medium"
      font-awesome-icon-size="lg"
      :color="iconColor"
      :disabled="!hasTutorial"
      @click="onboarding.start"
    />
  </VTooltip>
</template>

<style scoped lang="scss">
.is-onboarding-icon {
  border-radius: var(--radius-rounded);
  border: black solid 2px;
}

.is-dark {
  .is-onboarding-icon {
    border-color: var(--white);
  }
}
</style>
