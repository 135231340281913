<script setup lang="ts" generic="Keys extends string = string">
import type { DropdownElement } from '/@src/types/elements-ui'
import { toValue } from 'vue'
import type { RouteLocationNormalized } from 'vue-router/auto'
import type { RouteLocationRaw } from 'vue-router'

type VDropdownExtendedEmits = {
  open: []
  close: []
}

interface VDropdownExtendedProps<Keys extends string> {
  open?: boolean
  elements?: DropdownElement<Keys>[]
  placement?: string
  skidding?: number
}

const emits = defineEmits<VDropdownExtendedEmits>()
const props = defineProps<VDropdownExtendedProps<Keys>>()

const internalIsOpen = ref<boolean>(props.open)
watch(
  () => props.open,
  () => {
    internalIsOpen.value = props.open
  },
)

const visibleElements = computed(() =>
  props.elements?.filter((element) => !toValue(element.disabled)),
)

const internalOnClick = (onClick?: () => void) => {
  if (onClick) {
    closeDropdown()
    onClick()
  }
}

const openDropdown = () => {
  internalIsOpen.value = true
  emits('open')
}
const closeDropdown = () => {
  internalIsOpen.value = false
  emits('close')
}

defineExpose({
  openDropdown,
  closeDropdown,
})
</script>

<template>
  <VDropdown
    class="is-clickable"
    :distance="12"
    :shown="internalIsOpen"
    :placement="placement"
    :skidding="skidding"
    @apply-show="openDropdown"
    @apply-hide="closeDropdown"
  >
    <slot></slot>
    <template #popper>
      <slot name="pre-popper"></slot>

      <template
        v-for="(element, index) in visibleElements"
        :key="`element-${element.key}`"
      >
        <RouterLink
          v-if="toValue(element.to)"
          :to="toValue(element.to) as RouteLocationRaw"
          class="dropdown-item is-media"
          @click="internalOnClick(element?.onClick)"
          @keyup="internalOnClick(element?.onClick)"
        >
          <VIcon :icon="element.icon" />
          <div class="meta">
            <span>{{ toValue(element.title) }}</span>
            <span v-if="toValue(element.subtitle)">{{ toValue(element.subtitle) }}</span>
          </div>
        </RouterLink>
        <div
          v-else
          class="dropdown-item is-media"
          @click.prevent="internalOnClick(element?.onClick)"
          @keyup.prevent="internalOnClick(element?.onClick)"
        >
          <VIcon :icon="element.icon" />
          <div class="meta">
            <span>{{ toValue(element.title) }}</span>
            <span v-if="toValue(element.subtitle)">{{ toValue(element.subtitle) }}</span>
          </div>
        </div>
        <hr
          v-if="element.divider && index !== (visibleElements?.length ?? 0) - 1"
          class="dropdown-divider"
        />
      </template>

      <slot name="popper"></slot>
    </template>
  </VDropdown>
</template>

<style scoped lang="scss"></style>
