<script setup lang="ts">
import type { BulmaSize, VTagColor } from '/@src/types/elements-ui'
import { computed } from 'vue'

interface VTagProps {
  label?: string | number
  color?: VTagColor
  size?: BulmaSize
  light?: boolean
  rounded?: boolean
  isDelete?: boolean
  stringColor?: string
}

const props = withDefaults(defineProps<VTagProps>(), {
  label: undefined,
  color: undefined,
  size: undefined,
  light: false,
  rounded: false,
  isDelete: false,
  stringColor: undefined,
})

const computedColor = computed(() => {
  if (props.color) {
    return `is-${props.color}`
  }
  return 'is-default'
})

const computedSize = computed(() => {
  if (props.size) {
    return `is-${props.size}`
  }
  return 'is-normal'
})
</script>

<template>
  <span
    :style="{ backgroundColor: stringColor }"
    class="tag"
    :class="[
      computedColor,
      computedSize,
      { 'is-light': light },
      { 'is-rounded': rounded },
      { 'is-delete': isDelete },
    ]"
  >
    <slot>{{ label }}</slot>
  </span>
</template>

<style scoped lang="scss"></style>
