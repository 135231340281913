import type { OptionsIconMap, RatingOption } from '/@src/types/elements-ui'
import { FailIcon, SuccessIcon } from '/@src/models/standardIcons'

export const booleanRadioIconOptions: OptionsIconMap<0 | 1>[] = [
  {
    id: 0,
    name: 'Nee',
    icon: FailIcon,
  },
  {
    id: 1,
    name: 'Ja',
    icon: SuccessIcon,
  },
]

export const standardRatings: RatingOption[] = [
  {
    id: 1,
    color: 'danger',
  },
  {
    id: 2,
    color: 'danger',
  },
  {
    id: 3,
    color: 'danger',
  },
  {
    id: 4,
    color: 'danger',
  },
  {
    id: 5,
    color: 'warning',
  },
  {
    id: 6,
    color: 'warning',
  },
  {
    id: 7,
    color: 'warning',
  },
  {
    id: 8,
    color: 'success',
  },
  {
    id: 9,
    color: 'success',
  },
  {
    id: 10,
    color: 'success',
  },
]
