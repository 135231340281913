<script setup lang="ts">
import { computed, provide } from 'vue'
import { $FieldKey } from '/@src/types/inject-types'
import type { ComponentClass, FAIconName, FAIconPack } from '/@src/types/elements-ui'

type SpinnerSize = 'small' | 'normal' | 'large'
type FieldColor = 'is-success' | 'is-danger'

type VFieldEmits = {
  iconLeftClick: [value: Event]
  iconRightClick: [value: Event]
}

interface FieldProps {
  label?: string
  labelClass?: ComponentClass
  message?: string
  loading?: boolean
  spinnerSize?: SpinnerSize
  color?: FieldColor
  iconLeft?: FAIconName
  iconPackLeft?: FAIconPack
  iconRight?: FAIconName
  iconPackRight?: FAIconPack
  addons?: boolean
  passwordReveal?: boolean
  controlClass?: ComponentClass
}

const emits = defineEmits<VFieldEmits>()
const props = defineProps<FieldProps>()

const color = computed(() => props.color)
const computedSpinnerSize = computed(() => `is-${props.spinnerSize}`)

provide($FieldKey, {
  color: color,
})
</script>

<template>
  <div class="field" :class="addons && 'has-addons'">
    <label v-if="label" class="label ml-1" :class="labelClass">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div
      class="control"
      :class="[
        { 'has-icons-left': iconLeft || $slots['icon-left'] },
        { 'has-icons-right': iconRight || $slots['icon-right'] },
        { 'is-loading': loading },
        spinnerSize && computedSpinnerSize,
        controlClass,
      ]"
    >
      <slot />

      <slot name="icon-left">
        <VIcon
          v-if="iconLeft"
          :icon="iconLeft"
          :icon-pack="iconPackLeft"
          class="is-small is-left is-clickable"
          @click.prevent="emits('iconLeftClick', $event)"
        >
        </VIcon>
      </slot>

      <slot name="icon-right">
        <VIcon
          v-if="iconRight"
          :icon="iconRight"
          :icon-pack="iconPackRight"
          class="is-small is-right is-clickable"
          @click.prevent="emits('iconRightClick', $event)"
        >
        </VIcon>
      </slot>
    </div>
    <div v-if="$slots['addon']" class="control">
      <slot name="addon" />
    </div>
    <span v-if="message" class="help" :class="color">
      {{ message }}
    </span>
  </div>
</template>

<style scoped lang="scss"></style>
