<script
  lang="ts"
  setup
  generic="TLeft extends string = string, TRight extends string = string"
>
import { computed, type StyleValue } from 'vue'
import type {
  BulmaSize,
  ComponentClass,
  FAIconName,
  ModalSidebarItem,
} from '/@src/types/elements-ui'
import type { MaybeArray } from 'rollup'
import type { LengthUnit } from '/@src/types/utils'

type VModalAction = 'center' | 'right'

type VModalEmits = {
  open: []
  close: []
}

interface VModalProps<TLeft extends string = string, TRight extends string = string> {
  actions?: VModalAction
  rounded?: boolean
  title?: string
  size?: BulmaSize | 'big' | 'xlarge'

  modalId?: string

  cardClasses?: MaybeArray<string>
  cardStyle?: StyleValue

  leftSidebarTitle?: string
  leftSidebarItems?: ModalSidebarItem<TLeft>[]
  leftSidebarWidth?: LengthUnit

  rightSidebarTitle?: string
  rightSidebarItems?: ModalSidebarItem<TRight>[]
  rightSidebarWidth?: LengthUnit

  cancelLabel?: string
  headerText?: string
  headerIcon?: FAIconName
  headerClass?: ComponentClass
  headerImageSource?: string
}

const emits = defineEmits<VModalEmits>()
const props = withDefaults(defineProps<VModalProps<TLeft, TRight>>(), {
  actions: undefined,
  title: 'Modal',
  size: 'normal',

  modalId: '',

  cardClasses: undefined,
  cardStyle: undefined,

  leftSidebarTitle: undefined,
  leftSidebarItems: undefined,
  leftSidebarWidth: undefined,

  rightSidebarTitle: undefined,
  rightSidebarItems: undefined,
  rightSidebarWidth: undefined,

  cancelLabel: undefined,
  headerText: undefined,
  headerIcon: 'fa-arrow-right',
  headerClass: undefined,
  headerImageSource: undefined,
})

const isOpen = defineModel<boolean>('open', {
  required: false,
  default: false,
})

const cancelLabel = computed(() => props.cancelLabel || 'Annuleren')

const leftSidebarStyle = computed(() =>
  !!props.leftSidebarWidth ? { width: props.leftSidebarWidth } : undefined,
)
const rightSidebarStyle = computed(() =>
  !!props.rightSidebarWidth ? { width: props.rightSidebarWidth } : undefined,
)

const openModal = () => {
  isOpen.value = true
  emits('open')
}

const closeModal = () => {
  isOpen.value = false
  emits('close')
}
</script>

<template>
  <slot :open="openModal" name="open-modal">
    <div
      v-if="props.headerText"
      :class="headerClass"
      class="icon-text has-modal has-modal-link"
      @click="openModal"
      @keyup="openModal"
    >
      <span class="has-text-weight-semibold">{{ props.headerText }} </span>
      <span class="icon">
        <VIcon :icon="props.headerIcon" class="fas" />
      </span>
    </div>
  </slot>
  <Teleport to="#modal-root">
    <div
      :class="[isOpen && 'is-active', `is-${size}`]"
      class="modal v-modal"
      v-bind="$attrs"
    >
      <div
        class="modal-background v-modal-close"
        @click.prevent="closeModal"
        @keyup.prevent="closeModal"
      />
      <div :id="modalId" class="modal-content">
        <div
          v-if="
            (leftSidebarItems && leftSidebarItems.length > 0) || $slots['left-sidebar']
          "
          class="modal-sidebar modal-sidebar-left"
          :style="leftSidebarStyle"
        >
          <p v-if="leftSidebarTitle" class="title is-size-6 has-text-centered">
            {{ leftSidebarTitle }}
          </p>
          <div class="modal-sidebar-content columns is-multiline">
            <slot name="left-sidebar">
              <div
                v-for="item in leftSidebarItems"
                :key="`sidebar-modal-item-left-${item.key}`"
                class="column is-full is-flex modal-sidebar-item is-align-items-center"
                :class="
                  item.title ? 'is-justify-content-end' : 'is-justify-content-center'
                "
              >
                <div v-if="item.title" class="modal-sidebar-item-title mx-2">
                  <span>
                    {{ item.title }}
                  </span>
                  <span v-if="item.subtitle">
                    {{ item.subtitle }}
                  </span>
                </div>

                <VIconBox
                  :icon="item.icon"
                  :color="item.color"
                  class="modal-sidebar-item-icon"
                  :class="[item.onClick && 'is-clickable', item.isActive && 'is-active']"
                  override-clickable
                  @click.prevent="
                    () => (item.onClick ? item.onClick(item.key as TLeft) : undefined)
                  "
                  @keyup.prevent="
                    () => (item.onClick ? item.onClick(item.key as TLeft) : undefined)
                  "
                />
              </div>
            </slot>
          </div>
        </div>
        <div
          v-if="
            (rightSidebarItems && rightSidebarItems.length > 0) || $slots['right-sidebar']
          "
          class="modal-sidebar modal-sidebar-right"
          :style="rightSidebarStyle"
        >
          <p v-if="rightSidebarTitle" class="title is-size-6 has-text-centered">
            {{ rightSidebarTitle }}
          </p>
          <div class="modal-sidebar-content columns is-multiline">
            <slot name="right-sidebar">
              <div
                v-for="item in rightSidebarItems"
                :key="`sidebar-modal-item-right-${item.key}`"
                class="column is-full is-flex modal-sidebar-item is-align-items-center"
                :class="
                  item.title ? 'is-justify-content-end' : 'is-justify-content-center'
                "
              >
                <VIconBox
                  :icon="item.icon"
                  :color="item.color"
                  class="modal-sidebar-item-icon"
                  :class="[item.onClick && 'is-clickable', item.isActive && 'is-active']"
                  override-clickable
                  @click.prevent="
                    () => (item.onClick ? item.onClick(item.key as TRight) : undefined)
                  "
                  @keyup.prevent="
                    () => (item.onClick ? item.onClick(item.key as TRight) : undefined)
                  "
                />

                <div v-if="item.title" class="modal-sidebar-item-title mx-2">
                  <span>
                    {{ item.title }}
                  </span>
                  <span v-if="item.subtitle">
                    {{ item.subtitle }}
                  </span>
                </div>
              </div>
            </slot>
          </div>
        </div>
        <div class="modal-card" :class="cardClasses" :style="cardStyle">
          <header class="modal-card-head">
            <h3>
              {{ title }}
            </h3>

            <slot :close="closeModal" name="cross">
              <button
                aria-label="close"
                class="v-modal-close delete is-large"
                tabindex="0"
                @click="closeModal"
                @keydown.space.prevent="closeModal"
              ></button>
            </slot>
          </header>
          <section class="modal-card-body">
            <div class="container">
              <slot name="content" />
            </div>
          </section>
          <div
            :class="[
              props.actions === 'center' && 'is-centered',
              props.actions === 'right' && 'is-end',
            ]"
            class="modal-card-foot is-flex is-justify-content-center"
          >
            <div class="columns is-action-columns">
              <div v-if="$slots['pre-cancel-action']" class="column">
                <slot name="pre-cancel-action" />
              </div>
              <div class="column">
                <slot :close="closeModal" name="cancel">
                  <VButton
                    :rounded="rounded"
                    class="is-max-width v-button"
                    color="default"
                    @click="closeModal"
                    @keydown.space.prevent="closeModal"
                  >
                    {{ cancelLabel }}
                  </VButton>
                </slot>
              </div>
              <div v-if="$slots['action']" class="column">
                <slot name="action" :close="closeModal" />
              </div>
              <slot name="actions" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped></style>
