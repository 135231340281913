<script setup lang="ts">
import { ref } from 'vue'
import type { BulmaColor, FAIconName, VButtonProps } from '/@src/types/elements-ui'
import { omit } from 'lodash'

type VAnimatedButtonEmits = {
  click: []
}

interface VAnimatedButtonProps extends VButtonProps {
  icon: FAIconName
  time: number
  secondaryColor: BulmaColor
  minWidth: `${number}%`
  notAutomatic?: boolean
}

const emits = defineEmits<VAnimatedButtonEmits>()
const props = defineProps<VAnimatedButtonProps>()

const cssSeconds = computed(() => `${props.time}s`)
const color = computed(() => `var(--${props.secondaryColor})`)

const animationTriggered = ref<boolean>(false)
const revertingAnimation = ref<boolean>(false)

watch(
  () => props.loading,
  (newValue) => {
    if (!props.notAutomatic) {
      return
    }

    if (newValue) {
      animationTriggered.value = true
    } else {
      revertingAnimation.value = true
      setTimeout(() => {
        revertingAnimation.value = false
        animationTriggered.value = false
      }, props.time * 1000)
    }
  },
)

const triggerAnimation = () => {
  emits('click')

  if (props.notAutomatic) {
    return
  }

  animationTriggered.value = true
  setTimeout(() => {
    revertingAnimation.value = true
    setTimeout(() => {
      revertingAnimation.value = false
      animationTriggered.value = false
    }, props.time * 1000)
  }, props.time * 1500)
}
</script>

<template>
  <VButton
    :icon-left="icon"
    class="is-animated-button"
    :class="[animationTriggered && 'is-animating', revertingAnimation && 'is-reverting']"
    v-bind="omit(props, 'iconLeft')"
    @click.prevent="triggerAnimation"
  >
    <slot />
  </VButton>
</template>

<style scoped lang="scss">
@keyframes button-smaller {
  0% {
    width: 100%;
  }

  100% {
    width: v-bind(minWidth);
    background-color: v-bind(color);
  }
}

@keyframes button-larger {
  0% {
    width: v-bind(minWidth);
    background-color: v-bind(color);
  }

  100% {
    width: 100%;
  }
}

@keyframes text-appear {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes text-disappear {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes image-appear {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes image-disappear {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.is-animated-button {
  &.is-animating {
    animation: button-smaller v-bind(cssSeconds) ease-in-out;
    animation-fill-mode: forwards;

    :deep(span) {
      animation: text-disappear v-bind(cssSeconds) ease-in-out;
      animation-fill-mode: forwards;
    }

    :deep(.icon) {
      animation: image-appear v-bind(cssSeconds) ease-in-out;
      animation-fill-mode: forwards;
    }

    &.is-reverting {
      animation: button-larger v-bind(cssSeconds) ease-in-out;

      :deep(span) {
        animation: text-appear v-bind(cssSeconds) ease-in-out;
      }

      :deep(.icon) {
        animation: image-disappear v-bind(cssSeconds) ease-in-out;
      }
    }
  }

  :deep(.icon) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto !important;
    filter: alpha(opacity=0);
    opacity: 0;
  }
}
</style>
