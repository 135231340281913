import type { FocusMunicipalityTargetStatus } from '/@src/types/focus-municipalities'
import {
  FocusMunicipalityTargetStatusEnum,
  FocusMunicipalityType,
} from '/@src/types/focus-municipalities'
import type { BulmaColor, FAIconName } from '/@src/types/elements-ui'
import type { MaybeRefOrGetter } from 'vue'
import type { TotalMonthlyTargets } from '/@src/types/monthly-targets'
import { match } from 'ts-pattern'
import { calculateNormalisedTarget } from '/@src/utils/reporting'
import { dayjsUTC } from '/@src/utils/date-formatter'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

interface FocusMunicipalityNameParam {
  name: string
  belongsToMunicipality: string | null
}

export function getMunicipalityId(
  municipalityRef: MaybeRefOrGetter<FocusMunicipalityNameParam>,
): string {
  const municipality = toValue(municipalityRef)
  if (municipality.belongsToMunicipality) {
    return `${municipality.belongsToMunicipality.toLowerCase()}-${municipality.name.toLowerCase()}`
  } else {
    return municipality.name.toLowerCase()
  }
}

export function getMunicipalityName(
  municipalityRef: MaybeRefOrGetter<FocusMunicipalityNameParam>,
): string {
  const municipality = toValue(municipalityRef)
  if (municipality.belongsToMunicipality) {
    return `${municipality.belongsToMunicipality}-${municipality.name}`
  } else {
    return municipality.name
  }
}

export const focusMunicipalityTypeIcons: Record<FocusMunicipalityType, FAIconName> = {
  [FocusMunicipalityType.Municipality]: 'fa-city',
  [FocusMunicipalityType.Borough]: 'fa-tree-city',
  [FocusMunicipalityType.Neighborhood]: 'fa-school',
  [FocusMunicipalityType.District]: 'fa-shop',
}

export function getFocusMunicipalityTargetStatus(
  value: number,
  target: TotalMonthlyTargets | number,
  allowExtra: boolean = false,
): FocusMunicipalityTargetStatusEnum {
  if (typeof target === 'number') {
    target = {
      goldValue: target * 1.5,
      silverValue: target * 1.25,
      bronzeValue: target,
    } satisfies TotalMonthlyTargets
  }

  if (value >= target.goldValue && allowExtra) {
    return FocusMunicipalityTargetStatusEnum.Gold
  } else if (value >= target.silverValue && allowExtra) {
    return FocusMunicipalityTargetStatusEnum.Silver
  } else if (value >= target.bronzeValue) {
    return FocusMunicipalityTargetStatusEnum.Bronze
  } else if (
    value < target.bronzeValue &&
    value >= Math.min(target.bronzeValue * 0.9, target.bronzeValue - 3)
  ) {
    return FocusMunicipalityTargetStatusEnum.Orange
  } else {
    return FocusMunicipalityTargetStatusEnum.Red
  }
}

export function getFocusMunicipalityTargetBulmaColor(
  valueRef: MaybeRefOrGetter<number | undefined>,
  targetRef: MaybeRefOrGetter<TotalMonthlyTargets | number | undefined>,
): BulmaColor | undefined {
  const value = toValue(valueRef)
  const target = toValue(targetRef)

  if (!target) {
    return undefined
  }

  if (!value) {
    return 'danger'
  }

  const targetStatus = getFocusMunicipalityTargetStatus(value, target)

  return match(targetStatus)
    .returnType<BulmaColor>()
    .with(
      FocusMunicipalityTargetStatusEnum.Gold,
      FocusMunicipalityTargetStatusEnum.Silver,
      FocusMunicipalityTargetStatusEnum.Bronze,
      () => 'success',
    )
    .with(FocusMunicipalityTargetStatusEnum.Orange, () => 'warning')
    .with(FocusMunicipalityTargetStatusEnum.Red, () => 'danger')
    .otherwise(() => 'info')
}

export function getFocusMunicipalityTargetColor(
  valueRef: MaybeRefOrGetter<number | undefined>,
  targetRef: MaybeRefOrGetter<TotalMonthlyTargets | number | undefined>,
) {
  const value = toValue(valueRef)
  const target = toValue(targetRef)

  if (!target) {
    return undefined
  }

  if (!value) {
    return 'has-background-danger-light'
  }

  const bulmaColor = getFocusMunicipalityTargetBulmaColor(valueRef, targetRef)

  return `has-background-${bulmaColor}`
}

export function getFocusMunicipalityTargetMedalColor(
  valueRef: MaybeRefOrGetter<number | undefined>,
  targetRef: MaybeRefOrGetter<TotalMonthlyTargets | number | undefined>,
) {
  const value = toValue(valueRef)
  const target = toValue(targetRef)

  if (!target) {
    return undefined
  }

  if (!value) {
    return 'has-background-danger'
  }

  const targetStatus = getFocusMunicipalityTargetStatus(value, target, true)

  return match(targetStatus)
    .with(FocusMunicipalityTargetStatusEnum.Gold, () => 'has-background-gold')
    .with(FocusMunicipalityTargetStatusEnum.Silver, () => 'has-background-silver')
    .with(FocusMunicipalityTargetStatusEnum.Bronze, () => 'has-background-bronze')
    .with(FocusMunicipalityTargetStatusEnum.Orange, () => 'has-background-warning')
    .with(FocusMunicipalityTargetStatusEnum.Red, () => 'has-background-danger')
    .otherwise(() => 'has-background-info')
}

export function getTargetStatus(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
): FocusMunicipalityTargetStatusEnum | undefined {
  if (targetStatus?.normalisedTarget) {
    return getFocusMunicipalityTargetStatus(
      targetStatus.matchActual,
      targetStatus.normalisedTarget,
    )
  }

  if (targetStatus?.totalTarget) {
    const target = calculateNormalisedTarget(
      targetStatus.totalTarget,
      'week',
      1,
      dayjsUTC().week(),
    )
    return getFocusMunicipalityTargetStatus(targetStatus.matchActual, target)
  }

  return undefined
}

export function getTargetStatusBulmaColor(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
) {
  if (targetStatus?.normalisedTarget) {
    return getFocusMunicipalityTargetBulmaColor(
      targetStatus.matchActual,
      targetStatus.normalisedTarget,
    )
  }

  if (targetStatus?.totalTarget) {
    const target = calculateNormalisedTarget(
      targetStatus.totalTarget,
      'week',
      1,
      dayjsUTC().week(),
    )
    return getFocusMunicipalityTargetBulmaColor(targetStatus.matchActual, target)
  }

  return undefined
}

export function getTargetStatusColorClass(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
) {
  if (targetStatus?.normalisedTarget) {
    return getFocusMunicipalityTargetColor(
      targetStatus.matchActual,
      targetStatus.normalisedTarget,
    )
  }

  if (targetStatus?.totalTarget) {
    const target = calculateNormalisedTarget(
      targetStatus.totalTarget,
      'week',
      1,
      dayjsUTC().week(),
    )
    return getFocusMunicipalityTargetColor(targetStatus.matchActual, target)
  }

  return undefined
}

export function getTargetStatusCellClass(
  targetStatus: FocusMunicipalityTargetStatus | undefined,
) {
  if (
    targetStatus?.matchActual &&
    targetStatus?.totalTarget &&
    targetStatus.matchActual >= targetStatus.totalTarget
  ) {
    return 'has-background-purple px-1 has-border-radius'
  }

  if (targetStatus?.normalisedTarget) {
    const colorClass = getFocusMunicipalityTargetColor(
      targetStatus.matchActual,
      targetStatus.normalisedTarget,
    )
    return `${colorClass} px-1 has-border-radius`
  }

  if (targetStatus?.totalTarget) {
    const target = calculateNormalisedTarget(
      targetStatus.totalTarget,
      'week',
      1,
      dayjsUTC().week(),
    )
    const colorClass = getFocusMunicipalityTargetColor(targetStatus.matchActual, target)
    return `${colorClass} px-1 has-border-radius`
  }
}

export const focusMunicipalityTargetStatusMapping: Record<
  FocusMunicipalityTargetStatusEnum,
  string
> = {
  [FocusMunicipalityTargetStatusEnum.Gold]: 'Gouden',
  [FocusMunicipalityTargetStatusEnum.Silver]: 'Zilveren',
  [FocusMunicipalityTargetStatusEnum.Bronze]: 'Groene', // Bronze doubles as Green in this case
  [FocusMunicipalityTargetStatusEnum.Orange]: 'Oranje',
  [FocusMunicipalityTargetStatusEnum.Red]: 'Rode',
}
