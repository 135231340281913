import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  AdminTask,
  AdminTaskAutomaticUpdateParams,
  AdminTaskId,
  AdminTaskParams,
  AutomaticTask,
  MinimalAdminTaskParams,
  SearchTaskableResult,
  TaskableId,
  TaskableOptionsType,
  TaskModelParams,
} from '/@src/types/admin-tasks'
import { AdminTaskPriority, AdminTaskStatus, TaskableType } from '/@src/types/admin-tasks'
import type { ApiResponse, MaybeArray } from '/@src/types/utils'
import { toast } from 'vue-sonner'
import {
  rejectWebSocketUpdate,
  resolveWebSocketUpdate,
  updateModel,
} from '/@src/utils/webSockets'
import dayjs, { Dayjs } from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { isArray } from 'lodash'
import { TaskableTypeMapping } from '/@src/mapping/admin-tasks'
import type { UserId } from '/@src/types/users'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'

dayjs.extend(isToday)

const api = useApi()

interface State {
  taskModalParams: TaskModelParams
  tasksForToday: AdminTask[]

  showAnimationForNewTasks: boolean
  numberOfCompletedTasks: number
}

export const useTasksStore = defineStore('tasks', {
  state: (): State => ({
    taskModalParams: {
      open: false,
      task: undefined,
      taskableId: undefined,
      taskableType: undefined,
    },
    tasksForToday: [],

    numberOfCompletedTasks: 0,
    showAnimationForNewTasks: true,
  }),
  persist: usePersist<State>({
    pick: ['numberOfCompletedTasks', 'showAnimationForNewTasks'],
  }),
  actions: {
    newTask() {
      this.taskModalParams = {
        open: true,
      }
    },
    newSpecificTask(taskableId: TaskableId, taskableType: TaskableOptionsType) {
      this.taskModalParams = {
        open: true,
        taskableId: taskableId,
        taskableType: taskableType,
      }
    },
    closeTaskModal() {
      this.taskModalParams = {
        open: false,
        task: undefined,
        taskableId: undefined,
        taskableType: undefined,
      }
    },

    showedEmojis() {
      this.numberOfCompletedTasks = 0
    },

    receivedNewTask(newTask: AdminTask) {
      let addedNewTaskToToday = false

      const todayTaskExists = this.tasksForToday.find((task) => task.id === newTask.id)

      if (newTask.scheduledFor.isToday() && !todayTaskExists) {
        this.tasksForToday.push(newTask)
        addedNewTaskToToday = true
      }

      return addedNewTaskToToday
    },

    updateInternalTask(updatedTask: AdminTask) {
      const todayTaskExists = this.tasksForToday.find(
        (task) => task.id === updatedTask.id,
      )

      if (updatedTask.scheduledFor.isToday() && todayTaskExists) {
        updateModel(todayTaskExists, updatedTask)
      }
    },

    async createMinimalTask(params: MinimalAdminTaskParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put('admin/tasks/create/quick', params)
          .then(resolveWebSocketUpdate(resolve, 'Herinnering is aangemaakt'))
          .catch(rejectWebSocketUpdate(resolve, 'Herinnering aanmaken is mislukt'))
      })
    },

    async createTask(params: AdminTaskParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put('admin/tasks/create', params)
          .then(resolveWebSocketUpdate(resolve, 'Herinnering is aangemaakt'))
          .catch(rejectWebSocketUpdate(resolve, 'Herinnering aanmaken is mislukt'))
      })
    },

    async updateTask(taskId: AdminTaskId, params: AdminTaskParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/tasks/${taskId}/update`, params)
          .then(resolveWebSocketUpdate(resolve, 'Herinnering is aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Herinnering aanpassen is mislukt'))
      })
    },

    async reassignTasks(adminTaskIds: MaybeArray<AdminTaskId>, userId: UserId) {
      let adminTaskIdsParams: AdminTaskId[]

      if (!isArray(adminTaskIds)) {
        adminTaskIdsParams = [adminTaskIds]
      } else {
        adminTaskIdsParams = adminTaskIds
      }

      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/tasks/reassign`, {
            userId: userId,
            ids: adminTaskIdsParams,
          })
          .then(
            resolveWebSocketUpdate(
              resolve,
              adminTaskIdsParams.length === 1
                ? 'Herinnering is toegewezen'
                : 'Herinneringen zijn toegewezen',
            ),
          )
          .catch(
            rejectWebSocketUpdate(
              resolve,
              `Herinneringen${
                adminTaskIdsParams.length === 1 ? 'en' : ''
              } toewijzen mislukt`,
            ),
          )
      })
    },

    async completeTasks(adminTaskIds: MaybeArray<AdminTaskId>, userId?: UserId) {
      let adminTaskIdsParams: AdminTaskId[]

      if (!isArray(adminTaskIds)) {
        adminTaskIdsParams = [adminTaskIds]
      } else {
        adminTaskIdsParams = adminTaskIds
      }

      this.numberOfCompletedTasks += adminTaskIdsParams.length

      // Make sure this number doesn't get too big
      this.numberOfCompletedTasks %= 20

      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/tasks/complete`, {
            ids: adminTaskIdsParams,
            userId: userId,
          })
          .then(
            resolveWebSocketUpdate(
              resolve,
              adminTaskIdsParams.length === 1
                ? 'Herinnering is afgevinkt'
                : 'Herinneringen zijn afgevinkt',
            ),
          )
          .catch(
            rejectWebSocketUpdate(
              resolve,
              `Herinneringen${
                adminTaskIdsParams.length === 1 ? 'en' : ''
              } afvinken mislukt`,
            ),
          )
      })
    },

    async cancelTasks(adminTaskIds: MaybeArray<AdminTaskId>, userId?: UserId) {
      let adminTaskIdsParams: AdminTaskId[]

      if (!isArray(adminTaskIds)) {
        adminTaskIdsParams = [adminTaskIds]
      } else {
        adminTaskIdsParams = adminTaskIds
      }

      return new Promise<boolean>((resolve) => {
        api
          .put('admin/tasks/cancel', {
            ids: adminTaskIdsParams,
            userId: userId,
          })
          .then(
            resolveWebSocketUpdate(
              resolve,
              adminTaskIdsParams.length === 1
                ? 'Herinnering is geannuleerd'
                : 'Herinneringen zijn geannuleerd',
            ),
          )
          .catch(
            rejectWebSocketUpdate(
              resolve,
              `Herinneringen${
                adminTaskIdsParams.length === 1 ? 'en' : ''
              } annuleren mislukt`,
            ),
          )
      })
    },

    async postponeTasks(
      adminTaskIds: MaybeArray<AdminTaskId>,
      duration: 'day' | 'week' | 'other',
      postponeUntil: Dayjs | undefined = undefined,
      userId?: UserId,
    ) {
      let adminTaskIdsParams: AdminTaskId[]

      if (!isArray(adminTaskIds)) {
        adminTaskIdsParams = [adminTaskIds]
      } else {
        adminTaskIdsParams = adminTaskIds
      }

      return new Promise<boolean>((resolve) => {
        api
          .put('admin/tasks/postpone', {
            ids: adminTaskIdsParams,
            duration: duration,
            postponeUntil: postponeUntil,
            userId: userId,
          })
          .then(
            resolveWebSocketUpdate(
              resolve,
              adminTaskIdsParams.length === 1
                ? 'Herinnering is uitgesteld'
                : 'Herinneringen zijn uitgesteld',
            ),
          )
          .catch(
            rejectWebSocketUpdate(
              resolve,
              `Herinneringen${
                adminTaskIdsParams.length === 1 ? 'en' : ''
              } uitstellen mislukt`,
            ),
          )
      })
    },

    async updateStatus(adminTaskId: AdminTaskId, status: AdminTaskStatus) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/tasks/${adminTaskId}/update/status`, {
            status: status,
          })
          .then(resolveWebSocketUpdate(resolve, 'Status van herinnering is aangepast!'))
          .catch(
            rejectWebSocketUpdate(resolve, 'Status van herinnering aanpassen mislukt'),
          )
      })
    },

    async updateDescription(adminTaskId: AdminTaskId, description: string) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/tasks/${adminTaskId}/update/description`, {
            description: description,
          })
          .then(resolveWebSocketUpdate(resolve, 'Notities van herinnering is aangepast'))
          .catch(
            rejectWebSocketUpdate(resolve, 'Notities van herinnering aanpassen mislukt'),
          )
      })
    },

    async updateFieldAutomatically(payload: AdminTaskAutomaticUpdateParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/tasks/${payload.id}/update/automatic`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Herinnering aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Herinnering aanpassen mislukt'))
      })
    },

    async fetchForDate(date: Dayjs, userId?: UserId) {
      return new Promise<AdminTask[] | false>((resolve) => [
        api
          .post<ApiResponse<AdminTask[]>>(`admin/tasks/${userId ?? ''}`, {
            date: date,
          })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Herinneringen ophalen mislukt')
            resolve(false)
          }),
      ])
    },

    async fetchUpcomingReminders(userId?: UserId) {
      return new Promise<AdminTask[] | false>((resolve) => [
        api
          .get<ApiResponse<AdminTask[]>>(`admin/tasks/upcoming/${userId ?? ''}`)
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Herinneringen ophalen mislukt')
            resolve(false)
          }),
      ])
    },

    async fetchRemindersForCalendar(startDate: Dayjs, endDate: Dayjs, userId?: UserId) {
      return new Promise<AdminTask[] | false>((resolve) => [
        api
          .post<ApiResponse<AdminTask[]>>(`admin/tasks/calendar/${userId ?? ''}`, {
            startDate,
            endDate,
          })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Herinneringen ophalen mislukt')
            resolve(false)
          }),
      ])
    },

    async fetchAutomaticTasks() {
      return new Promise<AutomaticTask[] | false>((resolve) => [
        api
          .get<ApiResponse<AutomaticTask[]>>('admin/tasks/automatic-list')
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Herinneringen ophalen mislukt')
            resolve(false)
          }),
      ])
    },

    async getToday(userId?: UserId) {
      return new Promise<AdminTask[] | false>((resolve) => {
        api
          .get<ApiResponse<AdminTask[]>>(`admin/tasks/today/${userId ?? ''}`)
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Herinneringen ophalen mislukt')
            resolve(false)
          })
      })
    },

    async fetchTasksForNotification() {
      return new Promise<AdminTask[]>((resolve) => {
        api
          .get<ApiResponse<AdminTask[]>>('admin/tasks/current-notifications')
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            resolve([])
          })
      })
    },

    async searchTaskable(taskableId: number, taskableType: TaskableType) {
      return new Promise<SearchTaskableResult | false>((resolve) => {
        api
          .post<SearchTaskableResult>('admin/tasks/search-taskable', {
            taskableId: taskableId,
            taskableType: taskableType,
          })
          .then((result) => {
            const jsonData = result.data

            if (!jsonData.exists) {
              toast.error(`${TaskableTypeMapping[taskableType]} niet gevonden`)
              resolve(false)
            }

            resolve(jsonData)
          })
          .catch(() => {
            toast.error(`${TaskableTypeMapping[taskableType]} niet gevonden`)
            resolve(false)
          })
      })
    },
  },
  getters: {
    activeTasksForToday: (state: State): AdminTask[] => {
      return state.tasksForToday.filter((task) => task.status === AdminTaskStatus.Active)
    },

    emojiChance: (state: State): number =>
      Math.min(state.numberOfCompletedTasks / 10 + 0.1, 1.0),

    toolbarTasks: (state: State): AdminTask[] =>
      state.tasksForToday
        .filter((task) => task.status === AdminTaskStatus.Active)
        .sort((task1, task2) => dayjs(task1.scheduledFor).diff(task2.scheduledFor))
        .slice(0, 5)
        .sort((task1, task2) => {
          if (task1.priority === task2.priority) {
            return 0
          } else if (task1.priority === AdminTaskPriority.High) {
            return -1 // -1 means task1 comes before task 2
          } else if (task2.priority === AdminTaskPriority.High) {
            return 1
          } else {
            return 0
          }
        }),
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTasksStore, import.meta.hot))
}
