import type {
  DashboardLoadout,
  DashboardLoadoutId,
  DashboardSlot,
  DashboardWidgetKey,
  DashboardWidgetProps,
  DashboardWidgetTranslationDirection,
  LoadoutSettings,
} from '/@src/types/dashboard'
import { match } from 'ts-pattern'
import dayjs from 'dayjs'
import { UserRoleEnum } from '/@src/types/users'
import { dayjsUTC } from '/@src/utils/date-formatter'
import type { AdminUser } from '/@src/types/admin-users'
import { isHelpdesker } from '/@src/utils/entity-checks'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { formatPercentage } from '/@src/utils/helpers'

export const dashboardWidgetMap: Record<DashboardWidgetKey, Component> = {
  empty: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/EmptyDashboardWidget.vue'),
  ),
  'hidden-empty': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/EmptyDashboardWidget.vue'),
  ),
  'general-stats': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/GeneralStatsDashboardWidget.vue'),
  ),
  'focus-municipalities': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/FocusMunicipalityDashboardWidget.vue'),
  ),
  matchmakers: defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/MatchmakerGraphDashboardWidget.vue'),
  ),
  'matchmaker-stats': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/MatchmakerStatsDashboardWidget.vue'),
  ),
  notes: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/NotesDashboardWidget.vue'),
  ),
  'global-search': defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/GlobalSearchDashboardWidget.vue'),
  ),
  'average-ratings': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/AverageRatingsDashboardWidget.vue'),
  ),
  'new-match-requests': defineAsyncComponent(
    () =>
      import('/@src/components/organisms/dashboard/NewMatchRequestsDashboardWidget.vue'),
  ),
  'waiting-list-match-requests': defineAsyncComponent(
    () =>
      import(
        '/@src/components/organisms/dashboard/WaitingListMatchRequestsDashboardWidget.vue'
      ),
  ),
  vogs: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/VogDashboardWidget.vue'),
  ),
  animals: defineAsyncComponent(
    () => import('/@src/components/organisms/dashboard/AnimalsDashboardWidget.vue'),
  ),
}

export const getDashboardWidgetDefaultSettings = (
  key: DashboardWidgetKey | null | undefined,
): DashboardWidgetProps => {
  return match(key)
    .returnType<DashboardWidgetProps>()
    .with('general-stats', () => ({
      componentKey: 'general-stats',
      size: 'single',
      hidden: false,
      startDate: dayjs().startOf('year'),
      endDate: dayjs(),
    }))
    .with(
      'focus-municipalities',
      'global-search',
      'new-match-requests',
      'notes',
      (value) => ({
        componentKey: value,
        size: 'single',
        hidden: false,
        stringInput: '',
      }),
    )
    .with('matchmakers', () => ({
      componentKey: 'matchmakers',
      size: 'single',
      hidden: false,
      userIdFilter: undefined,
    }))
    .with('vogs', () => ({
      componentKey: 'vogs',
      size: 'single',
      hidden: false,
      vogTab: 'new',
    }))
    .with('waiting-list-match-requests', (value) => ({
      componentKey: value,
      size: 'single',
      hidden: false,
      booleanInput: false,
    }))
    .with('animals', () => ({
      componentKey: 'animals',
      size: 'single',
      hidden: false,
      animal: useAdminUsersStore().adminUser?.favoriteAnimal ?? 'cat',
    }))
    .with(
      'average-ratings',
      'matchmaker-stats',
      'empty',
      'hidden-empty',
      null,
      undefined,
      (value) => ({
        componentKey: value ?? 'empty',
        size: 'single',
        hidden: false,
      }),
    )
    .exhaustive()
}

export interface DashboardWidgetInfo {
  key: DashboardWidgetKey
  title: string
  description: string
  disabled: boolean | (() => boolean)
}

export const dashboardWidgetSelectorMap: Record<DashboardWidgetKey, DashboardWidgetInfo> =
  {
    empty: {
      key: 'empty',
      title: '',
      description: '',
      disabled: true,
    },
    'hidden-empty': {
      key: 'hidden-empty',
      title: '',
      description: '',
      disabled: true,
    },
    'general-stats': {
      key: 'general-stats',
      title: 'Algemene statistieken',
      description:
        'Laat algemene statistieken zien, o.e. aantal aanvragen per status en de gemiddelde cijfers van thuiskoks en maaltijdzoekers',
      disabled: false,
    },
    'focus-municipalities': {
      key: 'focus-municipalities',
      title: 'Focus-gemeentes',
      description:
        'Overzicht van focus-gemeentes en de status van de doelen in de focus-gemeentes',
      disabled: false,
    },
    matchmakers: {
      key: 'matchmakers',
      title: 'Matchmakers',
      description: 'Grafiek en overzicht van aanvragen per matchmaker',
      disabled: false,
    },
    'matchmaker-stats': {
      key: 'matchmaker-stats',
      title: 'Matchmaker Overzicht',
      description:
        'Overzicht van de matchmakers. Bevat onder andere de werkdagen en hoeveel aanvragen er actief zijn per matchmaker',
      disabled: () => !isHelpdesker.value,
    },
    notes: {
      key: 'notes',
      title: 'Notities',
      description: 'Maak snel een notitie',
      disabled: false,
    },
    'global-search': {
      key: 'global-search',
      title: 'Zoeken',
      description:
        'Zoek snel naar gebruikers, aanvragen, matches, of voer andere acties uit',
      disabled: false,
    },
    'average-ratings': {
      key: 'average-ratings',
      title: 'Tevredenheidscijfers',
      description:
        'Gemiddelde cijfers van thuiskoks en maaltijdzoekers uit de contactmomenten',
      disabled: false,
    },
    'new-match-requests': {
      key: 'new-match-requests',
      title: 'Nieuwe aanvragen',
      description: 'Overzicht van nieuw binnengekomen aanvragen',
      disabled: () => !isHelpdesker.value,
    },
    'waiting-list-match-requests': {
      key: 'waiting-list-match-requests',
      title: 'Wachtlijst aanvragen',
      description: 'Overzicht van de aanvragen op de wachtlijst',
      disabled: () => !isHelpdesker.value,
    },
    vogs: {
      key: 'vogs',
      title: 'VOGs',
      description: 'Overzicht van VOG status van koks',
      disabled: () => !isHelpdesker.value,
    },
    animals: {
      key: 'animals',
      title: '???',
      description: '',
      disabled: false,
    },
  }

export const getNeighborCellSlot = (
  cell: DashboardSlot,
  direction: DashboardWidgetTranslationDirection,
) => {
  return match([cell, direction])
    .returnType<DashboardSlot | undefined | null>()
    .with(['slotA', 'up'], () => undefined)
    .with(['slotA', 'right'], () => 'slotB')
    .with(['slotA', 'down'], () => 'slotC')
    .with(['slotA', 'left'], () => undefined)
    .with(['slotB', 'up'], () => undefined)
    .with(['slotB', 'right'], () => undefined)
    .with(['slotB', 'down'], () => 'slotD')
    .with(['slotB', 'left'], () => 'slotA')
    .with(['slotC', 'up'], () => 'slotA')
    .with(['slotC', 'right'], () => 'slotD')
    .with(['slotC', 'down'], () => undefined)
    .with(['slotC', 'left'], () => undefined)
    .with(['slotD', 'up'], () => 'slotB')
    .with(['slotD', 'right'], () => undefined)
    .with(['slotD', 'down'], () => undefined)
    .with(['slotD', 'left'], () => 'slotC')
    .exhaustive()
}

export const getDefaultLayoutForRole = (adminUser: AdminUser): DashboardLoadout => {
  const newLoadout = match(adminUser.mainRole)
    .returnType<LoadoutSettings>()
    .with(UserRoleEnum.Helpdesker, () => ({
      position: {
        slotA: 'matchmaker-stats',
        slotB: 'hidden-empty',
        slotC: 'new-match-requests',
        slotD: 'global-search',
      },
      settings: [
        {
          ...getDashboardWidgetDefaultSettings('matchmaker-stats'),
          size: 'horizontal',
        },
        getDashboardWidgetDefaultSettings('new-match-requests'),
        getDashboardWidgetDefaultSettings('global-search'),
      ],
    }))
    .otherwise(() => ({
      position: {
        slotA: 'general-stats',
        slotB: 'hidden-empty',
        slotC: 'focus-municipalities',
        slotD: 'matchmakers',
      },
      settings: [
        {
          ...getDashboardWidgetDefaultSettings('general-stats'),
          size: 'horizontal',
        },
        getDashboardWidgetDefaultSettings('focus-municipalities'),
        getDashboardWidgetDefaultSettings('matchmakers'),
      ],
    }))

  return {
    id: -1 as DashboardLoadoutId,
    adminUserId: adminUser.id,
    loadoutName: 'Standaard',
    createdAt: dayjsUTC(),
    updatedAt: dayjsUTC(),
    loadout: newLoadout,
  }
}

export function formatMatchmakerDashboardStatistics(
  value: number | undefined,
  target: number | undefined,
  redFocusAmount: number | null,
  showPercentages: boolean,
) {
  if (value && redFocusAmount) {
    value += redFocusAmount
  }

  if (showPercentages) {
    return formatPercentage(value ?? undefined, target ?? undefined)
  } else {
    if (redFocusAmount != null) {
      return `${value ?? '-'} (${redFocusAmount}) / ${target?.toFixed(0) ?? 0}`
    }
    return `${value ?? '-'} / ${target?.toFixed(0) ?? 0}`
  }
}

export function getMatchmakerStatisticsCellClass(
  value: number | undefined,
  target: number | undefined,
  redFocusAmount: number | null,
) {
  const baseClass = 'is-justify-content-center'

  if (value == null || target == null) {
    return baseClass
  }

  const currentCount = value + (redFocusAmount ?? 0)

  if (currentCount === 0 && target === 0) {
    return `${baseClass} has-background-success-light`
  } else if (currentCount !== 0 && currentCount <= target) {
    return `${baseClass} has-background-success-light`
  } else if (
    currentCount > target &&
    currentCount <= Math.max(target + 3, target * 1.1)
  ) {
    return `${baseClass} has-background-warning-light`
  } else {
    return `${baseClass} has-background-danger-light`
  }
}
