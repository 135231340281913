import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import { toast } from 'vue-sonner'
import type {
  AddressFilterData,
  AddressQuery,
  MatchmakerStatistics,
  MatchmakerStatisticsResult,
} from '/@src/types/dashboard'
import type { ApiResponse, CompleteAddress } from '/@src/types/utils'
import type {
  BaseAddress,
  FullAddressData,
  GlobalSearch,
  GlobalSearchResult,
  GlobalsResponse,
} from '/@src/types/globals'
import { clone, cloneDeep } from 'lodash'
import type { AmountOfContactsOptions } from '/@src/types/contact-importing'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { checkGlobalSearchQuery } from '/@src/mapping/global-search'
import { LimitedArray } from '/@src/utils/LimitedArray'
import { allowReadOnly } from '/@src/utils/axios-utils'
import { useMonthlyTargetsStore } from '/@src/stores/monthlyTargets'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'
import { useDashboardStore } from '/@src/stores/dashboard'

const api = useApi()

interface State {
  hasLoaded: boolean
  doneLoading: boolean
  userHash?: string

  searchHistory: GlobalSearch[]

  matchmakerStatistics?: MatchmakerStatisticsResult

  fullAddressData: FullAddressData
  filteredAddressData: FullAddressData

  newMatchesCount?: number

  maxAmountOfContacts: AmountOfContactsOptions
}

export const useGlobalsStore = defineStore('globals', {
  state: (): State => ({
    hasLoaded: false,
    doneLoading: false,
    userHash: undefined,

    searchHistory: new LimitedArray<GlobalSearch>(5),

    matchmakerStatistics: undefined,

    fullAddressData: {
      municipality: [],
      city: [],
      borough: [],
      neighborhood: [],
      district: [],
    },
    filteredAddressData: {
      municipality: [],
      city: [],
      borough: [],
      neighborhood: [],
      district: [],
    },

    newMatchesCount: undefined,

    maxAmountOfContacts: 10,
  }),

  persist: usePersist<State>({
    omit: ['doneLoading'],
  }),

  actions: {
    async globalSearchQuery(query: string) {
      return new Promise<GlobalSearchResult>((resolve) => {
        if (!query) {
          resolve({
            data: [],
            query: query,
          })

          return
        }

        const foundResponse = checkGlobalSearchQuery(query)

        if (foundResponse) {
          const searchResponse = foundResponse.map((searchResult) => {
            return {
              ...searchResult,
              type: 'history',
            } satisfies GlobalSearch
          })

          this.searchHistory.unshift(...searchResponse)

          resolve({
            data: foundResponse,
            query: query,
          })
          return
        }

        api
          .post<ApiResponse<GlobalSearch[]>>('admin/globals/search', {
            query: query,
          })
          .then((response) => {
            const results = response.data.data

            if (results.length > 0) {
              this.searchHistory.unshift({
                id: -1,
                type: 'history',
                fullName: `Gezocht naar "${query}"`,
                query: query,
              })
            }

            resolve({
              data: results,
              query: query,
            })
          })
          .catch(() =>
            resolve({
              data: [],
              query: query,
            }),
          )
      })
    },

    async fetchGlobalData() {
      if (this.doneLoading) {
        return
      }

      return new Promise<boolean>((resolve) => {
        api
          .get<GlobalsResponse>('admin/globals/')
          .then((response) => {
            this.hasLoaded = true
            this.doneLoading = true
            const jsonResponse = response.data

            const adminUserStore = useAdminUsersStore()

            adminUserStore.adminUser = jsonResponse.adminUser

            adminUserStore.matchmakers = jsonResponse.adminUsers.matchmakers
            adminUserStore.helpdeskers = jsonResponse.adminUsers.helpdeskers
            adminUserStore.impactAccelerators = jsonResponse.adminUsers.impactAccelerators
            adminUserStore.developers = jsonResponse.adminUsers.developers
            adminUserStore.exMatchmakers = jsonResponse.adminUsers.exMatchmakers
            adminUserStore.misc = jsonResponse.adminUsers.misc

            useMonthlyTargetsStore().monthlyTargets = jsonResponse.monthlyTargets

            const focusMunicipalityStore = useFocusMunicipalitiesStore()

            focusMunicipalityStore.data = jsonResponse.focusMunicipalities
            focusMunicipalityStore.targetStatus = jsonResponse.focusMunicipalityStatus

            const dashboardStore = useDashboardStore()
            dashboardStore.loadouts = jsonResponse.dashboardLoadouts
            dashboardStore.setCurrentLoadout(
              jsonResponse.adminUser.currentDashboardLoadoutId,
            )
            dashboardStore.doneLoading = true

            this.matchmakerStatistics = jsonResponse.matchmakerStatistics
            this.fullAddressData = jsonResponse.addressData
            this.filteredAddressData = cloneDeep(jsonResponse.addressData)
            this.newMatchesCount = jsonResponse.newMatchesCount

            resolve(true)
          })
          .catch(() => {
            toast.error(
              'Er is iets misgegaan bij het ophalen van de data. Probeer uit te loggen en opnieuw in te loggen',
            )
            resolve(false)
          })
      })
    },

    async fetchFilteredAddressData(addressFilter: AddressQuery): Promise<boolean> {
      // No filter is selected, so all data needs to be returned

      const noFiltersSelected =
        (!addressFilter.municipality || addressFilter.municipality.length === 0) &&
        (!addressFilter.city || addressFilter.city.length === 0) &&
        (!addressFilter.borough || addressFilter.borough.length === 0) &&
        (!addressFilter.neighborhood || addressFilter.neighborhood.length === 0) &&
        (!addressFilter.district || addressFilter.district.length === 0)

      const allFiltersSelected =
        !!addressFilter.municipality &&
        addressFilter.municipality.length > 0 &&
        !!addressFilter.city &&
        addressFilter.city.length > 0 &&
        !!addressFilter.borough &&
        addressFilter.borough.length > 0 &&
        !!addressFilter.neighborhood &&
        addressFilter.neighborhood.length > 0 &&
        !!addressFilter.district &&
        addressFilter.district.length > 0

      if (noFiltersSelected) {
        this.filteredAddressData = clone(this.fullAddressData)
        return new Promise((resolve) => resolve(false))
      }

      if (allFiltersSelected) {
        return new Promise((resolve) => resolve(false))
      }

      return new Promise<boolean>((resolve) => {
        api
          .post<AddressFilterData>(
            'admin/globals/filter-municipalities',
            addressFilter,
            allowReadOnly(),
          )
          .then(
            (response) => {
              const responseJson = response.data

              // Only store 'yet to be filtered' areas, else it is impossible to select multiple values
              if (
                !addressFilter.municipality ||
                addressFilter.municipality.length === 0
              ) {
                this.filteredAddressData.municipality = responseJson.results.municipality
              }

              if (!addressFilter.city || addressFilter.city.length === 0) {
                this.filteredAddressData.city = responseJson.results.city
              }

              if (!addressFilter.borough || addressFilter.borough.length === 0) {
                this.filteredAddressData.borough = responseJson.results.borough
              }

              if (
                !addressFilter.neighborhood ||
                addressFilter.neighborhood.length === 0
              ) {
                this.filteredAddressData.neighborhood = responseJson.results.neighborhood
              }

              if (!addressFilter.district || addressFilter.district.length === 0) {
                this.filteredAddressData.district = responseJson.results.district
              }

              resolve(true)
            },
            () => {
              toast.error('Kon adresgegevens niet ophalen.')
              resolve(false)
            },
          )
      })
    },

    async searchAddress(address: BaseAddress) {
      return new Promise<CompleteAddress | false>((resolve) => {
        api
          .post<ApiResponse<CompleteAddress>>(
            '/admin/search/address',
            address,
            allowReadOnly(),
          )
          .then((result) => {
            const jsonData = result.data
            resolve(jsonData.data)
          })
          .catch(() => resolve(false))
      })
    },

    async searchHouseNumbers(address: BaseAddress): Promise<string[] | boolean> {
      return new Promise<string[] | boolean>((resolve) => {
        api
          .post<ApiResponse<string[]>>(
            'admin/search/house-number',
            address,
            allowReadOnly(),
          )
          .then((response) => {
            const houseNumbers = response.data.data

            if (houseNumbers.length > 1) {
              resolve(houseNumbers)
            } else if (houseNumbers.length === 1) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(() => resolve(false))
      })
    },
  },

  getters: {
    getMatchmakerStatisticsSafe: (state: State) => (): MatchmakerStatistics => {
      if (state.matchmakerStatistics) {
        return state.matchmakerStatistics.data
      }

      return {
        [-1]: {
          open: {
            count: -1,
            municipalities: [],
          },
          currentWeek: {
            count: -1,
            municipalities: [],
          },
          suggested: {
            count: -1,
            municipalities: [],
          },
        },
      }
    },
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalsStore, import.meta.hot))
}
