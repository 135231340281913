import type { PiniaPluginContext, StateTree } from 'pinia'
import { pick } from 'lodash'
import defu from 'defu'
import type { Path } from 'deep-pick-omit'

export interface PiniaLogoutProperties {
  paths: string[]
}

declare module 'pinia' {
  export interface DefineStoreOptionsBase<S, Store> {
    logout?:
      | boolean
      | {
          pick: Path<S>[]
        }
  }
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    onLogout: () => void
  }
}

export function PiniaLogout(context: PiniaPluginContext) {
  const { store, options } = context
  const { state, logout } = options

  return {
    onLogout: markRaw(() => {
      let newState: StateTree
      if (logout) {
        if (typeof logout === 'boolean') {
          return
        } else {
          const keptProperties = pick(store.$state, logout.pick)
          const tempState = state ? state() : {}
          newState = defu(keptProperties, tempState)
        }
      } else {
        newState = state ? state() : {}
      }

      store.$patch(($state) => {
        Object.assign($state, newState)
      })
    }),
  }
}
