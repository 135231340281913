<script setup lang="ts">
import { useTemplateRef } from 'vue'

type GlobalSearchModalEmits = {
  close: []
}

const emits = defineEmits<GlobalSearchModalEmits>()

const isOpen = defineModel<boolean>('open')

const globalSearch = useTemplateRef('globalSearch')

const searchInput = ref<string>('')

whenever(isOpen, () => nextTick(() => globalSearch.value?.focusInput()))

const closeModal = () => {
  emits('close')
  isOpen.value = false
}

const { escape } = useMagicKeys()
whenever(escape, closeModal)
</script>

<template>
  <VModal
    v-model:open="isOpen"
    size="large"
    title="Zoeken"
    cancel-label="Sluiten"
    @close="closeModal"
  >
    <template #content>
      <GlobalSearch v-model="searchInput" ref="globalSearch" @link-clicked="closeModal" />
    </template>
  </VModal>
</template>

<style scoped lang="scss"></style>
