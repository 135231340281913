<script setup lang="ts">
definePage({
  meta: {
    requiresAdmin: true,
  },
})

const route = useRoute()
const router = useRouter()

watch(
  () => route.name,
  (newValue) => {
    if (newValue === '/analysis') {
      router.replace({
        name: '/analysis/',
      })
    }
  },
  { immediate: true },
)
</script>

<template>
  <div>
    <RouterLink v-if="route.name !== '/analysis/'" :to="{ name: '/analysis/' }">
      <VButton icon-right="fa-arrow-left" color="info" outlined>
        Ga naar overzicht
      </VButton>
    </RouterLink>

    <RouterView v-slot="{ Component }">
      <div class="page-content-inner">
        <component :is="Component" />
      </div>
    </RouterView>
  </div>
</template>
