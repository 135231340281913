import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import { snakeCaseToTitleCase } from '/@src/utils/helpers'
import { camelCase, find, findKey } from 'lodash'
import type { GroupOptions } from '/@src/types/elements-ui'
import type {
  SignupReference,
  SignupReferenceId,
  SignupReferences,
  UpdateSignupReferenceParams,
} from '/@src/types/signup-references'
import { toast } from 'vue-sonner'
import type { ApiResponse } from '/@src/types/utils'
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { SignupReferenceMiscId } from '/@src/mapping/signup-references'
import { RoleTypeEnum } from '/@src/types/shared'

const api = useApi()

export const useSignupReferencesStore = defineStore('signupReferences', () => {
  const data = ref<SignupReferences>()

  const flatSignupReferences = computed<SignupReference[] | undefined>(() => {
    if (!data.value) {
      return undefined
    }
    return Object.values(data.value).flat()
  })

  useWebSocket({
    event: '.SignupReferenceUpdatedOrCreated',
    callback: (newSignupReference: WebSocketsModelReply<SignupReference>) => {
      if (!data.value) {
        return
      }

      const camelCaseSignupReference = camelCase(newSignupReference.model.municipality)
      const signupReference = find(
        data.value[camelCaseSignupReference],
        (reference) => reference.id === newSignupReference.model.id,
      )

      if (signupReference) {
        updateModel(signupReference, newSignupReference.model)
      } else {
        data.value[camelCaseSignupReference].push(newSignupReference.model)
      }
    },
    channel: 'tg-admin-channel-signup-references',
    cancelOnUnmounted: false,
  })

  const create = async (payload: UpdateSignupReferenceParams): Promise<boolean> => {
    try {
      const result = await api.post<ApiResponse<SignupReference>>(
        'admin/signup-references',
        payload,
      )

      toast.success('Referentie maken gelukt!')
      return !!result.data.data
    } catch {
      toast.error('Referentie maken is mislukt')
      return false
    }
  }

  const fetch = async (): Promise<boolean> => {
    try {
      const result = await api.get<ApiResponse<SignupReferences>>(
        'admin/signup-references',
      )

      data.value = result.data.data
      return true
    } catch {
      toast.error('Ophalen signup references mislukt')
      return false
    }
  }
  fetch()

  const update = async (
    referenceId: SignupReferenceId,
    payload: UpdateSignupReferenceParams,
  ): Promise<boolean> => {
    try {
      await api.post(`admin/signup-references/${referenceId}`, payload)

      toast.success('Updaten van referentie gelukt')
      return true
    } catch {
      toast.error('Fout bij wijzigen referentie')
      return false
    }
  }

  const getSignupReference = (
    signupReference: SignupReferenceId | string | undefined | null,
  ): string => {
    if (!data.value || !signupReference) {
      return "Onbekende 'gevonden via'"
    }

    if (typeof signupReference === 'string') {
      signupReference = Number(signupReference) as SignupReferenceId
    }

    const signupReferenceMunicipality = findKey(data.value, (signupReferences) =>
      signupReferences.some((reference) => reference.id === signupReference),
    )

    if (signupReferenceMunicipality) {
      const foundSignupReference = data.value[signupReferenceMunicipality].find(
        (reference) => reference.id === signupReference,
      )

      if (foundSignupReference) {
        return foundSignupReference.reference
      }
    }

    return snakeCaseToTitleCase(signupReference)
  }

  const getSignupReferenceUnsafe = (
    signupReference: SignupReferenceId | string | undefined | null,
  ): string | undefined => {
    if (!data.value || !signupReference) {
      return undefined
    }

    if (typeof signupReference === 'string') {
      signupReference = Number(signupReference) as SignupReferenceId
    }

    const signupReferenceMunicipality = findKey(data.value, (signupReferences) =>
      signupReferences.some((reference) => reference.id === signupReference),
    )

    if (signupReferenceMunicipality) {
      const foundSignupReference = data.value[signupReferenceMunicipality].find(
        (reference) => reference.id === signupReference,
      )

      if (foundSignupReference) {
        return foundSignupReference.reference
      }
    }

    return undefined
  }

  const getSignupReferenceList = (
    municipality?: string | null,
    roleType?: RoleTypeEnum,
  ): GroupOptions<SignupReferenceId>[] => {
    const list = []

    const globalSignUpReferences = data.value

    if (globalSignUpReferences) {
      list.push({
        label: 'Algemene opties',
        values: globalSignUpReferences.general
          .filter((s) => {
            if (!roleType) {
              return true
            }
            return s.roleType === roleType || s.roleType === RoleTypeEnum.Both
          })
          .map((reference: SignupReference) => {
            return {
              name: reference.id,
              label: reference.reference,
              category: reference.municipality,
            }
          })
          .sort((a, b) => {
            if (a.name === SignupReferenceMiscId) {
              return 1
            } else if (b.name === SignupReferenceMiscId) {
              return -1
            }
            return a.label.localeCompare(b.label)
          }),
      })

      if (
        municipality &&
        Object.keys(globalSignUpReferences).includes(municipality.toLowerCase())
      ) {
        list.push({
          label: municipality,
          values: globalSignUpReferences[municipality.toLowerCase()]
            .filter((s) => {
              if (!roleType) {
                return true
              }
              return s.roleType === roleType || s.roleType === RoleTypeEnum.Both
            })
            .map((reference: SignupReference) => {
              return {
                name: reference.id,
                label: reference.reference,
                category: reference.municipality,
              }
            })
            .sort((a, b) => a.label.localeCompare(b.label)),
        })
      }
    }

    return list
  }

  return {
    data,
    flatSignupReferences,

    update,
    create,

    getSignupReference,
    getSignupReferenceUnsafe,
    getSignupReferenceList,
  }
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSignupReferencesStore as any, import.meta.hot),
  )
}
