<script setup lang="ts">
import { computed } from 'vue'
import { useVFieldInjection } from '/@src/composable/useVFieldInjection'
import type { VButtonProps } from '/@src/types/elements-ui'

const props = withDefaults(defineProps<VButtonProps>(), {
  color: undefined,
  size: 'normal',
  label: undefined,
  light: false,
  outlined: false,
  inverted: false,
  rounded: false,
  loading: false,
  static: false,
  width: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  iconOnly: undefined,
})

defineOptions({
  inheritAttrs: false,
})

const $field = useVFieldInjection()

const computedColor = computed(() => {
  if ($field.color?.value && !props.overrideColor) {
    return $field.color.value
  }

  if (props.color) {
    return `is-${props.color}`
  }
  return 'is-default'
})

const computedStyle = computed(() => {
  if (!props.width || props.width === 'full') {
    return
  }
  return { width: `${props.width}%`, maxWidth: `${props.width}%` }
})
</script>

<template>
  <button
    class="button v-button"
    :class="[
      computedColor,
      size && `is-${size}`,
      { 'is-rounded': rounded },
      { 'is-light': light },
      { 'is-inverted': inverted },
      { 'is-loading': loading },
      { 'is-static': static },
      { 'is-outlined': outlined },
      { 'has-border': border },
      { 'is-fullwidth': width === 'full' },
      { 'has-only-icon': !!iconOnly },
    ]"
    :style="computedStyle"
    v-bind="$attrs"
  >
    <slot name="icon-left"><VIcon v-if="iconLeft" :icon="iconLeft" /></slot>
    <slot name="icon-only"><VIcon v-if="iconOnly && !loading" :icon="iconOnly" /></slot>
    <span v-if="!iconOnly" class="icon-label">
      <slot> {{ label }} </slot>
    </span>
    <slot name="icon-right"><VIcon v-if="iconRight" :icon="iconRight" /></slot>
  </button>
</template>

<style scoped lang="scss">
.icon-label:empty {
  display: none;
}
</style>
