import { definePlugin } from '/@src/utils/app-helper'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

export default definePlugin(({ app }) => {
  app.use(FloatingVue, {
    themes: {
      menu: {
        $extend: 'tooltip',
      },
      dropdown: {
        $extend: 'tooltip',
      },
      legend: {
        $extend: 'dropdown',
      },
    },
  })
})
