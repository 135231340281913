import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  DashboardLoadout,
  DashboardLoadoutId,
  DashboardMutation,
  DashboardWidgetKey,
  DashboardWidgetPropMap,
  DashboardWorkdayHistoryResponse,
  GetDashboardLoadoutsResponse,
  MatchmakerKpiDashboardResource,
  MatchmakerKPIResource,
} from '/@src/types/dashboard'
import { toast } from 'vue-sonner'
import type { ApiResponse } from '/@src/types/utils'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'
import type { Rock } from '/@src/types/reporting'
import { allowReadOnly } from '/@src/utils/axios-utils'
import type { UserId } from '/@src/types/users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import {
  getDashboardWidgetDefaultSettings,
  getDefaultLayoutForRole,
} from '/@src/mapping/dashboard'
import { useWebSocket, useWebSocketModel } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { LimitedArray } from '/@src/utils/LimitedArray'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'
import { dayjsUTC } from '/@src/utils/date-formatter'
import { fakerNL as faker } from '@faker-js/faker'
import { capitalize, getRandomColor } from '/@src/utils/helpers'
import { onboarding } from '/@src/utils/onboarding'

const api = useApi()

export const useDashboardStore = defineStore(
  'dashboard',
  () => {
    const adminUserStore = useAdminUsersStore()

    const currentInterimRockTarget = ref<number>()

    const loadouts = ref<DashboardLoadout[]>([])
    const currentLoadout = ref<DashboardLoadout>()
    const dashboardHistory = ref<LimitedArray<DashboardMutation>>(new LimitedArray(50))

    const doneLoading = ref(false)

    const currentLoadoutIsEmpty = computed(() => {
      if (!currentLoadout.value) {
        return true
      }
      const positions = currentLoadout.value.loadout.position
      return Object.values(positions).every((p) => p === 'empty' || p === 'hidden-empty')
    })

    const loadoutsAreFromAnotherUser = computed(() => {
      if (!adminUserStore.adminUser) {
        return false
      }
      loadouts.value.some((l) => l.adminUserId !== adminUserStore.adminUser?.id)
    })

    const { setModels } = useWebSocketModel<DashboardLoadout>({
      baseChannel: 'tg-admin-channel-admin-user-dashboard-loadout',
      event: '.AdminUserDashboardLoadoutUpdated',
      callback: (newLoadout: WebSocketsModelReply<DashboardLoadout>) => {
        const loadout = loadouts.value.find((l) => l.id === newLoadout.model.id)
        if (loadout) {
          updateModel(loadout, newLoadout.model)
        }
        if (currentLoadout.value?.id === newLoadout.model.id) {
          updateModel(currentLoadout.value, newLoadout.model)
        }
      },
    })
    watch(loadouts, (newValue) => setModels(newValue))

    useWebSocket({
      event: '.AdminUserDashboardLoadoutCreated',
      channel: 'tg-admin-channel-admin-user-dashboard-loadout',
      callback: (newLoadout: WebSocketsModelReply<DashboardLoadout>) => {
        if (newLoadout.model.adminUserId !== adminUserStore.adminUser?.id) {
          return
        }

        const existingLoadout = loadouts.value.find((l) => l.id === newLoadout.model.id)
        if (existingLoadout) {
          return
        }

        const loadout = loadouts.value.find(
          (l) => l.loadoutName === newLoadout.model.loadoutName,
        )
        if (loadout) {
          updateModel(loadout, newLoadout.model)
        } else {
          loadouts.value.push(newLoadout.model)
        }

        setModels(loadouts)
      },
    })

    const getLoadouts = async (userId: UserId) => {
      try {
        const response = await api.get<GetDashboardLoadoutsResponse>(
          `admin/dashboard/loadouts/users/${userId}`,
        )
        if (
          response.data.loadouts.length > 0 &&
          response.data.currentDashboardLoadoutId
        ) {
          loadouts.value = response.data.loadouts
          setCurrentLoadout(response.data.currentDashboardLoadoutId)
          setModels(loadouts.value)
          return true
        } else {
          toast.warning('Deze gebruikers heeft geen loadouts')
          return false
        }
      } catch {
        toast.error('Loadouts ophalen mislukt')
        return false
      }
    }

    const storeLoadoutId = async (loadout: DashboardLoadout) => {
      if (currentLoadout.value?.id === loadout.id) {
        return false
      }

      try {
        return await adminUserStore.updateField(
          {
            field: 'current-dashboard-loadout-id',
            value: {
              id: loadout.id,
              adminUserId: loadout.adminUserId,
            },
          },
          true,
        )
      } catch {
        toast.error('Fout bij opslaan van layout')
        return false
      }
    }

    const storeLoadout = async (loadout: DashboardLoadout) => {
      if (
        Object.values(loadout.loadout.position).every(
          (p) => p === 'empty' || p === 'hidden-empty',
        )
      ) {
        return false
      }

      if (onboarding.isActive.value) {
        return true
      }

      try {
        const newLoadout = await api.put<ApiResponse<DashboardLoadout>>(
          'admin/dashboard/loadouts/update',
          loadout,
        )

        const oldLoadoutIndex = loadouts.value.findIndex((l) => l.id === loadout.id)
        if (oldLoadoutIndex !== -1) {
          loadouts.value[oldLoadoutIndex] = newLoadout.data.data
        }

        if (currentLoadout.value?.id === loadout.id) {
          currentLoadout.value = newLoadout.data.data
        }

        return true
      } catch {
        // TODO: Add error message?
        return false
      }
    }

    const storeCurrentLoadout = async (skipExistsCheck: boolean = false) => {
      if (!currentLoadout.value || (currentLoadout.value.id === -1 && !skipExistsCheck)) {
        return
      }

      return await storeLoadout(currentLoadout.value)
    }

    const setCurrentLoadout = (
      id: DashboardLoadoutId | null,
      forceEmpty: boolean = false,
      userId: UserId | null = null,
    ) => {
      if (!id) {
        let adminUser
        if (userId) {
          adminUser = adminUserStore.getAdminUser(userId)
        } else {
          adminUser = adminUserStore.adminUser
        }

        if (!adminUser) {
          return
        }

        let newLoadout: DashboardLoadout

        const newName = `${getRandomColor()} ${capitalize(faker.date.weekday())} ${faker.internet.emoji(
          {
            types: ['food', 'nature'],
          },
        )}`

        if (forceEmpty) {
          newLoadout = {
            id: -1 as DashboardLoadoutId,
            adminUserId: adminUser.id,
            loadoutName: `(Nieuw) ${newName}`,
            createdAt: dayjsUTC(),
            updatedAt: dayjsUTC(),
            loadout: {
              position: {
                slotA: 'empty',
                slotB: 'empty',
                slotC: 'empty',
                slotD: 'empty',
              },
              settings: [],
            },
          }
        } else {
          newLoadout = getDefaultLayoutForRole(adminUser)

          if (loadouts.value.length > 0) {
            newLoadout.loadoutName = newName
          }
        }

        loadouts.value.push(newLoadout)
        currentLoadout.value = newLoadout
        storeCurrentLoadout(true)
        return
      }

      const loadout = loadouts.value.find((l) => l.id === id)
      if (!loadout) {
        // Show error?
        return
      }

      storeLoadoutId(loadout)
      currentLoadout.value = loadout
    }

    const isValidLoadout = (input: any): input is DashboardLoadout => {
      return (
        'loadout' in input && 'position' in input.loadout && 'settings' in input.loadout
      )
    }

    const importLoadout = (inputString: string) => {
      try {
        const loadout = JSON.parse(atob(inputString))
        if (!isValidLoadout(loadout)) {
          toast.error('Importeren mislukt (niet geldig)')
          return false
        }
        const existingLoadout = loadouts.value.find(
          (l) => l.id === loadout.id || isEqual(l.loadout, loadout.loadout),
        )
        if (existingLoadout) {
          toast.error('Deze loadout bestaat al')
          return false
        }

        loadout.id = -1 as DashboardLoadoutId
        loadouts.value.push(loadout)
        setCurrentLoadout(loadout.id)
        storeCurrentLoadout()
        return true
      } catch {
        toast.error('Importeren mislukt')
        return false
      }
    }

    const getOrCreateWidgetSettings = <T extends DashboardWidgetKey>(
      componentKey: T | undefined | null,
      skipCreation: boolean = false,
    ): DashboardWidgetPropMap[T] | undefined => {
      if (
        !componentKey ||
        !currentLoadout.value ||
        componentKey === 'empty' ||
        componentKey === 'hidden-empty'
      ) {
        return undefined
      }
      const settings = currentLoadout.value?.loadout.settings.find(
        (w) => w.componentKey === componentKey,
      )

      if (settings || skipCreation) {
        return settings as DashboardWidgetPropMap[T]
      }

      const newSettings = getDashboardWidgetDefaultSettings(componentKey)
      currentLoadout.value?.loadout.settings.push(newSettings)
      return newSettings as DashboardWidgetPropMap[T]
    }

    const deleteLoadout = async (loadoutId: DashboardLoadoutId): Promise<boolean> => {
      try {
        if (loadoutId !== (-1 as DashboardLoadoutId)) {
          await api.delete(`admin/dashboard/loadouts/${loadoutId}`)
          toast.success('Loadout is verwijderd')
        }
        const index = loadouts.value.findIndex((l) => l.id === loadoutId)
        if (index !== -1) {
          loadouts.value.splice(index, 1)
        }

        if (currentLoadout.value?.id === loadoutId) {
          await storeLoadoutId(loadouts.value[0])
          currentLoadout.value = loadouts.value[0]
        }

        return true
      } catch {
        toast.error('Loadout verwijderden is mislukt')
        return false
      }
    }

    const createMatchmakersDashboardSnapshot = async (): Promise<boolean> => {
      try {
        await api.put('admin/dashboard/workday-snapshot')
        toast.success('Werkdagen zijn opgeslagen')
        return true
      } catch {
        toast.error('Er is iets misgegaan bij het opslaan van de werkdagen')
        return false
      }
    }

    const getMatchmakersDashboardHistory = async (
      startOfWeek: Dayjs,
    ): Promise<DashboardWorkdayHistoryResponse | false> => {
      try {
        const response = await api.post<ApiResponse<DashboardWorkdayHistoryResponse>>(
          'admin/dashboard/workday-history',
          {
            weekStartDate: startOfWeek,
          },
        )
        return response.data.data
      } catch {
        toast.error('Ophalen werkdagen-geschiedenis mislukt')
        return false
      }
    }

    const getMatchmakerKpisDashboardData = async (
      startDate: Dayjs,
      endDate: Dayjs,
    ): Promise<MatchmakerKpiDashboardResource | false> => {
      try {
        const response = await api.post<ApiResponse<MatchmakerKpiDashboardResource>>(
          'admin/dashboard/statistics/matchmaker-kpis',
          {
            startDate: startDate,
            endDate: endDate,
          },
          allowReadOnly(),
        )
        return response.data.data
      } catch {
        toast.error('Fout bij ophalen gegevens')
        return false
      }
    }

    const getKPIsOfMatchmaker = async (
      userId: UserId,
      startDate: Dayjs,
      endDate: Dayjs,
    ): Promise<MatchmakerKPIResource | false> => {
      try {
        const response = await api.post<ApiResponse<MatchmakerKPIResource>>(
          `admin/dashboard/statistics/${userId}/kpis`,
          {
            startDate: startDate,
            endDate: endDate,
          },
          allowReadOnly(),
        )
        return response.data.data
      } catch {
        toast.error('Fout bij ophalen gegevens')
        return false
      }
    }

    const getRockStatistics = async (): Promise<Rock | false> => {
      try {
        const response = await api.get<Rock>('admin/dashboard/statistics/rock')
        return response.data
      } catch {
        toast.error('Fout bij ophalen van rock statistieken.')
        return false
      }
    }

    return {
      doneLoading,

      currentInterimRockTarget,

      loadouts,
      currentLoadout,
      currentLoadoutIsEmpty,

      getLoadouts,
      loadoutsAreFromAnotherUser,

      setCurrentLoadout,
      storeCurrentLoadout,
      storeLoadout,
      deleteLoadout,
      dashboardHistory,

      importLoadout,

      getOrCreateWidgetSettings,

      createMatchmakersDashboardSnapshot,
      getMatchmakersDashboardHistory,
      getMatchmakerKpisDashboardData,
      getKPIsOfMatchmaker,
      getRockStatistics,
    }
  },
  {
    persist: usePersist(true),
    logger: {
      filter: ({ name }) =>
        !['storeCurrentLoadout', 'getOrCreateWidgetSettings'].includes(name),
    },
  },
)

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDashboardStore as any, import.meta.hot))
}
