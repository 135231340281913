/**
 * This is a store that hold the app versioning state
 *
 * Using useStorage from @vueuse/core allow persistence storage across tabs/sessions
 */
import { useStorage } from '@vueuse/core'
import packageInfo from '../../package.json'
import { sendSentryMessage } from '/@src/utils/SentryMessage'

export interface VersionState {
  version?: string
}

const state = useStorage<VersionState>('app-version', {
  version: undefined,
})

export const validateLocalStorage = () => {
  // invalidate localstorage if stored client version doesn't match
  if (!state.value.version) {
    sendSentryMessage("no 'version' detected in localStorage", 'version-reset', state)
    localStorage.clear()
    state.value.version = packageInfo['tg-admin-version']
    return
  }

  if (state.value.version != packageInfo['tg-admin-version']) {
    sendSentryMessage(
      "Mismatched 'version' detected in localStorage",
      'version-reset',
      state,
    )
    localStorage.clear()
    state.value.version = packageInfo['tg-admin-version']
  }
}
