import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  BaseNewEventRequest,
  DetailEventRequest,
  EventableCook,
  EventableCookResource,
  EventRegistration,
  EventRegistrationFieldParams,
  EventRegistrationId,
  EventRegistrationParams,
  EventRequestFieldParams,
  EventRequestId,
  NewEventRequest,
  NewEventRequestParams,
  SearchEventRequest,
  SearchEventRequestParams,
  UpdateEventRequest,
} from '/@src/types/eventRequests'
import { toast } from 'vue-sonner'
import type { ApiResponse } from '/@src/types/utils'
import { rejectWebSocketUpdate, resolveWebSocketUpdate } from '/@src/utils/webSockets'
import { cloneDeep, omit } from 'lodash'
import { allowReadOnly } from '/@src/utils/axios-utils'
import type { CookId } from '/@src/types/cooks'
import type { UserId } from '/@src/types/users'
import { MatchRequestContact } from '/@src/types/matchRequests'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'

const api = useApi()

interface State {
  newEventRequest: NewEventRequest

  searchParams: SearchEventRequestParams
  searchResults: SearchEventRequest[]

  specificEventRequest?: DetailEventRequest
  registrations: EventRegistration[]

  eventableCooks: EventableCook[]
  totalEventableCooks: number
}

const baseNewEventRequest: BaseNewEventRequest = {
  step: 'general',
  generalStep: {
    startDate: undefined,
    endDate: undefined,
    activity: undefined,
    description: undefined,
  },

  contactStep: {
    isSkip: false,
    contactName: undefined,
    contactPhone: undefined,
    contactEmail: undefined,
    contactSite: undefined,
  },

  addressStep: {
    isSkip: false,
    postalcode: undefined,
    housenumber: undefined,
    street: undefined,
    city: undefined,
    municipality: undefined,
    neighborhood: undefined,
    district: undefined,
  },

  impactAcceleratorStep: {
    isSkip: false,
    targetGroup: undefined,
    visitorsAmount: undefined,
    expectations: undefined,
    ambassadorsAmount: undefined,
    volunteerAllowance: undefined,
  },
}

const defaultParams: SearchEventRequestParams = {
  query: '',
  addressQuery: {
    municipality: [],
    city: [],
    borough: [],
    neighborhood: [],
    district: [],
  },
  limit: 30,
  maxDaysOld: null,

  status: null,
  futureEventsOnly: false,
}

export const useEventRequestsStore = defineStore('eventRequests', {
  state: (): State => ({
    newEventRequest: cloneDeep(baseNewEventRequest as NewEventRequest),

    searchParams: cloneDeep(defaultParams),
    searchResults: [],

    specificEventRequest: undefined,

    registrations: [],

    eventableCooks: [],
    totalEventableCooks: 0,
  }),
  persist: usePersist<State>({
    pick: ['newEventRequest'],
  }),
  logout: {
    pick: ['searchParams'],
  },
  actions: {
    resetNewEventRequest() {
      this.newEventRequest = cloneDeep(baseNewEventRequest as NewEventRequest)
    },
    resetFilter() {
      this.searchParams = cloneDeep(defaultParams)
    },

    async create() {
      return new Promise<number | false>((resolve) => {
        api
          .post<
            ApiResponse<{ id: number }>
          >('admin/event-requests/create', this.newEventRequestParams)
          .then(
            (response) => {
              toast.success('Evenement maken gelukt!')
              resolve(response.data.data.id)
            },
            () => {
              toast.error('Evenement maken is mislukt.')
              resolve(false)
            },
          )
      })
    },

    async fetchEventRequest(eventRequestId: EventRequestId) {
      return new Promise<boolean>((resolve) => {
        api
          .get<ApiResponse<DetailEventRequest>>(`admin/event-requests/${eventRequestId}`)
          .then(
            (response) => {
              const json = response.data
              this.specificEventRequest = json.data
              resolve(true)
            },
            () => {
              toast.error('Kon evenement niet ophalen.')
              resolve(false)
            },
          )
      })
    },

    async searchEventableCooks(argument: boolean | string, maxCooks: number) {
      const params =
        typeof argument === 'boolean'
          ? { limitDistance: argument, limitCooks: maxCooks }
          : { query: argument, limitCooks: maxCooks }

      return new Promise<boolean>((resolve) => {
        api
          .post<EventableCookResource>(
            `admin/event-requests/${this.eventRequest.id}/search-cooks`,
            params,
            allowReadOnly(),
          )
          .then(
            (response) => {
              const json = response.data
              this.eventableCooks = json.data
              this.totalEventableCooks = json.total
              resolve(true)
            },
            () => {
              toast.error('Fout bij ophalen koks.')
              resolve(false)
            },
          )
      })
    },

    async update(eventRequestId: EventRequestId, payload: UpdateEventRequest) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/event-requests/${eventRequestId}/update`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Evenement geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Evenement updaten mislukt'))
      })
    },

    async updateField(payload: EventRequestFieldParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/event-requests/${payload.id}/update/${payload.field}`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Evenement succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Evenement updaten mislukt.'))
      })
    },

    async search() {
      return new Promise<boolean>((resolve) => {
        api
          .post<
            ApiResponse<SearchEventRequest[]>
          >('admin/event-requests/search', this.searchParams, allowReadOnly())
          .then(
            (response) => {
              const jsonData = response.data
              this.searchResults = jsonData.data
              resolve(true)
            },
            () => {
              toast.error('Fout bij zoeken naar evenementen')
              resolve(false)
            },
          )
      })
    },

    async updateEventableCookMatchComment(cookId: CookId, matchComment: string) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/cooks/${cookId}/update/match-comments`, {
            argument: matchComment,
          })
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten kok'))
      })
    },

    async addContactActivity(
      cookIds: CookId[],
      action: MatchRequestContact,
      isAddedManually: boolean,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .post(
            `admin/event-requests/${this.eventRequest.id}/cooks/add-contact-activity`,
            {
              cooks: cookIds,
              action: action,
              isAddedManually: isAddedManually,
            },
          )
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten koks'))
      })
    },

    async addEventableCookReaction(
      cookId: CookId,
      isPositive: boolean,
      isAddedManually: boolean,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .post(
            `admin/event-requests/${this.eventRequest.id}/cook/${cookId}/add-reaction`,
            {
              isPositive: isPositive,
              isAddedManually: isAddedManually,
            },
          )
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten koks'))
      })
    },

    async addSuggestedCook(cookId: CookId, isAddedManually: boolean) {
      return new Promise<boolean>((resolve) => {
        api
          .post(
            `admin/event-requests/${this.eventRequest.id}/cook/${cookId}/add-suggested`,
            {
              isAddedManually: isAddedManually,
            },
          )
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten koks'))
      })
    },

    // ------------------  EVENT REGISTRATIONS  ------------------

    async createEventRegistration(
      eventRequestId: EventRequestId,
      params: EventRegistrationParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put<
            ApiResponse<EventRegistration>
          >(`admin/event-requests/${eventRequestId}/registrations/create`, params)
          .then(
            (response) => {
              this.registrations.push(response.data.data)
              toast.success('Aanmelding succesvol opgeslagen')
              resolve(true)
            },
            () => {
              toast.error('Aanmelding opslaan mislukt')
              resolve(false)
            },
          )
      })
    },

    async updateEventRegistration(
      eventRequestId: EventRequestId,
      eventRegistrationId: EventRegistrationId,
      params: EventRegistrationParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(
            `admin/event-requests/${eventRequestId}/registrations/${eventRegistrationId}/update`,
            params,
          )
          .then(() => resolveWebSocketUpdate(resolve, 'Aanmelding is geüpdate'))
          .catch(() => rejectWebSocketUpdate(resolve, 'Aanmelding updaten mislukt'))
      })
    },

    async fetchEventRegistrations(eventRequestId: EventRequestId) {
      return new Promise<EventRegistration[] | false>((resolve) => {
        api.get(`admin/event-requests/${eventRequestId}/registrations`).then(
          (response) => {
            this.registrations = response.data.data
            resolve(response.data.data)
          },
          () => {
            toast.error('Ophalen van aanmeldingen mislukt')
            resolve(false)
          },
        )
      })
    },

    async updateRegistrationField(
      eventRequestId: EventRequestId,
      eventRegistrationId: EventRegistrationId,
      payload: EventRegistrationFieldParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(
            `admin/event-requests/${eventRequestId}/registrations/${eventRegistrationId}/update/${payload.field}`,
            payload,
          )

          .then(() => resolveWebSocketUpdate(resolve, 'Registratie succesvol geüpdate'))
          .catch(() => rejectWebSocketUpdate(resolve, 'Registratie updaten mislukt'))
      })
    },

    async addActivity(
      eventRequestId: EventRequestId,
      eventRegistrationId: EventRegistrationId,
      action: 'email' | 'phone' | 'texting',
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .post(
            `admin/event-requests/${eventRequestId}/registrations/${eventRegistrationId}/add-activity`,
            {
              action: action,
            },
          )
          .then(resolveWebSocketUpdate(resolve))
          .catch(rejectWebSocketUpdate(resolve, 'Fout bij updaten aanmelding'))
      })
    },

    async linkUserToRegistration(
      eventRequestId: EventRequestId,
      eventRegistrationId: EventRegistrationId,
      userId: UserId,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(
            `admin/event-requests/${eventRequestId}/registrations/${eventRegistrationId}/link-user`,
            {
              userId: userId,
            },
          )
          .then(resolveWebSocketUpdate(resolve, 'Gebruiker succesvol gekoppeld'))
          .catch(rejectWebSocketUpdate(resolve, 'Gebruiker koppelen mislukt'))
      })
    },

    async deleteUserFromRegistration(
      eventRequestId: EventRequestId,
      eventRegistrationId: EventRegistrationId,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(
            `admin/event-requests/${eventRequestId}/registrations/${eventRegistrationId}/delete-user`,
          )
          .then(resolveWebSocketUpdate(resolve, 'Gebruiker succesvol ontkoppelt'))
          .catch(rejectWebSocketUpdate(resolve, 'Gebruiker ontkoppelen mislukt'))
      })
    },
  },
  getters: {
    eventRequest: (state): DetailEventRequest => state.specificEventRequest!,
    newEventRequestParams: (state): NewEventRequestParams => {
      const newEventRequest = state.newEventRequest

      return {
        startDate: newEventRequest.generalStep.startDate,
        endDate: newEventRequest.generalStep.endDate,
        activity: newEventRequest.generalStep.activity,
        description: newEventRequest.generalStep.description,

        contactName: newEventRequest.contactStep.contactName,
        contactPhone: newEventRequest.contactStep.contactPhone,
        contactEmail: newEventRequest.contactStep.contactEmail,
        contactSite: newEventRequest.contactStep.contactSite,

        postalcode: newEventRequest.addressStep.postalcode,
        housenumber: newEventRequest.addressStep.housenumber,
        street: newEventRequest.addressStep.street,
        city: newEventRequest.addressStep.city,
        municipality: newEventRequest.addressStep.municipality,
        neighborhood: newEventRequest.addressStep.neighborhood,
        district: newEventRequest.addressStep.district,
        borough: newEventRequest.addressStep.borough,

        additionalQuestions: {
          impactAcceleratorQuestions: omit(newEventRequest.impactAcceleratorStep, [
            'isSkip',
          ]),
        },
      }
    },
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEventRequestsStore, import.meta.hot))
}
