<script setup lang="ts">
import type { BulmaSize } from '/@src/types/elements-ui'

type VCheckboxEmits = {
  click: []
}

interface VCheckboxProps {
  label?: string
  value: string | number // Name of this option
  size?: BulmaSize
  border?: boolean
  disabled?: boolean
  circle?: boolean
  check?: boolean
}

const emits = defineEmits<VCheckboxEmits>()
const props = defineProps<VCheckboxProps>()

const modelValue = defineModel<any>()
</script>

<template>
  <label class="checkbox" :class="[circle && 'is-circle', size && `is-${size}`]">
    <input
      v-model="modelValue"
      type="checkbox"
      :value="value"
      :disabled="disabled"
      :class="[border && 'has-border', check && 'has-check']"
      @change="emits('click')"
    />
    <span class="control-label" :class="!$slots['default'] && !label && 'mr-0'"></span>
    <slot> {{ label }} </slot>
  </label>
</template>

<style scoped lang="scss"></style>
