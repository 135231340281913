<script setup lang="ts">
import { type AdminUser, type AdminUserFieldUpdateEmoji } from '/@src/types/admin-users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { useWebSocketModel } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { z } from 'zod'
import { useTypedForm } from '/@src/composable/useTypedForm'
import type { EmojiExt } from 'vue3-emoji-picker'
import { useDarkMode } from '/@src/stores/darkmode'

const adminUserStore = useAdminUsersStore()
const darkModeStore = useDarkMode()

const showEmojiPicker = ref(false)
const isUpdatingEmoji = ref(false)

const { setModel } = useWebSocketModel<AdminUser>({
  baseChannel: 'tg-admin-channel-admin-user',
  event: '.AdminUserUpdated',
  callback: (newAdminUser: WebSocketsModelReply<AdminUser>) => {
    updateModel(adminUserStore.adminUser, newAdminUser.model)
    resetForm({
      values: {
        field: 'emoji-of-the-day',
        value: newAdminUser.model.emojiOfTheDay,
      },
    })
  },
})

const { handleSubmit, resetForm, useField } = useTypedForm<AdminUserFieldUpdateEmoji>({
  id: 'matchmaker-profile-dropdown',
  schema: {
    field: z.literal('emoji-of-the-day'),
    value: z.string().emoji().nullable(),
  },
  initialValues: {
    field: 'emoji-of-the-day',
    value: adminUserStore.adminUser?.emojiOfTheDay ?? null,
  },
})

const { value: emojiField } = useField('value')

watch(
  () => adminUserStore.adminUser,
  () => {
    emojiField.value = adminUserStore.adminUser?.emojiOfTheDay ?? null

    if (adminUserStore.adminUser) {
      setModel(adminUserStore.adminUser)
    }
  },
)

const onEmojiChange = handleSubmit(async (values) => {
  isUpdatingEmoji.value = true
  await adminUserStore.updateField(values)
  isUpdatingEmoji.value = false
})

const selectedEmoji = (event: EmojiExt) => {
  const chosenEmoji = event.i
  if (emojiField.value !== chosenEmoji) {
    emojiField.value = chosenEmoji
    onEmojiChange()
  }
}

onMounted(() => {
  if (adminUserStore.adminUser) {
    setModel(adminUserStore.adminUser)
  }
})
</script>

<template>
  <div class="is-flex is-justify-content-center is-flex-direction-column">
    <FormInput name="value" label="Emoji van de dag" field-class="has-text-centered" />

    <EmojiPicker
      v-show="showEmojiPicker"
      native
      :theme="darkModeStore.darkModeText"
      @select="selectedEmoji"
    />

    <VButton
      :icon-right="showEmojiPicker ? 'fa-face-laugh-wink' : 'fa-face-grin-beam'"
      @click.prevent="showEmojiPicker = !showEmojiPicker"
    >
      {{ showEmojiPicker ? "Sluit emoji's" : "Zoek naar emoji's" }}
    </VButton>
  </div>
</template>

<style scoped lang="scss">
::v-deep(.v-icon) {
  &:not(.traffic-light-active) {
    background-color: unset;
  }

  &.traffic-light-active {
    color: var(--muted-grey);
    box-shadow: 0 0 6px var(--muted-grey);
  }
}

.is-dark {
  .traffic-light-active {
    color: var(--white);
    box-shadow: 0 0 12px var(--white);
  }
}
</style>
