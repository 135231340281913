<script setup lang="ts">
import {
  type MealWish,
  type MealWishId,
  type MealWishParams,
  MealWishType,
  MealWishTypeCamelCase,
  MealWishTypeOptions,
} from '/@src/types/meal-wishes'
import type { OptionsIconMap, OptionsMap } from '/@src/types/elements-ui'
import { SaveIcon } from '/@src/models/standardIcons'
import { useWebSocket, useWebSocketModel } from '/@src/composable/useWebSocket'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { camelCase } from 'lodash'
import { updateModel } from '/@src/utils/webSockets'
import { removeKeysForResetForm, zodErrors } from '/@src/utils/zodUtils'
import { z } from 'zod'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { kebabCase } from '/@src/utils/helpers'
import { useMealWishesStore } from '/@src/stores/mealWishes'
import {
  mealWishTypeIcon,
  mealWishTypeName,
  UnEditableMealWishes,
} from '/@src/mapping/meal-wishes'

definePage({
  meta: {
    roles: 'lead-matchmaker',
  },
})

const mealWishStore = useMealWishesStore()

const isLoading = ref(false)
const activeType = ref<MealWishTypeCamelCase>(MealWishTypeCamelCase.DietWish)

const selectedMealWishId = ref<MealWishId>()
const newMealWishMode = ref(false)

const activeTypeName = computed(() => {
  const type = kebabCase(activeType.value) as MealWishType
  return mealWishTypeName[type].toLowerCase()
})

const activeMealWishes = computed<MealWish[]>(
  () => mealWishStore.mealWishes?.[activeType.value] ?? [],
)

const activeMealWish = computed<MealWish | undefined>(() =>
  activeMealWishes.value.find((mealWish) => mealWish.id === selectedMealWishId.value),
)

const activeMealWishIsDisabled = computed(() =>
  UnEditableMealWishes.includes(activeMealWish.value?.name ?? ''),
)

const activeMealWishOptions = computed<OptionsMap[]>(() =>
  activeMealWishes.value.map<OptionsMap>((mealWish) => {
    let name = mealWish.name
    if (!mealWish.isActive) {
      name += ' <niet actief>'
    }
    return {
      id: mealWish.id,
      name: name,
    }
  }),
)

const formMode = computed<'new' | 'update' | undefined>(() => {
  if (activeMealWish.value) {
    return 'update'
  } else if (newMealWishMode.value) {
    return 'new'
  } else {
    return undefined
  }
})

const { setModels } = useWebSocketModel<MealWish>({
  baseChannel: 'tg-admin-channel-meal-wish',
  event: '.MealWishUpdated',
  callback: (newModel: WebSocketsModelReply<MealWish>) => {
    const type = camelCase(newModel.model.type) as MealWishTypeCamelCase
    const mealWishes = mealWishStore.mealWishes?.[type] ?? []
    const foundMealWish = mealWishes.find((mealWish) => mealWish.id === newModel.model.id)

    if (foundMealWish) {
      updateModel(foundMealWish, newModel.model)
    }
  },
})
useWebSocket({
  event: '.MealWishCreated',
  callback: (newModel: WebSocketsModelReply<MealWish>) => {
    const type = camelCase(newModel.model.type) as MealWishTypeCamelCase
    const mealWishes = mealWishStore.mealWishes?.[type]
    if (mealWishes) {
      mealWishes.push(newModel.model)
      const allMealWishes = Object.values(mealWishStore.mealWishes ?? []).flat()
      setModels(allMealWishes)
    }
  },
  channel: 'tg-admin-channel-meal-wishes',
})

const { handleSubmit, resetForm, handleReset, meta, formProps } =
  useTypedForm<MealWishParams>({
    id: 'meal-wishes-settings',
    schema: {
      name: z.string().min(1, zodErrors.emptyString('naam')),
      translation: z.string().min(1, zodErrors.emptyString('vertaling')),
      type: MealWishTypeOptions,
      isActive: z.boolean(),
    },
    initialValues: {
      type: kebabCase(activeType.value) as MealWishType,
      isActive: true,
    },
  })

watch(activeMealWish, (newValue) => {
  if (newValue) {
    newMealWishMode.value = false
    resetForm(removeKeysForResetForm(newValue, ['id']))
  }
})
watch(newMealWishMode, (newValue) => {
  if (newValue) {
    selectedMealWishId.value = undefined
    resetForm({
      values: {
        name: undefined,
        translation: undefined,
        type: kebabCase(activeType.value) as MealWishType,
        isActive: true,
      },
    })
  }
})
watch(activeType, () => {
  newMealWishMode.value = false
  resetForm({
    values: {
      name: undefined,
      translation: undefined,
      type: kebabCase(activeType.value) as MealWishType,
      isActive: true,
    },
  })
})

watch(
  () => mealWishStore.mealWishes,
  (mealWishes) => {
    if (mealWishes) {
      const allMealWishes = Object.values(mealWishes).flat()
      setModels(allMealWishes)
    }
  },
  { immediate: true },
)

const onSubmit = handleSubmit(async (values) => {
  isLoading.value = true
  let result = false
  if (formMode.value === 'new') {
    result = await mealWishStore.create(values)
  } else if (formMode.value === 'update' && selectedMealWishId.value) {
    result = await mealWishStore.update(selectedMealWishId.value, values)
  }
  isLoading.value = false

  if (result) {
    resetForm({
      values: values,
    })
  }
})

const mealWishOptions: OptionsIconMap<MealWishTypeCamelCase>[] = [
  {
    id: MealWishTypeCamelCase.DietWish,
    name: mealWishTypeName[MealWishType.DietWish],
    icon: mealWishTypeIcon[MealWishType.DietWish],
  },
  {
    id: MealWishTypeCamelCase.KitchenPreference,
    name: mealWishTypeName[MealWishType.KitchenPreference],
    icon: mealWishTypeIcon[MealWishType.KitchenPreference],
  },
]

onMounted(() => mealWishStore.getAll())
</script>

<template>
  <VCard title="Dieetvoorkeuren">
    <template #content>
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="columns">
            <VRadioIcon
              v-model="activeType"
              :options="mealWishOptions"
              name="meal-wish-type"
              centered
            />
          </div>
        </div>
        <div class="column is-full mt-5 mb-4 is-divider"></div>
        <div class="column is-half is-flex is-justify-content-center">
          <VField label="&nbsp;" class="is-fullwidth">
            <VAnimatedButton
              icon="fa-plus"
              icon-right="fa-plus"
              :color="newMealWishMode ? 'success' : 'info'"
              override-color
              width="full"
              min-width="90%"
              :time="0.4"
              secondary-color="success"
              :disabled="newMealWishMode"
              @click="newMealWishMode = true"
            >
              Nieuwe {{ activeTypeName }} aanmaken
            </VAnimatedButton>
          </VField>
        </div>
        <div class="column is-half">
          <VField :label="`Bestaande ${activeTypeName} aanpassen`">
            <VMultiselect
              v-model="selectedMealWishId"
              :options="activeMealWishOptions"
              allow-empty
            />
          </VField>
        </div>
      </div>
      <FormWrapper v-if="formMode" v-bind="formProps" class="columns is-multiline">
        <div class="column is-full mt-5 mb-4 is-divider"></div>
        <div class="column is-full">
          <p class="title is-size-5">
            <template v-if="activeMealWishIsDisabled">
              Deze {{ activeTypeName }} mag niet aangepast worden
            </template>
            <template v-else-if="formMode === 'new'">
              Nieuwe {{ activeTypeName }} aanmaken
            </template>
            <template v-else> Bestaande {{ activeTypeName }} aanpassen</template>
          </p>
        </div>
        <div class="column is-offset-one-quarter is-one-quarter">
          <FormInput name="name" label="Naam" :disabled="activeMealWishIsDisabled" />
        </div>
        <div class="column is-one-quarter">
          <FormInput
            name="translation"
            label="Vertaling"
            :disabled="activeMealWishIsDisabled"
          />
        </div>
        <div class="column is-full is-flex is-justify-content-center">
          <FormSwitchBlock
            name="isActive"
            label="Zichtbaar voor gebruikers?"
            left-label="Nee"
            right-label="Ja"
            control-class="is-flex is-justify-content-center"
            :disabled="activeMealWishIsDisabled"
          />
        </div>
        <div
          class="column is-offset-one-quarter is-one-quarter is-flex is-justify-content-center"
        >
          <ResetButton
            :disabled="!meta.dirty"
            :loading="isLoading"
            :time="0.7"
            @reset="handleReset"
          />
        </div>
        <div class="column is-one-quarter is-flex is-justify-content-center">
          <VButton
            :icon-right="SaveIcon"
            color="success"
            width="full"
            :disabled="!meta.dirty || !meta.valid || activeMealWishIsDisabled"
            :loading="isLoading"
            @click.prevent="onSubmit"
          >
            Opslaan
          </VButton>
        </div>
      </FormWrapper>
    </template>
  </VCard>
</template>

<style scoped lang="scss"></style>
