<script lang="ts" setup>
import { LockIcon, OpenLockIcon } from '/@src/models/standardIcons'
import { camelCase, kebabCase } from 'lodash'
import {
  ContactMoment,
  ContactMomentCamelCase,
  type MonitoringQuestion,
  type MonitoringQuestionId,
  type MonitoringQuestions,
} from '/@src/types/contact-moment'
import type { OptionsMap } from '/@src/types/elements-ui'
import { toast } from 'vue-sonner'
import { contactMomentTranslation } from '/@src/mapping/contact-moments'
import { UserRoleEnum } from '/@src/types/users'
import { useMonitoringQuestionsStore } from '/@src/stores/monitoringQuestions'

definePage({
  meta: {
    roles: 'lead-matchmaker',
  },
})

const monitoringQuestionStore = useMonitoringQuestionsStore()

const isLoading = ref(false)

const updateQuestion = ref<string>()
const newQuestion = ref<string>()
const contactMoment = ref<ContactMoment>()
const questionId = ref<MonitoringQuestionId>()

const contactMomentCamelCase = computed(
  () => camelCase(contactMoment.value) as ContactMomentCamelCase,
)

const questions = computed<MonitoringQuestions | undefined>(
  () => monitoringQuestionStore.data,
)

const selectedContactMoment = computed<string | undefined>(() => {
  if (!contactMoment.value) {
    return
  }

  return contactMomentTranslation[contactMoment.value]
})

const momentsList = computed<OptionsMap[]>(() => {
  return Object.values(ContactMoment).map((moment) => ({
    id: moment,
    name: contactMomentTranslation[moment],
  }))
})

const questionExists = computed<boolean>(
  () =>
    !!questionForMomentOptions.value?.find(
      (question) => question.id === questionId.value,
    ),
)

const questionForMomentOptions = computed<OptionsMap[]>(() => {
  if (
    questions.value &&
    contactMoment.value &&
    questions.value[contactMomentCamelCase.value]
  ) {
    return Object.values(questions.value[contactMomentCamelCase.value])
      .sort((a, b) => Number(b.isActive) - Number(a.isActive))
      .map((question) => {
        const type =
          question.roleType === UserRoleEnum.Cook ? 'Thuiskok' : 'Maaltijdzoeker'
        let name = `${question.question} (${type})`
        if (!question.isActive) {
          name = `${name} <non-actief>`
        }

        return {
          id: question.id,
          name: name,
        }
      })
  }

  return []
})

const selectedQuestion = computed<MonitoringQuestion | undefined>(() => {
  if (
    !questions.value ||
    !contactMoment.value ||
    !questions.value[contactMomentCamelCase.value]
  ) {
    return undefined
  }

  return questions.value[contactMomentCamelCase.value].find(
    (question) => question.id === questionId?.value,
  )
})

const createQuestion = async () => {
  if (!confirm(`Vraag '${newQuestion.value}' toevoegen?`)) {
    return
  }

  if (!questions.value || !contactMoment.value) {
    toast.error('Geen contactmoment geselecteerd.')
    return
  }

  isLoading.value = true

  if (!newQuestion.value) {
    return
  }

  const result = await monitoringQuestionStore.createMonitoringQuestion({
    question: newQuestion.value,
    contactMoment: kebabCase(contactMoment.value) as ContactMoment,
  })

  isLoading.value = false

  if (result) {
    questions.value[contactMomentCamelCase.value] =
      questions.value[contactMomentCamelCase.value] ?? []
    newQuestion.value = ''
  }
}

const changeQuestion = async () => {
  if (
    !confirm(
      `Vraag '${selectedQuestion.value?.question}' wijzigen naar '${updateQuestion.value}'?`,
    )
  ) {
    return
  }

  if (!updateQuestion.value) {
    return
  }

  if (selectedQuestion.value) {
    isLoading.value = true
    await monitoringQuestionStore.updateMonitoringQuestion(selectedQuestion.value.id, {
      question: updateQuestion.value,
      contactMoment: kebabCase(contactMoment.value) as ContactMoment,
    })
    isLoading.value = false
    updateQuestion.value = ''
  }
}

const deactivateQuestion = async () => {
  if (!confirm(`Vraag '${selectedQuestion.value?.question}' non-actief  maken?`)) {
    return
  }

  isLoading.value = true

  if (selectedQuestion.value) {
    await monitoringQuestionStore.deactivateMonitoringQuestion(selectedQuestion.value.id)
  }

  isLoading.value = false
}

const activateQuestion = async () => {
  if (!confirm(`Vraag '${selectedQuestion.value?.question}' activeren?`)) {
    return
  }

  isLoading.value = true

  if (selectedQuestion.value) {
    await monitoringQuestionStore.activateMonitoringQuestion(selectedQuestion.value.id)
  }

  isLoading.value = false
}

const changeSelectedMoment = () => {
  updateQuestion.value = ''
  newQuestion.value = ''
}
</script>

<template>
  <VCard title="Contactmomenten">
    <template #content>
      <div class="columns is-full">
        <div class="column is-half">
          <label class="label light-text">Contactmomenten</label>
          <VMultiselect
            v-model="contactMoment"
            :loading="isLoading"
            :options="momentsList"
            searchable
            @select="changeSelectedMoment"
          />
        </div>

        <div class="column is-half">
          <label class="label light-text">{{
            selectedContactMoment
              ? `Vragen van contactmoment: '${selectedContactMoment}'`
              : 'Vragen'
          }}</label>
          <VField addons control-class="is-expanded">
            <VMultiselect
              v-model="questionId"
              :loading="isLoading"
              :options="questionForMomentOptions"
              searchable
            />
            <template #addon>
              <VButton
                v-if="selectedQuestion?.isActive"
                :disabled="isLoading || !questionExists"
                :icon-left="LockIcon"
                color="danger"
                override-color
                @click.prevent="deactivateQuestion"
              >
                Deactiveren
              </VButton>
              <VButton
                v-else
                :disabled="isLoading || !questionExists"
                :icon-left="OpenLockIcon"
                color="warning"
                override-color
                @click.prevent="activateQuestion"
              >
                Activeren
              </VButton>
            </template>
          </VField>
        </div>
      </div>

      <div class="columns is-full">
        <div class="column is-half">
          <label class="label light-text">Vraag wijzigen</label>
          <VField addons control-class="is-expanded">
            <VInput
              v-model="updateQuestion"
              placeholder="Vul een vraag in"
              :disabled="isLoading || !questionExists"
            />
            <template #addon>
              <VButton
                :disabled="isLoading || !updateQuestion || !questionExists"
                color="primary"
                icon-left="fa-arrows-rotate"
                override-color
                @click.prevent="changeQuestion"
              >
                Wijzigen
              </VButton>
            </template>
          </VField>
        </div>

        <div class="column is-half">
          <label class="label light-text">{{
            selectedContactMoment
              ? `Vraag toevoegen aan contactmoment: '${selectedContactMoment}'`
              : 'Vraag toevoegen'
          }}</label>
          <VField addons control-class="is-expanded">
            <VInput v-model="newQuestion" placeholder="Vul een nieuwe vraag in" />
            <template #addon>
              <VButton
                :disabled="isLoading || !newQuestion"
                color="success"
                icon-left="fa-plus"
                override-color
                @click.prevent="createQuestion"
              >
                Toevoegen
              </VButton>
            </template>
          </VField>
        </div>
      </div>
    </template>
  </VCard>
</template>

<style lang="scss" scoped>
.label {
  font-family: var(--font);
  font-size: 0.9rem;
}
</style>
