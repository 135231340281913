<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import type { Color, LengthUnit } from '/@src/types/utils'
import type {
  BulmaColor,
  FAIconPack,
  BulmaSize,
  FAIconName,
  VIconColor,
} from '/@src/types/elements-ui'

type FontAwesomeIconSize = 'lg' | '2x'

interface IconProps {
  icon: FAIconName
  hoverIcon?: FAIconName
  iconPack?: FAIconPack
  size?: BulmaSize | 'tiny'
  fontAwesomeIconSize?: FontAwesomeIconSize
  color?: VIconColor
  textColor?: BulmaColor
  text?: string
  flex?: boolean
  list?: boolean
  rounded?: boolean
  bordered?: boolean
  outlined?: boolean
  loading?: boolean
  disabled?: boolean
  overrideClickable?: boolean
  backgroundColor?: Color
  borderRadius?: LengthUnit
}

const props = withDefaults(defineProps<IconProps>(), {
  iconPack: 'fas',
  size: undefined,
  fontAwesomeIconSize: undefined,
  color: undefined,
  textColor: undefined,
  text: undefined,
  backgroundColor: undefined,
  borderRadius: undefined,
})

const iconRef = useTemplateRef('iconRef')
const isHovered = useElementHover(iconRef)

const computedIcon = computed(() => {
  if (!props.hoverIcon) {
    return props.icon
  }

  return isHovered.value ? props.hoverIcon : props.icon
})

const computedIconSize = computed(() => {
  if (props.size) {
    return `is-${props.size}`
  }
  return undefined
})

const computedFontAwesomeIconSize = computed(() => {
  if (props.fontAwesomeIconSize) {
    return `fa-${props.fontAwesomeIconSize}`
  }
  return undefined
})

const computedIconColor = computed(() => {
  if (props.color) {
    return `is-${props.color}`
  }
  return undefined
})

const computedTextColor = computed(() => {
  if (props.textColor) {
    return `has-text-${props.textColor}`
  }
  return undefined
})

const computedBackground = computed(() => {
  return {
    'background-color': props.backgroundColor,
    'border-radius': props.borderRadius,
  }
})

const isLoadingIcon = computed(() => (props.loading ? 'fa-spinner fa-pulse' : undefined))
</script>

<template>
  <div
    ref="iconRef"
    class="v-icon mx-1"
    :class="[
      computedIconSize,
      computedIconColor,
      rounded && 'is-rounded',
      bordered && 'is-bordered',
      outlined && 'is-outlined is-bordered',
      disabled && 'is-disabled',
      $attrs.onClick && !overrideClickable && 'is-clickable',
    ]"
  >
    <div
      v-if="text && flex"
      :style="computedBackground"
      class="icon-text"
      :class="[textColor && computedTextColor, size && computedIconSize]"
    >
      <span class="icon">
        <i
          :class="[
            iconPack,
            !isLoadingIcon && computedIcon,
            fontAwesomeIconSize && computedFontAwesomeIconSize,
            isLoadingIcon,
          ]"
        ></i>
      </span>
      <span>{{ text }}</span>
    </div>
    <span
      v-else-if="list && iconPack === 'fas'"
      :style="computedBackground"
      class="fa-li"
      :class="[textColor && computedTextColor, size && computedIconSize]"
    >
      <i :class="[iconPack, !isLoadingIcon && computedIcon, isLoadingIcon]"></i>
    </span>
    <span
      v-else-if="text"
      :style="computedBackground"
      class="icon-text"
      :class="[textColor && computedTextColor, size && computedIconSize]"
    >
      <span class="icon">
        <i
          :class="[
            iconPack,
            !isLoadingIcon && computedIcon,
            fontAwesomeIconSize && computedFontAwesomeIconSize,
            isLoadingIcon,
          ]"
        ></i>
      </span>
      <span>{{ text }}</span>
    </span>

    <span
      v-else
      :style="computedBackground"
      class="icon"
      :class="size && computedIconSize"
    >
      <i
        :class="[
          iconPack,
          !isLoadingIcon && computedIcon,
          fontAwesomeIconSize && computedFontAwesomeIconSize,
          isLoadingIcon,
        ]"
      ></i>
    </span>
  </div>
</template>

<style scoped lang="scss"></style>
