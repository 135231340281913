import { definePlugin } from '/@src/utils/app-helper'
import { z } from 'zod'
import { zodErrors } from '/@src/utils/zodUtils'
import { match } from 'ts-pattern'

export default definePlugin(() => {
  const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    let message = ctx.defaultError

    // Checks multiple error codes to add default error messages where applicable.
    // Uncomment the lines below to enable logging of unknown error types
    if (issue.code === z.ZodIssueCode.too_small) {
      if (issue.type === 'string') {
        message = zodErrors.emptyString()
      } else if (issue.type === 'number') {
        message = zodErrors.min(Number(issue.minimum))
      }
      // else {
      //   console.log(issue)
      // }
    } else if (issue.code === z.ZodIssueCode.too_big) {
      if (issue.type === 'number') {
        message = zodErrors.max(Number(issue.maximum))
      } else if (issue.type === 'string') {
        message = zodErrors.maxString(Number(issue.maximum))
      }
    } else if (issue.code === z.ZodIssueCode.invalid_type) {
      message = match(issue.expected)
        .with('integer', () => zodErrors.int())
        .with('number', () => zodErrors.number())
        .otherwise(() => {
          // console.log(issue)
          return zodErrors.required().required_error
        })
    } else if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === 'email') {
        message = zodErrors.email()
      } else if (issue.validation === 'emoji') {
        message = zodErrors.emoji()
      }
      // else {
      //   console.log('invalid_string', issue)
      // }
    } else if (issue.code === z.ZodIssueCode.not_multiple_of) {
      message = zodErrors.multipleOf(issue.multipleOf)
    }
    // else if (issue.code !== z.ZodIssueCode.custom) {
    //   console.log(issue)
    // }
    return { message: message }
  }

  z.setErrorMap(customErrorMap)
})
