import type { GlobalSearch } from '/@src/types/globals'
import { capitalize } from '/@src/utils/helpers'
import { match } from 'ts-pattern'
import { useMatchRequestStore } from '/@src/stores/matchRequests'
import { useMatchesStore } from '/@src/stores/matches'
import { useCooksStore } from '/@src/stores/cooks'
import { useAdminUsersStore } from '/@src/stores/adminUsers'
import { useEventRequestsStore } from '/@src/stores/eventRequests'
import { useTasksStore } from '/@src/stores/tasks'
import { isArray } from 'lodash'
import { TasksNavbarIcon } from '/@src/models/standardIcons'

const searchOnMunicipality =
  /(aanvraag|aanvragen|match(?:es)?|(?:thuis)?koks?|events?|evenement(?:en)?) in (?:gemeente )*(\w+)/i
const emojiQuery = /emoji.*: *(.+)/i
const boomQuery = /boom/i
const adminTaskQuery = /herinnering(?:en)?|taak|taken/i

export function checkGlobalSearchQuery(query: string): GlobalSearch[] | undefined {
  const result: GlobalSearch[] = []

  for (const regexString in globalSearchMap) {
    const regex = RegExp(regexString, 'i')

    const regexMatch = query.match(regex)

    if (regexMatch) {
      const funcResult = globalSearchMap[regexString](regexMatch, query)
      if (funcResult) {
        if (isArray(funcResult)) {
          result.push(...funcResult)
        } else {
          result.push(funcResult)
        }
      }
    }
  }

  if (result.length === 0) {
    return undefined
  } else {
    return result
  }
}

const globalSearchMap: Record<
  string,
  (
    queryResult: RegExpMatchArray,
    query: string,
  ) => GlobalSearch | GlobalSearch[] | undefined
> = {
  [searchOnMunicipality.source]: searchOnMunicipalityInner,
  [emojiQuery.source]: changeEmojiOfTheDayQuery,

  [boomQuery.source]: (): GlobalSearch => {
    return {
      id: -1,
      type: 'misc',
      fullName: 'Maak een emoji explosie',
      action: () => 'emoji',
    }
  },

  [adminTaskQuery.source]: processAdminTasksQuery,
}

function searchOnMunicipalityInner(
  queryResult: RegExpMatchArray,
): GlobalSearch | undefined {
  const type = queryResult[1].toLowerCase()
  const municipality = capitalize(queryResult[2])

  return match(type)
    .returnType<GlobalSearch | undefined>()
    .with('aanvraag', 'aanvragen', () => {
      return {
        id: -1,
        type: 'misc',
        fullName: `Zoek naar aanvragen in ${municipality}`,
        action: () => {
          useMatchRequestStore().searchMatchRequestParams.addressQuery = {
            municipality: [municipality],
            city: null,
            borough: null,
            district: null,
            neighborhood: null,
          }
          return undefined
        },
        to: {
          name: '/matching/',
        },
      }
    })
    .with('match', 'matches', () => {
      return {
        id: -1,
        type: 'misc',
        fullName: `Zoek naar matches in ${municipality}`,
        action: () => {
          useMatchesStore().searchParams.addressQuery = {
            municipality: [municipality],
            city: null,
            borough: null,
            district: null,
            neighborhood: null,
          }
          useMatchesStore().search()
          return undefined
        },
        to: {
          name: '/matches/',
        },
      }
    })
    .with('kok', 'koks', 'thuiskok', 'thuiskoks', () => {
      return {
        id: -1,
        type: 'misc',
        fullName: `Zoek naar koks in ${municipality}`,
        action: () => {
          useCooksStore().searchParams.addressQuery = {
            municipality: [municipality],
            city: null,
            borough: null,
            district: null,
            neighborhood: null,
          }
          return undefined
        },
        to: {
          name: '/cooks/',
        },
      }
    })
    .with('event', 'events', 'evenement', 'evenementen', () => {
      return {
        id: -1,
        type: 'misc',
        fullName: `Zoek naar evenementen in ${municipality}`,
        action: () => {
          useEventRequestsStore().searchParams.addressQuery = {
            municipality: [municipality],
            city: null,
            borough: null,
            district: null,
            neighborhood: null,
          }
          return undefined
        },
        to: {
          name: '/events/',
        },
      }
    })
    .otherwise(() => undefined)
}

function changeEmojiOfTheDayQuery(
  queryResult: RegExpMatchArray,
): GlobalSearch | undefined {
  const emoji = queryResult[1]
  return {
    id: -1,
    type: 'misc',
    fullName: `Pas emoji aan naar ${emoji}`,
    action: () => {
      useAdminUsersStore().updateField({
        field: 'emoji-of-the-day',
        value: emoji,
      })
      return undefined
    },
  }
}

function processAdminTasksQuery(
  queryResult: RegExpMatchArray,
  query: string,
): GlobalSearch | GlobalSearch[] | undefined {
  const taskStore = useTasksStore()

  if (query.match(/toevoegen|voeg.*toe|maak.*(?:aan)?/i)) {
    return {
      id: -1,
      type: 'misc',
      fullName: 'Maak snelle herinnering aan',
      action: () => {
        taskStore.newTask()
        return 'close'
      },
    }
  } else if (query.match(/afvinken|vink.*af|voltooi(?:en)?/i)) {
    if (taskStore.toolbarTasks.length === 0) {
      return {
        id: -1,
        type: 'misc',
        fullName: 'Geen herinnering om af te vinken',
        icon: 'fa-gifts',
      }
    }

    return taskStore.toolbarTasks.map((task) => {
      return {
        id: -1,
        type: 'misc',
        fullName: `Vink herinnering af: ${task.title}`,
        action: async () => {
          await taskStore.completeTasks(task.id)
          return 'close'
        },
      } satisfies GlobalSearch
    })
  } else {
    return taskStore.toolbarTasks.map((task) => {
      return {
        id: -1,
        type: 'misc',
        fullName: `Open herinnering: ${task.title}`,
        icon: TasksNavbarIcon,
        to: {
          name: '/to-do/',
          query: { task: task.id.toString() },
        },
      } satisfies GlobalSearch
    })
  }
}
